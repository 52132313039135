import React, { useCallback, useEffect, useState } from 'react'
import { IUser } from '../../../types/types'
import { useLocation } from 'react-router-dom'
import { Box, Button, Card, CardContent, CircularProgress, Divider, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { GetMerchantsApiKey } from '../../../services/admin/Merchants'
import { GetBalanceMethod } from '../../../services/Actives'
import { BanUserMethod, GetUsersMethod, SetUserRoleMethod } from '../../../services/admin/Users'

const MerchantsEdit = () => {
  const location = useLocation()
  const merchantLocation: IUser = location.state

  if (!merchantLocation) document.location.href = '/'

  const [apiKey, setApiKey] = useState<string | null>(null)
  const [balance, setBalance] = useState<string | null>(null)

  const getMerchantBalance = useCallback(async () => {
    const data = await GetBalanceMethod(merchantLocation.id)
    if (data.status === 200 && data.data.Success) {
      setBalance(data.data.data[0].value)
    }
  }
    , [merchantLocation.id])


  const getApiKey = useCallback(async () => {
    const data = await GetMerchantsApiKey(merchantLocation.id)
    console.log(data);

  }
    , [merchantLocation.id])

  const [loading, setLoading] = useState(true)
  const [merchant, setMerchant] = useState<IUser | null>(null)

  const getMerchant = useCallback(async () => {
    const data = await GetUsersMethod(merchantLocation.id)
    if (data.status === 200 && data.data.Success) {
      setMerchant(data.data.data[0])
      setLoading(false)
    }
  }
    , [merchantLocation.id])

  useEffect(() => {
    getMerchant()
  }, [getMerchant])

  useEffect(() => {
    getApiKey()
    getMerchantBalance()
  }, [getApiKey, getMerchantBalance])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
  }

  const updateRole = async (role: number) => {
    if (!merchant) return
    const data = await SetUserRoleMethod(merchant.id, role);
    if (data.status === 200 && data.data.Success) {
      getMerchant()
      setAnchorEl(null);
    }
  };

  const SetBanMerchant = async (banned: 0 | 1) => {
    if (!merchant) return
    const data = await BanUserMethod(merchant.id, merchant.login, banned);
    if (data.status === 200 && data.data.Success) {
      getMerchant()
    }
  }

  return (
    <>
      {
        !loading ?
          merchant &&
          <Box>
            <Stack flexDirection={"row"} gap={"10px"} marginBottom={"20px"}>
              <Typography variant='h5'>Информация мерчанта:</Typography>
              <Typography variant='h5' color='primary'>{merchant.email}</Typography>
            </Stack>
            <Card>
              <CardContent>
                <Stack flexDirection={"row"} gap={"10px"} margin={"20px 0"}>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"}>ID:</Typography>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"} color='primary'>{merchant.id}</Typography>
                </Stack>
                <Divider />
                <Stack flexDirection={"row"} gap={"10px"} margin={"20px 0"}>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"}>Email:</Typography>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"} color='primary'>{merchant.email}</Typography>
                </Stack>
                <Divider />
                <Stack flexDirection={"row"} gap={"10px"} margin={"20px 0"}>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"}>Login:</Typography>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"} color='primary'>{merchant.login}</Typography>
                </Stack>
                <Divider />
                <Stack flexDirection={"row"} gap={"10px"} margin={"20px 0"}>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"}>Telegram:</Typography>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"} color='primary'>{merchant.telegram ?? 'Не подключен'}</Typography>
                </Stack>
                <Divider />
                <Stack flexDirection={"row"} gap={"10px"} margin={"20px 0"}>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"}>API-KEY:</Typography>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"} color='primary'>{apiKey ?? 'Не сгенерирован'}</Typography>
                </Stack>
                <Divider />
                <Stack flexDirection={"row"} gap={"10px"} margin={"20px 0"}>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"}>Баланс:</Typography>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"} color='primary'>{balance ? `${balance} USDT` : 'Нет баланса'}</Typography>
                </Stack>
                <Divider />
                <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"} margin={"20px 0"} justifyContent={"space-between"}>
                  <Stack flexDirection={"row"} gap={"10px"}>
                    <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"}>Роль:</Typography>
                    <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"} color='primary'>
                      {
                        merchant.role_id === 1
                          ? 'Админ'
                          : merchant.role_id === 2
                            ? 'Мерчант'
                            : merchant.role_id === 3
                              ? 'Магазин'
                              : 'Трейдер'
                      }
                    </Typography>
                  </Stack>
                  <Button
                    variant='outlined'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    Изменить
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => updateRole(1)}>Админ</MenuItem>
                    <MenuItem onClick={() => updateRole(2)}>Мерчант</MenuItem>
                    <MenuItem onClick={() => updateRole(4)}>Трейдер</MenuItem>
                  </Menu>
                </Stack>
                <Stack flexDirection={"row"} gap={"10px"} marginBottom={"20px"}>
                  {
                    merchant.banned_status === 0
                      ? <Button color='error' variant='outlined' onClick={() => SetBanMerchant(1)}>Заблокировать</Button>
                      : <Button color='primary' variant='outlined' onClick={() => SetBanMerchant(0)}>Разблокировать</Button>
                  }
                </Stack>
              </CardContent>
            </Card>
          </Box>
          :
          <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"200px"}>
            <CircularProgress size="40px" />
          </Stack>
      }
    </>
  )
}

export default MerchantsEdit