import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'

interface BankCardPropsType {
  bank: string
  setChoosed: (param: string) => void;
}

const BankCard = ({ bank, setChoosed }: BankCardPropsType) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
          {bank}
        </Typography>
      </CardContent>
      <CardMedia
        sx={{ height: 140 }}
        image=""
        title={`${bank}`}
      />
      <CardActions>
        <Button size="small" fullWidth variant='outlined' onClick={() => setChoosed(bank)}>Выбрать</Button>
      </CardActions>
    </Card>
  )
}

export default BankCard