import { ApiClient } from "../Client"


export const AllowReqUserMethod = async (userId: number) => {
  return await ApiClient({
    method: 'POST',
    url: '/admin/confirm-request',
    data: {
      user_id: userId,
    }
  })
}

export const GetUsersMethod = async (id: number, banned?: number) => {
  return await ApiClient({
    method: 'POST',
    url: '/admin/get-all-users',
    data: {
      id: id,
      app_id: 3,
      ...(banned && { banned: banned })
    }
  })
}

// 1 - admin, 2 - merchant, 3 - shop, 4 - trader
export const SetUserRoleMethod = async (user_id: number, role_id: number) => {
  return await ApiClient({
    method: "POST",
    url: "/admin/update-auth-roles",
    data: {
      user_id: user_id,
      role_id: role_id
    }
  })
}

export const BanUserMethod = async (user_id: number, login: string, banned: 0 | 1) => {
  return await ApiClient({
    method: "POST",
    url: "/admin/set-any-user",
    data: {
      user_id: user_id,
      login: login,
      banned: banned
    }
  })
}