import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f0f0f0',
    color: '#000',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #ddd',
    // borderRadius: '16px',
    padding: '4px 8px',
    fontWeight: 500,
  },
}));