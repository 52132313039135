import CreditCardIcon from "@mui/icons-material/CreditCard"
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff"
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material"
import Grid from "@mui/material/Grid2"
import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppSelector } from "../../../redux/hooks/hooks"
import { GetPayTypeMethod } from "../../../services/Actives"
import {
  DelFavBankMethod,
  GetBanksMethod,
  GetCurrencyMethod,
  GetPayReqsGroupsMethod,
  GetPayReqsStatusMethod,
  GetPayReqsTypesMethod,
  SetFavBankMethod,
  SetReqsMethod,
} from "../../../services/Mains"
import {
  CustomSelectContentType,
  IBanks,
  ICurrency,
  IFavBank,
  IGetReqs,
  IPayType,
  IStatus,
  ITypes,
} from "../../../types/types"
import CustomInput from "../../../UI/customInput/CustomInput"
import CustomSelect from "../../../UI/customSelect/CustomSelect"
import CustomSnackBar from "../../../UI/customSnackBar/CustomSnackBar"
import CustomTable from "../../../UI/customTable/CustomTable"
import { FavBanksSample } from "./FavBanksSample"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Modal from "../../../UI/modal/Modal"

const RequisitesPayinEdit = () => {
  const profile = useAppSelector((state) => state.app.profile)

  const navigate = useNavigate()
  const location = useLocation()
  const requisites: IGetReqs = location.state

  if (!requisites) document.location.href = "/"

  const [openSnack, setOpenSnack] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const [group, setGroups] = useState<CustomSelectContentType[]>([
    { contentLabel: "Не выбрано", contentValue: "0" },
  ])
  const [currency, setCurrency] = useState<CustomSelectContentType[]>([])
  const [bank, setBank] = useState<CustomSelectContentType[]>([])
  const [type, setType] = useState<CustomSelectContentType[]>([])

  const [groupSelected, setGroupSelected] = useState(
    `${requisites?.req_group_id}`
  )
  const [currencySelected, setCurrencySelected] = useState(
    `${requisites?.currency_id}`
  )
  const [typeSelected, setTypeSelected] = useState(
    `${requisites?.reqs_types_id}`
  )
  const [bankSelected, setBankSelected] = useState(`${requisites?.bank_id}`)
  const [selectedStatus, setSelectedStatus] = useState<string>(
    requisites?.pay_status
  )
  const [loading, setLoading] = useState(true)
  const [title, setTitle] = useState(requisites?.title)
  const [payType, setPayType] = useState(1)
  const [listStatus, setListStatus] = useState<IStatus[]>([])

  const [qtyLimitHour, setQtyLimitHour] = useState(
    `${requisites?.qty_limit_hour}`
  )
  const [qtyLimitDay, setQtyLimitDay] = useState(`${requisites?.qty_limit_day}`)
  const [qtyLimitMonth, setQtyLimitMonth] = useState(
    `${requisites?.qty_limit_month}`
  )
  const [sumLimitHour, setSumLimitHour] = useState(
    `${requisites?.sum_limit_hour}`
  )
  const [sumLimitDay, setSumLimitDay] = useState(`${requisites?.sum_limit_day}`)
  const [sumLimitMonth, setSumLimitMonth] = useState(
    `${requisites?.sum_limit_month}`
  )
  const [limitActiveOrders, setLimitActiveOrders] = useState(
    `${requisites?.limit_active_orders}`
  )
  const [sequence, setSequence] = useState(`${requisites?.sequence}`)
  const [value, setValue] = useState(`${requisites?.value}`)

  const sendEditRequisites = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!profile) return
    const body = {
      id: requisites.id,
      uuid: requisites.uuid,
      title: title,
      user_id: profile.id,
      req_group_id: +groupSelected,
      sequence: +sequence,
      pay_pay_id: payType,
      value: value,
      currency_id: +currencySelected,
      reqs_types_id: +typeSelected,
      reqs_status_id: listStatus.find(
        (status) => status.title === selectedStatus
      )?.id,
      bank_id: +bankSelected,
      phone: "-",
      qty_limit_hour: +qtyLimitHour,
      qty_limit_day: +qtyLimitDay,
      qty_limit_month: +qtyLimitMonth,
      sum_limit_hour: +sumLimitHour,
      sum_limit_day: +sumLimitDay,
      sum_limit_month: +sumLimitMonth,
      limit_active_orders: +limitActiveOrders,
    }

    const dataCreateReqs = await SetReqsMethod(body)

    if (dataCreateReqs.status === 200 && dataCreateReqs.data.Success) {
      setSuccessMessage("Реквизит успешно изменен!")
      setOpenSnack(true)
      setTimeout(() => {
        setSuccessMessage("")
        navigate("/trader/requisites")
      }, 3000)
    } else {
      setErrorMessage("Что-то пошло не так..")
      setOpenSnack(true)
      setTimeout(() => {
        setErrorMessage("")
      }, 3200)
    }
  }

  const getInfo = useCallback(async () => {
    const dataGroup = await GetPayReqsGroupsMethod(0)
    if (dataGroup.status === 200 && dataGroup.data.Success) {
      const newGroups = dataGroup.data.data.map((group: any) => ({
        contentLabel: group.title,
        contentValue: group.id,
      }))

      setGroups((prevGroups) => {
        const uniqueGroups = new Map(
          [...prevGroups, ...newGroups].map((item) => [item.contentValue, item])
        )
        return Array.from(uniqueGroups.values())
      })
    }

    const dataCurrency = await GetCurrencyMethod(0)
    if (dataCurrency.status === 200 && dataCurrency.data.Success) {
      const newCurrency = dataCurrency.data.data.map((currency: ICurrency) => ({
        contentLabel: currency.symbol,
        contentValue: currency.id,
      }))

      setCurrency((prevCurrency) => {
        const uniqueCurrency = new Map(
          [...prevCurrency, ...newCurrency].map((item) => [
            item.contentValue,
            item,
          ])
        )
        return Array.from(uniqueCurrency.values())
      })
    }

    const dataPayType = await GetPayTypeMethod(0)
    if (dataPayType.status === 200 && dataPayType.data.Success) {
      const payTypeId = dataPayType.data.data.find(
        (el: IPayType) => el.title === "Payin"
      ).id

      setPayType(payTypeId)
    }

    const dataReqsStatus = await GetPayReqsStatusMethod(0)
    if (dataReqsStatus.status === 200 && dataReqsStatus.data.Success) {
      setListStatus(dataReqsStatus.data.data)
    }

    if (!profile) return
    const dataBank = await GetBanksMethod(profile.id)
    if (dataBank.status === 200 && dataBank.data.Success) {
      const newBank = dataBank.data.data.map((bank: IBanks) => ({
        contentLabel: bank.title,
        contentValue: bank.id,
      }))

      setBank((prevBank) => {
        const uniqueBank = new Map(
          [...prevBank, ...newBank].map((item) => [item.contentValue, item])
        )
        return Array.from(uniqueBank.values())
      })
    }

    const dataTypes = await GetPayReqsTypesMethod(0)
    if (dataTypes.status === 200 && dataTypes.data.Success) {
      const newTypes = dataTypes.data.data.map((type: ITypes) => ({
        contentLabel: type.title,
        contentValue: type.id,
      }))

      setType((prevType) => {
        const uniqueType = new Map(
          [...prevType, ...newTypes].map((item) => [item.contentValue, item])
        )
        return Array.from(uniqueType.values())
      })
    }

    setLoading(false)
  }

    , [profile])

  useEffect(() => {
    getInfo()
  }, [getInfo])

  const [rowFavBanks, setRowFavBanks] = useState<IFavBank[]>([])
  const [favBanks, setFavBanks] = useState<any[]>([])

  const getRowBanks = useCallback(async () => {
    if (!profile) return
    const data = await GetBanksMethod(profile.id)
    if (data.status === 200 && data.data.Success) {
      setRowFavBanks(data.data.data)
    }
  }
    , [profile])

  const delFavBank = useCallback(async (title: string) => {
    if (!profile) return
    const data = await DelFavBankMethod(profile.id, title)
    if (data.status === 200 && data.data.Success) {
      getRowBanks()
      getInfo()
    }
  }
    , [getInfo, getRowBanks, profile])

  const handleOpenModalDelete = useCallback((title: string) => {
    delFavBank(title)
  }
    , [delFavBank])

  useEffect(() => {
    const toRender = FavBanksSample({ rowFavBanks, setOpenSnack, handleOpenModalDelete })
    setFavBanks(toRender)
  }, [handleOpenModalDelete, rowFavBanks])

  useEffect(() => {
    getRowBanks()
  }, [getRowBanks])

  const rowsBanksHead = ['Название', 'БИК', 'Действия']

  const [openModalAddFavBank, setOpenModalAddFavBank] = useState(false)

  const [addBank, setAddBank] = useState('')
  const [addBik, setAddBik] = useState('')

  const addFavBank = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!profile) return
    const data = await SetFavBankMethod(profile.id, addBank, addBik);
    if (data.status === 200 && data.data.Success) {
      setAddBank('')
      setAddBik('')
      setOpenModalAddFavBank(false)
      getRowBanks()
      getInfo()
    }
  }

  const modalAddFavBank = <Box gap={"5px"}>
    <Typography variant='h5' gutterBottom>Добавление избранного банка</Typography>
    <form action="#" onSubmit={(e) => { e.preventDefault(); addFavBank(e) }}>
      <CustomInput label="Название" required value={addBank} setValue={setAddBank} />
      <CustomInput label="БИК" required value={addBik} setValue={setAddBik} />
      <Button variant='contained' sx={{ fontWeight: 700 }} type='submit'>Добавить банк</Button>
    </form>
  </Box>

  return (
    <Box>
      <Typography variant="h4">Редактировать реквизиты</Typography>
      <Divider sx={{ margin: "20px 0" }} />
      {!loading ? (
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <form
              action="#"
              onSubmit={(e) => {
                e.preventDefault()
                sendEditRequisites(e)
              }}>
              <FormControl sx={{ marginBottom: "10px" }}>
                <FormLabel id="radio-buttons-group-label">Статус</FormLabel>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  name="radio-buttons-group"
                  row>
                  <FormControlLabel
                    value="Активен"
                    control={<Radio />}
                    label={
                      <Chip
                        variant="outlined"
                        color="primary"
                        icon={<CreditCardIcon />}
                        label="Активны"
                      />
                    }
                  />
                  <FormControlLabel
                    value="Не активен"
                    control={<Radio />}
                    label={
                      <Chip
                        variant="outlined"
                        icon={<CreditCardOffIcon />}
                        label="Неактивны"
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
              <CustomInput
                label="Название"
                value={title}
                setValue={setTitle}
                size="small"
              />
              <CustomSelect
                label="Группа"
                content={group}
                value={groupSelected}
                setValue={setGroupSelected}
                size="small"
                autosuggest
              />
              <CustomSelect
                label="Валюта"
                required
                content={currency}
                value={currencySelected}
                setValue={setCurrencySelected}
                size="small"
                autosuggest
              />
              <CustomSelect
                label="Банк"
                required
                content={bank}
                value={bankSelected}
                setValue={setBankSelected}
                size="small"
                autosuggest
              />
              <CustomSelect
                label="Способ пополнения"
                required
                content={type}
                value={typeSelected}
                setValue={setTypeSelected}
                size="small"
              />
              <CustomInput
                label="Номер счета"
                required
                value={value}
                setValue={setValue}
                size="small"
              />
              {/*}<CustomInput
            label="Владелец реквизитов"
            value={requisites.nameOn}
            size="small"
          />
          <CustomInput label="БИК" value={requisites.bik} size="small" />
          <CustomInput
            label="Последние 4 цифры счета"
            value={requisites.lastNums}
            size="small"
          />*/}
              <Grid container spacing={1}>
                <Grid size={{ xs: 12, md: 4 }}>
                  <CustomInput
                    label="Количество в час"
                    value={`${qtyLimitHour}`}
                    shrink
                    notched
                    numeric
                    required
                    setValue={setQtyLimitHour}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                  <CustomInput
                    label="Количество в день"
                    value={`${qtyLimitDay}`}
                    shrink
                    notched
                    numeric
                    required
                    setValue={setQtyLimitDay}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                  <CustomInput
                    label="Количество в месяц"
                    value={`${qtyLimitMonth}`}
                    shrink
                    notched
                    numeric
                    required
                    setValue={setQtyLimitMonth}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid size={{ xs: 12, md: 4 }}>
                  <CustomInput
                    label="Сумма в час"
                    value={`${sumLimitHour}`}
                    shrink
                    notched
                    numeric
                    required
                    setValue={setSumLimitHour}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                  <CustomInput
                    label="Сумма в день"
                    value={`${sumLimitDay}`}
                    shrink
                    notched
                    numeric
                    required
                    setValue={setSumLimitDay}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                  <CustomInput
                    label="Сумма в месяц"
                    value={`${sumLimitMonth}`}
                    shrink
                    notched
                    numeric
                    required
                    setValue={setSumLimitMonth}
                  />
                </Grid>
              </Grid>
              <CustomInput
                label="Одновременное число активных ордеров"
                value={`${limitActiveOrders}`}
                shrink
                notched
                numeric
                required
                setValue={setLimitActiveOrders}
              />
              <CustomInput
                label="Частота успешных ордеров (1 успешный / X минут)"
                value={`${sequence}`}
                shrink
                notched
                numeric
                required
                setValue={setSequence}
              />
              <Typography
                padding={"0 10px"}
                variant="caption"
                fontSize={"0.7rem"}>
                Cледующие ордера будут назначаться на данный реквизит через X
                минут после последнего успешного
              </Typography>
              <Stack flexDirection={"row"} gap={"10px"} marginTop={"20px"}>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/trader/requisites")}>
                  Отмена
                </Button>
                <Button variant="contained" type="submit">
                  Сохранить
                </Button>
              </Stack>
            </form>
          </Grid>
          <Grid sx={{ xs: 12, md: 6, ld: 4 }}>
            <Stack gap={"10px"}>
              <Typography variant="h4">Избранные банки</Typography>
              {
                favBanks.length ?
                  <CustomTable rowsDataHead={rowsBanksHead} paginationCol={rowsBanksHead.length} rowsDataBody={favBanks} />
                  :
                  <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <ErrorOutlineIcon />
                    Данные не найдены
                  </Paper>
              }
              <Button variant="contained" sx={{ maxWidth: "max-content" }} onClick={() => setOpenModalAddFavBank(true)}>Добавить</Button>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          minHeight={"100px"}>
          <CircularProgress size="40px" />
        </Stack>
      )}
      <Modal
        modalContent={modalAddFavBank}
        open={openModalAddFavBank}
        setOpen={setOpenModalAddFavBank}
      />
      <CustomSnackBar
        open={openSnack}
        message={errorMessage || successMessage}
        setOpen={setOpenSnack}
      />
    </Box>
  )
}

export default RequisitesPayinEdit
