import { TabContext, TabPanel } from '@mui/lab'
import { Box, Button, Chip, IconButton, Menu, Paper, Stack, Typography } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { useCallback, useEffect, useState } from 'react'
import CustomSelect from '../../../UI/customSelect/CustomSelect';
import CustomTable from '../../../UI/customTable/CustomTable';
import CustomSnackBar from '../../../UI/customSnackBar/CustomSnackBar';
import { CustomSelectContentType, IPayMethod } from '../../../types/types';
import { PayMethodsSample } from './PayMethodsSample';
import { DelPayMethodsMethod, GetPayMethodsMethod, SetPayMethodsMethod } from '../../../services/admin/PayMethods';
import Modal from '../../../UI/modal/Modal';
import CustomInput from '../../../UI/customInput/CustomInput';

const PayMethods = () => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [operationSelected, setOperationSelected] = useState('all')

  const operationList: CustomSelectContentType[] = [
    { contentLabel: 'Все платежные методы', contentValue: 'all' },
  ]

  const handleResetFilters = () => {
    setOperationSelected('')
    setAnchorEl(null);
    getPayMethods()
  }

  const [openModalEdit, setOpenModalEdit] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalCreate, setOpenModalCreate] = useState(false)

  const [idToDel, setIdToDel] = useState('')
  const [idToEdit, setIdToEdit] = useState('')

  const handleOpenModalEdit = (id: number) => {
    setIdToEdit(id.toString())
    setOpenModalEdit(true)
  }

  const handleOpenModalDelete = (id: number) => {
    setIdToDel(id.toString())
    setOpenModalDelete(true)
  }

  const handleOpenModalCreate = () => {
    setOpenModalCreate(true)
  }

  const [openS, setOpenS] = useState(false);
  const [message, setMessage] = useState('')

  const [rowPayMethods, setRowPayMethods] = useState<IPayMethod[]>([])
  const [payMethods, setPayMethods] = useState<any[]>([])

  const getPayMethods = useCallback(async () => {
    const payMethodsData = await GetPayMethodsMethod();
    if (payMethodsData.status === 200 && payMethodsData.data.Success) {
      setRowPayMethods(payMethodsData.data.data)
    }
  }
    , [])

  // SET PAY METHODS
  useEffect(() => {
    const toRender = PayMethodsSample({ rowPayMethods, setOpenS, handleOpenModalEdit, handleOpenModalDelete })
    setPayMethods(toRender)
  }, [getPayMethods, operationSelected, rowPayMethods])

  useEffect(() => {
    getPayMethods()
  }, [getPayMethods])

  const [newMethod, setNewMethod] = useState('')

  const editPayMethod = async () => {
    const data = await SetPayMethodsMethod(newMethod, +idToEdit)
    if (data.status === 200 && data.data.Success) {
      setOpenModalEdit(false)
    } else {
      setMessage('Что-то пошло не так..')
      setOpenS(true)
      setTimeout(() => {
        setMessage('')
        setOpenS(false)
      }, 3000);
    }
  }

  const modalEdit = (
    <Box gap={"5px"}>
      <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
        <Typography variant='h6' gutterBottom>Изменить платежный метод</Typography>
      </Stack>
      <form action='#' onSubmit={(e) => { e.preventDefault(); editPayMethod().then(() => getPayMethods()) }}>
        <Box margin={"10px 0"} display={'flex'} flexDirection={'column'} gap={"10px"}>
          <CustomInput label='Новое название метода' required value={newMethod} setValue={setNewMethod} />
        </Box>
        <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
          <Button onClick={() => setOpenModalEdit(false)}>Отмена</Button>
          <Button variant='contained' sx={{ fontWeight: 700 }} type='submit'>Изменить</Button>
        </Stack>
      </form>
    </Box>
  )

  const deletePayMethod = async () => {
    const data = await DelPayMethodsMethod(+idToDel)
    if (data.status === 200 && data.data.Success) {
      setOpenModalDelete(false)
      getPayMethods()
    } else {
      setMessage('Что-то пошло не так..')
      setOpenS(true)
      setTimeout(() => {
        setMessage('')
        setOpenS(false)
      }, 3000);
    }
  }

  const modalDelete = (
    <Box gap={"5px"}>
      <Typography variant="body1" fontWeight={700} gutterBottom>
        Вы уверены, что хотите удалить платежный метод?
      </Typography>
      <Stack
        flexDirection={"row"}
        justifyContent={"flex-end"}
        gap={"10px"}
        marginTop={"20px"}>
        <Button onClick={() => setOpenModalDelete(false)}>Отмена</Button>
        <Button
          variant="contained"
          color="error"
          sx={{ fontWeight: 700 }}
          onClick={deletePayMethod}>
          Ок
        </Button>
      </Stack>
    </Box>
  )

  const createPayMethod = async () => {
    const data = await SetPayMethodsMethod(newMethod)
    if (data.status === 200 && data.data.Success) {
      setOpenModalCreate(false)
    } else {
      setMessage('Что-то пошло не так..')
      setOpenS(true)
      setTimeout(() => {
        setMessage('')
        setOpenS(false)
      }, 3000);
    }
  }

  const modalCreate = (
    <Box gap={"5px"}>
      <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
        <Typography variant='h6' gutterBottom>Создать платежный метод</Typography>
      </Stack>
      <form action='#' onSubmit={(e) => { e.preventDefault(); createPayMethod().then(() => getPayMethods()) }}>
        <Box margin={"10px 0"} display={'flex'} flexDirection={'column'} gap={"10px"}>
          <CustomInput label='Название метода' required value={newMethod} setValue={setNewMethod} />
        </Box>
        <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
          <Button onClick={() => setOpenModalCreate(false)}>Отмена</Button>
          <Button variant='contained' sx={{ fontWeight: 700 }} type='submit'>Создать</Button>
        </Stack>
      </form>
    </Box>
  )

  const rowsHead = ['ID', 'Название', 'Действия']

  return (
    <TabContext value={'1'}>
      <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"flex-end"}>
        <Button variant='contained' sx={{ fontWeight: 700 }} onClick={handleOpenModalCreate}>Создать</Button>
      </Stack>
      <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"space-between"} sx={{ padding: '8px 16px 0 16px' }}>
        <Chip label={operationSelected === 'all' ? 'Платежные методы' : '..'} />
        <IconButton onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
            <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <CustomSelect
            label='Тип'
            content={operationList}
            variant='outlined'
            value={operationSelected}
            setValue={setOperationSelected}
          />
          <Stack flexDirection={"row"} gap={"10px"}>
            <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
          </Stack>
        </Menu>
      </Stack>
      <TabPanel value="1" sx={{ padding: '10px 0' }}>
        {
          payMethods.length ?
            <CustomTable rowsDataHead={rowsHead} rowsDataBody={payMethods} paginationCol={rowsHead.length} />
            :
            <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <ErrorOutlineIcon />
              Данные не найдены
            </Paper>
        }
      </TabPanel>
      <CustomSnackBar open={openS} message={'Скопировано!'} setOpen={setOpenS} />
      <Modal
        modalContent={modalEdit}
        open={openModalEdit}
        setOpen={setOpenModalEdit}
      />
      <Modal
        modalContent={modalDelete}
        open={openModalDelete}
        setOpen={setOpenModalDelete}
      />
      <Modal
        modalContent={modalCreate}
        open={openModalCreate}
        setOpen={setOpenModalCreate}
      />
      <CustomSnackBar open={openS} message={message} setOpen={setOpenS} />
    </TabContext>
  )
}

export default PayMethods