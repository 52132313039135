import { Box, Chip, Stack, Tooltip, Typography, Zoom } from "@mui/material"
import { IOrder } from "../../../types/types"
import { copy } from "../../../utils/Copy"
import { turncate } from "../../../utils/Turncate"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonIcon from '@mui/icons-material/Person';

interface OrdersSamplePropsType {
  rowOrders: IOrder[]
  setOpenS: (param: boolean) => void
}

export const OrdersSample = ({ rowOrders, setOpenS }: OrdersSamplePropsType) => {
  return rowOrders.map((order, index) => (
    [
      <Box>
        <Typography variant="caption" width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(order.uuid, setOpenS)}
            title="Скопировать"
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography noWrap fontSize={'0.7rem'}>{turncate(order.uuid, 21)}</Typography>
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant="caption" fontWeight={300} fontSize={'0.7rem'}>{order.date}</Typography>
      </Box>,
      <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{order.course} ₽</Typography>,
      <Box>
        <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(`${+order.value * +order.course}`, setOpenS)}
            title={'Скопировать сумму'}
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                {+order.value * +order.course} ₽
              </Typography>
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(`${order.value}`, setOpenS)}
            title={'Скопировать сумму'}
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} alignItems={"center"} gap={'5px'}>
              <Chip color='primary' variant='outlined' icon={<AccountBalanceWalletIcon />} label={`${order.value} ${order.chart_symbol}`} size='small' />
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
      </Box>,
      <Box>
        {/* <Typography fontWeight={700} noWrap>{order.id}</Typography> */}
        <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{order.banks_name}</Typography>
        {/* <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>test{order.id}</Typography>
        <Typography variant='subtitle2' color='textDisabled' fontSize={'0.7rem'} noWrap>test{order.id}</Typography> */}
        <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(`${order.bik}`, setOpenS)}
            title={`БИК: ${order.bik}`}
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                БИК: {order.bik}
              </Typography>
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(`${order.phone}`, setOpenS)}
            title={`${order.phone}`}
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                {order.phone}
              </Typography>
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
        {/* <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>test{order.id}</Typography> */}
      </Box>,
      <Typography variant='subtitle1' fontSize={'0.7rem'}>{order.pay_type}</Typography>,
      <Box>
        {/* <Tooltip
          title={`${order.pay_notify_order_types_title}`}
          sx={{ cursor: 'pointer' }}
          slots={{
            transition: Zoom,
          }}
        > */}
        {/* <SmartToyIcon color='primary' sx={{ width: '15px', height: '15px' }} /> */}
        {/* <PersonIcon sx={{ width: '15px', height: '15px' }} /> */}
        {/* </Tooltip> */}
        <Typography variant='subtitle1' fontSize={'0.7rem'}>{order.pay_notify_order_types_title}</Typography>
      </Box>,
    ]
  ))
}

