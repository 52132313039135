import React, { useCallback, useEffect, useState } from 'react'
import styles from './Funds.module.scss'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Chip, IconButton, Link, Menu, Paper, Stack, Tab, Tooltip, Typography, Zoom } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import CustomSelect from '../../../UI/customSelect/CustomSelect';
import { CustomSelectContentType } from '../../../types/types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomTable from '../../../UI/customTable/CustomTable';
import { turncate } from '../../../utils/Turncate';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomSnackBar from '../../../UI/customSnackBar/CustomSnackBar';

const Funds = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [dateOn, setDateOn] = useState<Dayjs | null>(null)
  const [dateFor, setDateFor] = useState<Dayjs | null>(null)

  const [operationSelected, setOperationSelected] = useState('')

  const operationList: CustomSelectContentType[] = [
    { contentLabel: 'Вывод средств', contentValue: 'fundsWithdraws' },
    { contentLabel: 'Пополнение средств', contentValue: 'fundsDeposit' }
  ]

  const handleResetFilters = () => {
    setOperationSelected('')
    setDateFor(null)
    setDateOn(null)
    setAnchorEl(null);
  }

  const [fundsActiveOrders, setFundsActiveOrders] = useState<any[]>([])
  const [fundsArchiveOrders, setFundsArchiveOrders] = useState<any[]>([])

  const [openS, setOpenS] = React.useState(false);

  const copy = (str: string) => {
    navigator.clipboard.writeText(str).then(function () {
      setOpenS(true)
    }, function (err) {
      console.error('Произошла ошибка при копировании текста: ', err);
    });
  }

  const getActiveOrder = useCallback(async () => {
    const rowsActive = [
      [
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('adress to copy')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography noWrap>{turncate('adress to copy', 21)}</Typography>
                <ContentCopyIcon color='primary' fontSize='small' />
              </Stack>
            </Tooltip>
          </Typography>
          <Typography variant="caption" fontWeight={300}>2024-11-15 08:04</Typography>
        </Box>,
        <Box>
          <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
            <Tooltip
              // onClick={() => copy('adress to check')}
              title="9ae558404966b4366c62cf6f4af0fafd67165f3a674929d0ac6d50304993462b"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography noWrap>
                  <Link
                    display={"block"}
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`https://tronscan.org/#/transaction/9ae558404966b4366c62cf6f4af0fafd67165f3a674929d0ac6d50304993462b`}
                  >
                    {turncate('9ae558404966b4366c62cf6f4af0fafd67165f3a674929d0ac6d50304993462b', 21)}
                  </Link>
                </Typography>
                <ContentCopyIcon color='primary' fontSize='small' />
              </Stack>
            </Tooltip>
          </Typography>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('adress to copy')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography noWrap>{turncate('adress to copy', 21)}</Typography>
                <ContentCopyIcon color='primary' fontSize='small' />
              </Stack>
            </Tooltip>
          </Typography>
        </Box>,
        <Box>
          <Typography variant="subtitle2">
            3 066,64185 <span className={styles.additional}>USDT</span>
          </Typography>
          <Chip icon={<AddIcon />} color='primary' variant='outlined' label="Пополнение средств" />
          {/* <Chip icon={<RemoveIcon />} variant='outlined' label="Вывод средств" /> */}
        </Box>,
        <Box>
          <Chip icon={<CheckCircleOutlineIcon />} color='primary' variant='outlined' label="Выполнено" />
          {/* <Chip icon={<DoDisturbIcon />} color='error' variant='outlined' label="Отклонено" /> */}
        </Box>,
      ]
    ]

    // const rowsArchive = rowsActive;

    // setFundsActiveOrders(rowsActive)
    setFundsArchiveOrders(rowsActive)

  }
    , [])

  useEffect(() => {
    getActiveOrder()
  }, [getActiveOrder])

  const rowsHead = ['Дата', 'Транзакция', 'Сумма', 'Статус']

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs">
          <Tab label="Активные" value="1" />
          <Tab label="Архивные" value="2" />
        </TabList>
      </Box>
      <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"flex-end"} sx={{ padding: '8px 16px 0 16px' }}>
        <IconButton onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
            <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DatePicker
            label="Дата от"
            value={dateOn}
            onChange={(newValue) => setDateOn(newValue)}
          />
          <DatePicker
            label="Дата до"
            value={dateFor}
            onChange={(newValue) => setDateFor(newValue)}
          />
          <CustomSelect
            label='Операция'
            content={operationList}
            variant='outlined'
            value={operationSelected}
            setValue={setOperationSelected}
          />
          <Stack flexDirection={"row"} gap={"10px"}>
            <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
            <Button variant='outlined' fullWidth>OK</Button>
          </Stack>
        </Menu>
      </Stack>
      <TabPanel value="1" sx={{ padding: '10px 0' }}>
        {
          fundsActiveOrders.length ?
            <CustomTable rowsDataHead={rowsHead} rowsDataBody={fundsActiveOrders} paginationCol={4} />
            :
            <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <ErrorOutlineIcon />
              Данные не найдены
            </Paper>
        }
      </TabPanel>
      <TabPanel value="2" sx={{ padding: '10px 0' }}>
        {
          fundsArchiveOrders.length ?
            <CustomTable rowsDataHead={rowsHead} rowsDataBody={fundsArchiveOrders} paginationCol={4} />
            :
            <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <ErrorOutlineIcon />
              Данные не найдены
            </Paper>
        }
      </TabPanel>
      <CustomSnackBar open={openS} message={'Скопировано!'} setOpen={setOpenS} />
    </TabContext>
  )
}

export default Funds