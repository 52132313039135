import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppSelector } from "../../../redux/hooks/hooks"
import { GetPayTypeMethod } from "../../../services/Actives"
import {
  AddReqsToGroupMethod,
  GetReqsMethod,
  RemoveReqsFromGroupMethod,
  SetReqsGroupsMethod,
} from "../../../services/Mains"
import { IGetGroup, IGetReqs, IPayType } from "../../../types/types"
import CustomInput from "../../../UI/customInput/CustomInput"
import CustomSnackBar from "../../../UI/customSnackBar/CustomSnackBar"

const GroupEdit = () => {
  const profile = useAppSelector((state) => state.app.profile)

  const navigate = useNavigate()
  const location = useLocation()
  const requisites: IGetGroup = location.state

  if (!requisites) document.location.href = "/"

  const [openSnack, setOpenSnack] = useState(false)

  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [title, setTitle] = useState(requisites?.title)
  const [reqsPayin, setReqsPayin] = useState<IGetReqs[]>([])
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [previousSelectedIds, setPreviousSelectedIds] = useState<number[]>([])

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    if (event.target.checked) {
      setSelectedIds((prev) => [...prev, id])
    } else {
      setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== id))
    }
  }

  const sendEditGroup = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!profile) return
    const body = {
      title: title,
      types_automate_id: 1,
      turn_off: 0,
      user_id: profile.id,
      id: requisites.id,
    }

    const toRemove = previousSelectedIds.filter(
      (id) => !selectedIds.includes(id)
    )
    const toAdd = selectedIds.filter((id) => !previousSelectedIds.includes(id))

    const dataSetGroups = await SetReqsGroupsMethod(body)

    if (dataSetGroups.status === 200 && dataSetGroups.data.Success) {
      toAdd.forEach(async (el) => {
        await AddReqsToGroupMethod({
          id_reqs: el,
          id_group: requisites.id,
        })
      })
      toRemove.forEach(async (el) => {
        await RemoveReqsFromGroupMethod({
          id_reqs: el,
          id_group: requisites.id,
        })
      })
      setSuccessMessage("Группа успешно изменена!")
      setOpenSnack(true)
      setTimeout(() => {
        setSuccessMessage("")
        navigate("/trader/requisites")
      }, 3000)
    } else {
      setErrorMessage("Что-то пошло не так..")
      setOpenSnack(true)
      setTimeout(() => {
        setErrorMessage("")
      }, 3200)
    }
  }

  const getInfo = useCallback(async () => {
    if (!profile) return

    const dataPayType = await GetPayTypeMethod(0)

    const payinId = dataPayType.data.data.find(
      (el: IPayType) => el.title === "Payin"
    ).id

    const dataReqsPayin = await GetReqsMethod(profile.id, payinId)

    const newSelectId = dataReqsPayin?.data?.data
      .filter((el: IGetReqs) => el.req_group_id === requisites.id)
      .map((el: IGetReqs) => el.id)

    setSelectedIds(newSelectId)
    setPreviousSelectedIds(newSelectId)
    setReqsPayin(dataReqsPayin?.data?.data || [])

    setLoading(false)
  }, [profile])

  useEffect(() => {
    getInfo()
  }, [getInfo])

  return (
    <Box>
      <Typography variant="h4">Редактировать группу</Typography>
      <Divider sx={{ margin: "20px 0" }} />
      {!loading ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          maxWidth={"30rem"}>
          <form
            action="#"
            onSubmit={(e) => {
              e.preventDefault()
              sendEditGroup(e)
            }}>
            <CustomInput
              label="Название"
              required
              value={title}
              size="small"
              setValue={setTitle}
            />
            <Typography>Реквизиты</Typography>
            <FormGroup>
              {reqsPayin.length &&
                reqsPayin.map((req) => (
                  <Paper key={req.id}>
                    <FormControlLabel
                      sx={{ width: "100%", margin: "10px" }}
                      control={
                        <Checkbox
                          checked={selectedIds.includes(req.id)}
                          onChange={(event) =>
                            handleCheckboxChange(event, req.id)
                          }
                          disabled={
                            req.req_group_id !== 0 &&
                            req.req_group_id !== requisites.id
                          }
                        />
                      }
                      label={
                        <Box>
                          <Typography>
                            {req.title} | {req.currency_symbol} |{" "}
                            {req.reqs_types_title} | {req.bank_title}
                          </Typography>
                          <Typography>{req.value}</Typography>
                        </Box>
                      }
                    />
                  </Paper>
                ))}
            </FormGroup>
            <FormControlLabel
              sx={{ width: "100%" }}
              control={<Checkbox defaultChecked />}
              label="Автоматическое включение реквизитов без доступа к автоматике"
              disabled
            />
            <Stack flexDirection={"row"} gap={"10px"} marginTop={"20px"}>
              <Button
                variant="outlined"
                onClick={() => navigate("/trader/requisites")}>
                Отмена
              </Button>
              <Button variant="contained" type="submit">
                Сохранить
              </Button>
            </Stack>
          </form>
        </Box>
      ) : (
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          minHeight={"100px"}>
          <CircularProgress size="40px" />
        </Stack>
      )}
      <CustomSnackBar
        open={openSnack}
        message={errorMessage || successMessage}
        setOpen={setOpenSnack}
      />
    </Box>
  )
}

export default GroupEdit
