import { Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, Stack, Tooltip, Typography, Zoom } from '@mui/material'
import Grid from '@mui/material/Grid2'
import React, { useCallback, useEffect, useState } from 'react'
import styles from './Balances.module.scss'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomSnackBar from '../../../UI/customSnackBar/CustomSnackBar';
import ReplayIcon from '@mui/icons-material/Replay';
import Modal from '../../../UI/modal/Modal';
import CustomInput from '../../../UI/customInput/CustomInput';
import { GetBalanceMethod, GetBalancePercentMethod, GetDepositMethod } from '../../../services/Actives';
import { useAppSelector } from '../../../redux/hooks/hooks';
import { IBalance, IPercent } from '../../../types/types';

const Balances = () => {

  const profile = useAppSelector((state) => state.app.profile)

  const [loading, setLoading] = useState(true)

  // if (!profile) setLoading(true)

  const [balance, setBalance] = useState<IBalance | null>(null)
  const [deposit, setDeposit] = useState<IBalance | null>(null)
  const [percentPayIn, setPercentPayIn] = useState<IPercent | null>(null)
  const [percentPayOut, setPercentPayOut] = useState<IPercent | null>(null)

  const getBalance = useCallback(async () => {
    if (!profile) return
    const dataBalance = await GetBalanceMethod(profile.id)
    const dataDeposit = await GetDepositMethod(profile.id)
    const dataPercentPayIn = await GetBalancePercentMethod(profile.id, 1)

    if (dataBalance.status === 200 && dataBalance.data.Success) {
      setBalance(dataBalance.data.data[0])
      // setIsExistBalance(true)
      if (dataDeposit.status === 200 && dataDeposit.data.Success) {
        setDeposit(dataDeposit.data.data[0])
        // setIsExistBalance(true)
        if (dataPercentPayIn.status === 200 && dataPercentPayIn.data.Success) {
          setPercentPayIn(dataPercentPayIn.data.data[0])
          setPercentPayOut(dataPercentPayIn.data.data[1])
          // setIsExistBalance(true)
          // if (dataPercentPayOut.status === 200 && dataPercentPayOut.data.Success) {
          //   setPercentPayOut(dataPercentPayOut.data.data[0])
          // setIsExistBalance(true)
          // }
        }
      }
    }
    setLoading(false)
  }
    , [profile])

  useEffect(() => {
    getBalance()
  }, [getBalance])

  const cardPayin = (
    <>
      <CardContent>
        <Typography gutterBottom variant='h6'>
          Вознаграждение (PayIn)
        </Typography>
        <Typography variant="h5">
          {
            // percentPayIn ?
            <Stack flexDirection={"row"} gap={"10px"}>
              <Typography>{percentPayIn?.value ?? 0}</Typography>
              <Typography color='primary'>%</Typography>
            </Stack>
            // :
            // <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"100px"}>
            //   <CircularProgress size="30px" />
            // </Stack>
          }
        </Typography>
      </CardContent>
    </>
  )
  const cardPayOut = (
    <>
      <CardContent>
        <Typography gutterBottom variant='h6'>
          Вознаграждение (PayOut)
        </Typography>
        <Typography variant="h5">
          {
            // percentPayOut ?
            <Stack flexDirection={"row"} gap={"10px"}>
              <Typography>{percentPayOut?.value ?? 0}</Typography>
              <Typography color='primary'>%</Typography>
            </Stack>
            // :
            // <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"100px"}>
            //   <CircularProgress size="30px" />
            // </Stack>
          }
        </Typography>
      </CardContent>
    </>
  )
  const cardBalance = (
    <>
      <CardContent>
        <Typography gutterBottom variant='h6'>
          Баланс
        </Typography>
        <Typography variant="h5">
          {
            balance ?
              <Stack flexDirection={"row"} gap={"10px"}>
                <Typography>{balance.value}</Typography>
                <Typography color='primary'>{balance.chart_symbol}</Typography>
              </Stack>
              :
              <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"100px"}>
                <CircularProgress size="30px" />
              </Stack>
          }
        </Typography>
        <Typography variant="caption" gutterBottom>
          Доступно
        </Typography>
        <Typography variant="subtitle2" sx={{ marginTop: '10px' }}>
          {balance?.frozen ?? 0} <span className={styles.additional}>USDT</span>
        </Typography>
        <Typography variant="caption">
          Заморожено
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="medium" variant='contained' sx={{ fontWeight: 600 }} onClick={() => setOpenModalBalance(true)}>Вывести</Button>
      </CardActions>
    </>
  )

  const [errorMessageAdress, setErrorMessageAdress] = useState('')
  const [errorMessageEnterSum, setErrorMessageEnterSum] = useState('')
  // const [errorMessagefeeSum, setErrorMessagefeeSum] = useState('')
  // const [errorMessageWithdrawsSum, setErrorMessageWithdrawsSum] = useState('')

  const sendWithdrawsRequest = async (e: React.FormEvent<HTMLFormElement>) => {
    if (enterAdress.trim()) {
      if (+enterSum - +feeSum <= 0) {
        setErrorMessageEnterSum('Введенная сумма должна быть больше комиссии')
        setTimeout(() => {
          setErrorMessageEnterSum('')
        }, 2000);
      } else {
        console.log('ok');
      }
    } else {
      setErrorMessageAdress('Проверьте правильность адреса')
      setTimeout(() => {
        setErrorMessageAdress('')
      }, 2000);
    }
  }

  const [openModalBalance, setOpenModalBalance] = useState(false)
  const [enterAdress, setEnterAdress] = useState('')
  const [enterSum, setEnterSum] = useState('')
  const [feeSum, setFeeSum] = useState('2')
  const [withdrawsSum, setWithdrawsSum] = useState('')

  const modalBalanceWithdraws = <Box gap={"5px"}>
    <Typography variant='h5' gutterBottom>Заявка на вывод</Typography>
    <Typography variant='subtitle2' sx={{ fontWeight: 600, marginTop: '30px' }}>Сеть:</Typography>
    <Typography variant='subtitle1' gutterBottom >trc20</Typography>
    <form action="#" onSubmit={(e) => { e.preventDefault(); sendWithdrawsRequest(e) }}>
      <CustomInput label='Ваш адрес' errorMessage={errorMessageAdress} required setValue={setEnterAdress} />
      <CustomInput
        label='Сумма'
        errorMessage={errorMessageEnterSum}
        required
        numeric
        adornment='USDT'
        setValue={setEnterSum}
      />
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, md: 6 }}>
          <CustomInput
            label='Комиссия'
            // errorMessage={errorMessage}
            disabled
            value={`${feeSum}`}
            shrink
            notched
            adornment='USDT'
            numeric
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <CustomInput
            label='Сумма выплаты'
            // errorMessage={errorMessage}
            disabled
            value={`${(+enterSum - +feeSum) < 0 ? 0 : (+enterSum - +feeSum)}`}
            shrink
            notched
            adornment='USDT'
            numeric
          />
        </Grid>
      </Grid>
      <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"}>
        <Button onClick={() => { setOpenModalBalance(false); setEnterSum('') }}>Отмена</Button>
        <Button variant='contained' sx={{ fontWeight: 700 }} type='submit'>Создать заявку</Button>
      </Stack>
    </form>
  </Box>

  const cardDeposit = (
    <>
      <CardContent>
        <Typography gutterBottom variant='h6'>
          Депозит
        </Typography>
        <Typography variant="h5">
          {
            deposit ?
              <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
                <Typography>{+deposit.value - +(deposit?.frozen ?? 0)}</Typography>
                <Typography>из</Typography>
                <Typography>{deposit.value}</Typography>
                <Typography color='primary'>{balance?.chart_symbol}</Typography>
              </Stack>
              :
              <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"100px"}>
                <CircularProgress size="30px" />
              </Stack>
          }
        </Typography>
        <Typography variant="caption" gutterBottom>
          Доступно
        </Typography>
        <Typography variant="subtitle2" sx={{ marginTop: '10px' }}>
          {deposit?.frozen ?? 0} <span className={styles.additional}>USDT</span>
        </Typography>
        <Typography variant="caption">
          Заморожено
        </Typography>
      </CardContent>
      <CardActions>
        <Button disabled size="medium" variant='contained' sx={{ fontWeight: 600 }}>Вывести</Button>
      </CardActions>
    </>
  )

  const modalDepositWithdraws = <Box gap={"5px"}>

  </Box>

  const [open, setOpen] = useState(false);

  const [adress, setAdress] = useState('TE1p1ncr1ntgSVK4u5GRcXB9kGkfpShCro')

  const copyAdress = () => {
    navigator.clipboard.writeText(adress).then(function () {
      setOpen(true)
    }, function (err) {
      console.error('Произошла ошибка при копировании текста: ', err);
    });
  }

  const [openModalWallet, setOpenModalWallet] = useState(false)

  const wallet = (
    <>
      <CardContent>
        <Typography gutterBottom variant='h6'>
          Кошелек для пополнения
        </Typography>
        <Typography variant="subtitle2" fontWeight={700}>
          Сеть
        </Typography>
        <Typography variant="caption" gutterBottom>
          TRC20
        </Typography>
        <Typography variant="subtitle2" sx={{ marginTop: '10px', fontWeight: 700 }}>
          Адрес
        </Typography>
        <Typography variant="caption">
          <Tooltip
            onClick={copyAdress}
            title="Скопировать"
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography noWrap>{adress}</Typography>
              <ContentCopyIcon color='primary' fontSize='small' />
            </Stack>
          </Tooltip>
        </Typography>
      </CardContent>
      <CardActions>
        <Chip
          icon={<ReplayIcon />}
          color='primary'
          label="Создать новый"
          clickable
          sx={{ fontWeight: 600 }}
          onClick={() => setOpenModalWallet(true)}
        />
      </CardActions>
    </>
  )

  const modalWalletReset = <Box gap={"5px"}>
    <Typography variant='h6' gutterBottom>Подтверждение</Typography>
    <Typography variant='caption' sx={{ marginTop: '30px' }}>Вы уверены, что хотите выполнить действие?</Typography>
    <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
      <Button onClick={() => setOpenModalWallet(false)}>Отмена</Button>
      <Button variant='contained' sx={{ fontWeight: 700 }}>ОК</Button>
    </Stack>
  </Box>

  return (
    <React.Fragment>
      {
        !loading ?
          <Stack gap={'30px'}>
            <Grid container spacing={1}>
              <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                <Card variant="outlined" sx={{ padding: '24px 40px' }}>{cardPayin}</Card>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                <Card variant="outlined" sx={{ padding: '24px 40px' }}>{cardPayOut}</Card>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                <Card variant="outlined" sx={{ padding: '24px 40px' }}>{cardBalance}</Card>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                <Card variant="outlined" sx={{ padding: '24px 40px' }}>{cardDeposit}</Card>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                <Card variant="outlined" sx={{ padding: '24px 40px' }}>{wallet}</Card>
              </Grid>
            </Grid>
            <CustomSnackBar open={open} message={'Скопировано!'} setOpen={setOpen} />
            <Modal modalContent={modalBalanceWithdraws} open={openModalBalance} setOpen={setOpenModalBalance} />
            <Modal modalContent={modalWalletReset} open={openModalWallet} setOpen={setOpenModalWallet} />
          </Stack>
          :
          <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"100px"}>
            <CircularProgress size="40px" />
          </Stack>
      }
    </React.Fragment>
  )
}

export default Balances