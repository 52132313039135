import { Box, IconButton, Stack, Switch, Tooltip, Typography, Zoom } from "@mui/material"
import { IReqs } from "../../../types/types"
import { copy } from "../../../utils/Copy"
import { turncate } from "../../../utils/Turncate"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NavLink } from "react-router-dom";

interface RequisitesASamplePropsType {
  rowRequisites: IReqs[]
  setOpenS: (param: boolean) => void
  // handleOpenModalAuto: () => void
  // handleOpenModalDelete: () => void
}

export const RequisitesASample = ({
  rowRequisites,
  setOpenS,
  // handleOpenModalAuto, 
  // handleOpenModalDelete 
}: RequisitesASamplePropsType) => {
  return rowRequisites.map((reqs, index) => (
    [
      <Switch defaultChecked={Boolean(reqs.status)} value={reqs.status}
      // onChange={handleChangeStatus} 
      />,
      <Box>
        <Typography variant="caption" width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(reqs.adress, setOpenS)}
            title="Скопировать"
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography noWrap fontSize={'0.7rem'}>{turncate(reqs.adress, 21)}</Typography>
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant="caption" fontWeight={300} fontSize={'0.7rem'}>{reqs.date}</Typography>
      </Box>,
      <Box>
        <Typography fontWeight={700} noWrap>{reqs.name}</Typography>
        <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{reqs.currency}</Typography>
        <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{reqs.type}</Typography>
        <Typography variant='subtitle2' color='textDisabled' fontSize={'0.7rem'} noWrap>{reqs.bank}</Typography>
        <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(reqs.bik, setOpenS)}
            title={`БИК: ${reqs.bik}`}
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                БИК: {reqs.bik}
              </Typography>
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(reqs.number, setOpenS)}
            title={reqs.number}
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                {reqs.number}
              </Typography>
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{reqs.nameOn}</Typography>
        <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{reqs.lastNums}</Typography>
      </Box>,
      <Box>
        <Typography variant='subtitle1' fontSize={'0.7rem'}>{reqs.counts.split('/')[0]}</Typography>
        /
        <Typography variant='subtitle1' fontSize={'0.7rem'}>{reqs.counts.split('/')[1]}</Typography>
        /
        <Typography variant='subtitle1' fontSize={'0.7rem'}>{reqs.counts.split('/')[2]}</Typography>
      </Box>,
      <Box >
        <Typography variant='subtitle1' fontSize={'0.7rem'}>{reqs.sums.split('/')[0]}</Typography>
        /
        <Typography variant='subtitle1' fontSize={'0.7rem'}>{reqs.sums.split('/')[1]}</Typography>
        /
        <Typography variant='subtitle1' fontSize={'0.7rem'}>{reqs.sums.split('/')[2]}</Typography>
      </Box>,
      <Typography variant='subtitle1' fontSize={'0.7rem'}>{reqs.group}</Typography>,
      <Typography variant='subtitle1' fontSize={'0.7rem'}>{reqs.auto}</Typography>,
      <Typography variant='subtitle1' fontSize={'0.7rem'}>{reqs.statusSuccessOrders}</Typography>,
      <Stack flexDirection={"column"} alignItems={'flex-start'} gap={"5px"}>
        {/* <IconButton color='primary' onClick={handleOpenModalAuto}><AutoModeIcon /></IconButton> */}
        <NavLink to={`/admin/requisites/edit/${reqs.number}`} state={reqs}>
          <IconButton color='primary'><EditIcon /></IconButton>
        </NavLink>
        {/* <IconButton color='error' onClick={handleOpenModalDelete}><DeleteIcon /></IconButton> */}
      </Stack>
    ]
  ))
}

