import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, IconButton, TableFooter, TablePagination, useTheme } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import CustomDrawer from '../customDrawer/CustomDrawer';

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

interface defaultLabelDisplayedRowsPropsType {
  from: number,
  to: number,
  count: number
}

const defaultLabelDisplayedRows = ({ from, to, count }: defaultLabelDisplayedRowsPropsType) => {
  return `${from}–${to} из ${count !== -1 ? count : `больше чем ${to}`}`;
}

export interface Data {
  [key: string]: any;
}

interface CustomTablePropsType {
  rowsDataBody: any[];
  rowsDataHead: string[];
  paginationCol: number;
  showDetails?: boolean;
}

export default function CustomTable({
  rowsDataBody,
  rowsDataHead,
  paginationCol,
  showDetails
}: CustomTablePropsType) {
  // Функция создания данных
  function createData(...args: any[]): Data {
    const result: Data = {};
    args.forEach((value, index) => {
      result[`key${index}`] = value;
    });
    return result;
  }

  // Преобразуем данные в строки для таблицы
  const rowsBody = rowsDataBody.map(item => createData(...item));

  // Состояние пагинации
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Сбросить на первую страницу при изменении количества строк
  };

  // Сегментирование данных для текущей страницы
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedRows = rowsBody.slice(startIndex, endIndex);

  const [details, setDetails] = React.useState<Data>()

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', position: 'relative' }}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            {
              rowsDataHead.map((row, index) => (
                <TableCell key={index}>{row}</TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedRows.map((row, index) => (
            <TableRow key={index} onClick={() => showDetails ? setDetails(row) : <></>} hover={showDetails} sx={showDetails ? { cursor: 'pointer' } : {}}>
              {Object.values(row).map((cell: any, cellIndex) => (
                <TableCell key={cellIndex}>
                  {typeof cell === 'string' ? cell : cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage='Записи:'
              labelDisplayedRows={defaultLabelDisplayedRows}
              colSpan={paginationCol}
              count={rowsBody.length} // Общее количество строк
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'Записи',
                  }
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <CustomDrawer side='right' details={details} setDetails={setDetails} rowHeads={rowsDataHead} />
    </TableContainer>
  );
}
