import { Box, Button, Card, CircularProgress, Divider, Link, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2'
import BankCard from '../../components/bankCard/BankCard';
import CircleIcon from '@mui/icons-material/Circle';
import CustomSnackBar from '../../UI/customSnackBar/CustomSnackBar';
import { copy } from '../../utils/Copy';
import { GetInvoiceInfoMethod } from '../../services/Pay';
import { IPayReqs } from '../../types/types';

const Pay = () => {

  const userId = localStorage.getItem("user_id")

  if (!userId) document.location.href = '/login';

  // const [step, setStep] = useState(1)
  const [choosed, setChoosed] = useState('')
  const [openS, setOpenS] = useState(false)
  const [cardNumber, setCardNumber] = useState('номер карты')
  const [payReqs, setPayReqs] = useState<IPayReqs[]>([])
  // const banks = ['Сбер', 'Т-Банк', 'Открытие']

  const handleCopy = () => {
    copy(cardNumber, setOpenS)
  }

  const getInvoiceInfo = useCallback(async () => {
    if (!userId) return;
    const data = await GetInvoiceInfoMethod(+userId)
    // console.log(data);
    if (data.status === 200 && data.data.Success) {
      setPayReqs(data.data.data)
    }
  }
    , [userId])


  useEffect(() => {
    getInvoiceInfo()
  }, [getInvoiceInfo])

  return (
    <Box margin={"50px 0"} padding={"0 10px"}>
      <Container maxWidth="md" disableGutters sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }} >
        <Typography variant='h2'>Оплата заказа</Typography>
        {
          !choosed ?
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center', width: '100%' }}>
              <Typography variant='subtitle2'>Пожалуйста выберите банк, с карты которого вы будете совершать перевод</Typography>
              <Grid container spacing={1} width={"100%"}>
                {
                  payReqs.map((bank, index) => (
                    <Grid size={{ xs: 6, md: 4, lg: 4 }} key={index}>
                      <BankCard bank={bank.group} setChoosed={setChoosed} />
                    </Grid>
                  ))
                }
              </Grid>
              <Typography variant='subtitle2'>Идентификатор заказа: ...</Typography>
              <Typography variant='subtitle2'>Заказ активен ещё .. минут</Typography>
              <Link href={'/'} sx={{ cursor: 'pointer' }}>Венуться на сайт</Link>
            </Box>
            :
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center', width: '100%' }}>
              <Typography variant='subtitle2'>Заказ ... активен ещё .. минут</Typography>
              <Card sx={{ padding: "10px 20px", maxWidth: '500px' }}>
                <List >
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '30px' }}>
                      <CircleIcon sx={{ width: '10px' }} />
                    </ListItemIcon>
                    <ListItemText>Переведите на указанную карту в точности указанную сумму, одним переводом</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '30px' }}>
                      <CircleIcon sx={{ width: '10px' }} />
                    </ListItemIcon>
                    <ListItemText>Обратите внимание, номер карты меняется с каждым заказом</ListItemText>
                  </ListItem>
                </List>
                <Typography variant='subtitle2' textAlign={"center"}>В случае несоблюдения данных условий, мы не сможем идентифицировать ваш платеж!</Typography>
              </Card>
              <Card sx={{ padding: "10px 20px", maxWidth: '500px', width: "100%" }}>
                <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                  <Box>{choosed}</Box>
                  <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                    <Typography variant='h6'>Сумма к оплате</Typography>
                    <Typography variant='h5'>₽ ...</Typography>
                  </Box>
                </Stack>
                <Divider sx={{ margin: '10px 0' }} />
                <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                  <Typography variant='h5' textAlign={"center"}>{cardNumber}</Typography>
                  <Button variant='outlined' onClick={handleCopy}>Скопировать</Button>
                </Box>
              </Card>
              <Stack flexDirection={"row"} gap={"20px"} alignItems={"center"}>
                <CircularProgress size={'20px'} />
                <Typography variant='subtitle2'>Статус платежа</Typography>
              </Stack>
              <Link href={'/'} sx={{ cursor: 'pointer' }}>Венуться на сайт</Link>
            </Box>
        }
      </Container>
      <CustomSnackBar open={openS} setOpen={setOpenS} message='Скопировано!' />
    </Box>
  )
}

export default Pay