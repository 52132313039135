import React, { useCallback, useEffect, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, ButtonGroup, Card, CardContent, Chip, IconButton, Menu, Paper, Stack, Switch, Tab, Tooltip, Typography, Zoom } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { CustomSelectContentType, RequisitesType } from '../../../types/types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomTable, { Data } from '../../../UI/customTable/CustomTable';
import { turncate } from '../../../utils/Turncate';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomSnackBar from '../../../UI/customSnackBar/CustomSnackBar';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '../../../UI/modal/Modal';
import { useNavigate } from 'react-router-dom';
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CustomDrawer from '../../../UI/customDrawer/CustomDrawer';
import styles from './Statistic.module.scss'
import Grid from '@mui/material/Grid2'
import Rechart from '../../../UI/customRechart/CustomRechart';
import CustomSelect from '../../../UI/customSelect/CustomSelect';
import { GetStatisticsMethod } from '../../../services/User';
import { useAppSelector } from '../../../redux/hooks/hooks';

const Statistic = () => {
  const [payValue, setPayValue] = React.useState('payin');
  const [valueTabOne, setValueTabOne] = React.useState('1');
  const [valueTabTwo, setValueTabTwo] = React.useState('1');

  const handleChangeTabOne = (event: React.SyntheticEvent, newValue: string) => {
    setValueTabOne(newValue);
  };
  const handleChangeTabTwo = (event: React.SyntheticEvent, newValue: string) => {
    setValueTabTwo(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [dateOn, setDateOn] = useState<Dayjs | null>(null)
  const [dateFor, setDateFor] = useState<Dayjs | null>(null)

  const [operationSelected, setOperationSelected] = useState('')

  const operationList: CustomSelectContentType[] = [
    { contentLabel: 'Вывод средств', contentValue: 'fundsWithdraws' },
    { contentLabel: 'Пополнение средств', contentValue: 'fundsDeposit' }
  ]

  const handleResetFilters = () => {
    setOperationSelected('')
    setDateFor(null)
    setDateOn(null)
    setAnchorEl(null);
  }

  const [orders, setOrders] = useState<any[]>([])
  const [rowsRequisites, setRowsRequisites] = useState<any[]>([])
  const [exchangeArchiveOrders, setExchangeArchiveOrders] = useState<any[]>([])

  const [openS, setOpenS] = React.useState(false);

  const copy = (str: string) => {
    navigator.clipboard.writeText(str).then(function () {
      setOpenS(true)
    }, function (err) {
      console.error('Произошла ошибка при копировании текста: ', err);
    });
  }

  const [openModalAuto, setOpenModalAuto] = useState(false)
  const [openModalAutoGroup, setOpenModalAutoGroup] = useState(false)

  const handleOpenModalAuto = () => {
    setOpenModalAuto(true)
  }
  const handleOpenModalAutoGroup = () => {
    setOpenModalAutoGroup(true)
  }

  const modalGraf = <Box gap={"5px"}>
    <Typography variant='body1' fontWeight={700} gutterBottom>Cтатистика реквизита</Typography>
    <Rechart />
  </Box>

  const modalAutoGroup = <Box gap={"5px"}>
    <Typography variant='body1' fontWeight={700} gutterBottom>Автоматизация</Typography>
    <Typography variant='subtitle2' fontWeight={700} sx={{ marginTop: '30px' }}>API ключ</Typography>
    <Typography variant='subtitle1' width={"max-content"} display={"block"}>
      <Tooltip
        onClick={() => copy('156dd7d8-1749-4331-8a28-7aa2c0b6ab95')}
        title="Скопировать"
        sx={{ cursor: 'pointer' }}
        slots={{
          transition: Zoom,
        }}
      >
        <Stack flexDirection={'row'} gap={'5px'}>
          <Typography noWrap fontSize={'0.7rem'}>156dd7d8-1749-4331-8a28-7aa2c0b6ab95</Typography>
          <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
        </Stack>
      </Tooltip>
    </Typography>
    <Typography variant='subtitle2' fontWeight={700} sx={{ marginTop: '30px' }}>Способ автоматизации</Typography>
    <ButtonGroup sx={{ marginTop: '5px' }}>
      <Button variant="outlined">Ручной</Button>
      <Button variant="contained">Автоматический</Button>
    </ButtonGroup>
    <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
      <Button onClick={() => setOpenModalAutoGroup(false)}>Отмена</Button>
      <Button variant='contained' color='primary' sx={{ fontWeight: 700 }}>Сохранить</Button>
    </Stack>
  </Box>

  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalDeleteGroup, setOpenModalDeleteGroup] = useState(false)

  const handleOpenModalDelete = () => {
    setOpenModalDelete(true)
  }
  const handleOpenModalDeleteGroup = () => {
    setOpenModalDeleteGroup(true)
  }

  const modalDelete = <Box gap={"5px"}>
    <Typography variant='body1' fontWeight={700} gutterBottom>Вы уверены, что хотите удалить реквизиты?</Typography>
    <Box>
      <Typography fontWeight={700} noWrap>Name name</Typography>
      <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>RUB</Typography>
      <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>Перевод по номеру счета</Typography>
      <Typography variant='subtitle2' color='textDisabled' fontSize={'0.7rem'} noWrap>СберБанк</Typography>
      <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
        <Tooltip
          onClick={() => copy('044525225')}
          title="БИК: 044525225"
          sx={{ cursor: 'pointer' }}
          slots={{
            transition: Zoom,
          }}
        >
          <Stack flexDirection={'row'} gap={'5px'}>
            <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
              БИК: 044525225
            </Typography>
            <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
          </Stack>
        </Tooltip>
      </Typography>
      <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
        <Tooltip
          onClick={() => copy('40820810738260623135')}
          title="40820810738260623135"
          sx={{ cursor: 'pointer' }}
          slots={{
            transition: Zoom,
          }}
        >
          <Stack flexDirection={'row'} gap={'5px'}>
            <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
              40820810738260623135
            </Typography>
            <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
          </Stack>
        </Tooltip>
      </Typography>
      <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>Name name</Typography>
      <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>3135</Typography>
    </Box>
    <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
      <Button onClick={() => setOpenModalDelete(false)}>Отмена</Button>
      <Button variant='contained' color='error' sx={{ fontWeight: 700 }}>Ок</Button>
    </Stack>
  </Box>

  const modalDeleteGroup = <Box gap={"5px"}>
    <Typography variant='body1' fontWeight={700} gutterBottom>Подтверждение удаления</Typography>
    <Typography variant='subtitle1' fontSize={'0.7rem'} marginTop={"20px"} noWrap>Вы уверены, что хотите удалить элемент?</Typography>
    <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
      <Button onClick={() => setOpenModalDeleteGroup(false)}>Отмена</Button>
      <Button variant='contained' color='error' sx={{ fontWeight: 700 }}>Ок</Button>
    </Stack>
  </Box>

  const [status, setStatus] = useState(false)

  const handleChangeStatus = () => {
    setStatus(!status)
  }

  const navigate = useNavigate()

  const [showGrafModal, setShowGrafModal] = useState(false)

  const handleGrafClick = () => {
    setShowGrafModal(true)
  }

  const getOrders = useCallback(async () => {

    const rowReqExample: RequisitesType = {
      adress: 'adress to copy',
      date: '2024-11-15 08:04',
      name: 'Name name',
      currency: 'RUB',
      type: 'Перевод по номеру счета',
      bank: 'СберБанк',
      bik: '044525225',
      number: '40820810738260623135',
      nameOn: 'Name name',
      lastNums: '3134',
      counts: '0 из 1 000 / 1 из 10 000 / 10 из 100 000',
      sums: '0 из 10 000 000 / 1 001 из 100 000 000 / 13 013 из 1 000 000 000',
      group: 'Сбербанк|СПб|RUB',
      auto: 'Автоматический (Группа)',
      status: 0,
      statusSuccessOrders: 0,
    }

    const rowsOrders = [
      [
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy(rowReqExample.adress)}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography noWrap fontSize={'0.7rem'}>{turncate(rowReqExample.adress, 21)}</Typography>
                <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
              </Stack>
            </Tooltip>
          </Typography>
          <Typography variant="caption" fontWeight={300} fontSize={'0.7rem'}>{rowReqExample.date}</Typography>
        </Box>,
        <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>103.894 ₽</Typography>,
        <Box>
          <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('1001')}
              title={'Скопировать сумму'}
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                  1001 ₽
                </Typography>
                <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
              </Stack>
            </Tooltip>
          </Typography>
          <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('9,63482')}
              title={'Скопировать сумму'}
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} alignItems={"center"} gap={'5px'}>
                <Chip color='primary' variant='outlined' icon={<AccountBalanceWalletIcon />} label="9,63482 USD₮" size='small' />
                <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
              </Stack>
            </Tooltip>
          </Typography>
        </Box>,
        <Box>
          <Typography fontWeight={700} noWrap>{rowReqExample.name}</Typography>
          <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{rowReqExample.currency}</Typography>
          <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{rowReqExample.type}</Typography>
          <Typography variant='subtitle2' color='textDisabled' fontSize={'0.7rem'} noWrap>{rowReqExample.bank}</Typography>
          <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('044525225')}
              title="БИК: 044525225"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                  БИК: {rowReqExample.bik}
                </Typography>
                <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
              </Stack>
            </Tooltip>
          </Typography>
          <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy(rowReqExample.number)}
              title={rowReqExample.number}
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                  {rowReqExample.number}
                </Typography>
                <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
              </Stack>
            </Tooltip>
          </Typography>
          <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{rowReqExample.nameOn}</Typography>
        </Box>,
        <Typography variant='subtitle1' fontSize={'0.7rem'}>Перевод по номеру счета</Typography>,
        <Box>
          <Tooltip
            title={'Закрыто вручную'}
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            {/* <SmartToyIcon color='primary' sx={{ width: '15px', height: '15px' }} /> */}
            <PersonIcon sx={{ width: '15px', height: '15px' }} />
          </Tooltip>
        </Box>,
      ]
    ]

    const rowsRequisites = [
      [
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy(rowReqExample.adress)}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography noWrap fontSize={'0.7rem'}>{turncate(rowReqExample.adress, 21)}</Typography>
                <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
              </Stack>
            </Tooltip>
          </Typography>
          <Typography variant="caption" fontWeight={300} fontSize={'0.7rem'}>{rowReqExample.date}</Typography>
        </Box>,
        <Box>
          <Typography fontWeight={700} noWrap>{rowReqExample.name}</Typography>
          <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{rowReqExample.currency}</Typography>
          <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{rowReqExample.type}</Typography>
          <Typography variant='subtitle2' color='textDisabled' fontSize={'0.7rem'} noWrap>{rowReqExample.bank}</Typography>
          <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('044525225')}
              title="БИК: 044525225"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                  БИК: {rowReqExample.bik}
                </Typography>
                <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
              </Stack>
            </Tooltip>
          </Typography>
          <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy(rowReqExample.number)}
              title={rowReqExample.number}
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                  {rowReqExample.number}
                </Typography>
                <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
              </Stack>
            </Tooltip>
          </Typography>
          <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{rowReqExample.nameOn}</Typography>
        </Box>,
        <Typography variant='subtitle1' fontSize={'0.7rem'}>24</Typography>,
        <Typography variant='subtitle1' fontSize={'0.7rem'}>10</Typography>,
        <Typography variant='subtitle1' fontSize={'0.7rem'}>9</Typography>,
        <Button variant='contained' sx={{ fontWeight: '600' }} onClick={handleGrafClick}>График</Button>
      ]
    ]

    // const rowsArchive = rowsOrders;

    setOrders(rowsOrders)
    setRowsRequisites(rowsRequisites)
    // setExchangeArchiveOrders(rowsArchive)

  }
    , [navigate])

  useEffect(() => {
    getOrders()
  }, [getOrders])

  const rowsHeadOrdersSuccess = [
    'ID',
    'Курс',
    'Сумма',
    'Реквизиты',
    'Оплата',
    'Статус',
  ]

  const rowsHeadOrdersCanceled = [
    'ID',
    'Реквизиты',
    'Созданные',
    'Успешные',
    'Закрытые автоматикой',
    'Действия',
  ]

  const defaultViewCard = (head: string, value: string, add: string) => (
    <Card variant="outlined" sx={{ padding: '24px 40px' }}>
      <CardContent>
        <Typography gutterBottom variant='h6'>
          {head}
        </Typography>
        <Typography variant="h5">
          {value} <span className={styles.additional}>{add}</span>
        </Typography>
      </CardContent>
    </Card>
  )

  const viewHeads = ['Оборот', 'Кешбэк', 'Количество сделок', 'Успешные сделки', 'Конверсия', 'Средний чек']
  const viewGrafHeads = ['Ордера', 'Оборот', 'Средний чек', 'Конверсия', 'Кешбэк']

  const profile = useAppSelector((state) => state.app.profile)

  const getStatistics = useCallback(async () => {
    if (!profile) return
    const data = await GetStatisticsMethod(profile.id)
    console.log(data);

  }
    , [profile])

  useEffect(() => {
    getStatistics()
  }, [getStatistics])

  return (
    <TabContext value={payValue}>
      <Stack flexDirection={'row'} gap={"10px"}>
        <Chip label={'PayIn'} variant='outlined' color={payValue === 'payin' ? 'primary' : 'default'} onClick={() => setPayValue('payin')} />
        <Chip label={'PayOut'} variant='outlined' color={payValue === 'payout' ? 'primary' : 'default'} onClick={() => setPayValue('payout')} />
      </Stack>
      <TabPanel value="payin" sx={{ padding: '10px 0' }}>
        <TabContext value={valueTabOne}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTabOne}>
              <Tab label="Обзор" value="1" />
              <Tab label="Обзор по дням" value="2" />
              <Tab label="Реквизиты" value="3" />
              <Tab label="Трейдеры рефералы" value="4" />
            </TabList>
          </Box>
          <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"flex-end"} sx={{ padding: '8px 16px 0 16px' }}>
            <IconButton onClick={handleClick}>
              <FilterListIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
                <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <DatePicker
                label="Дата от"
                value={dateOn}
                onChange={(newValue) => setDateOn(newValue)}
              />
              <DatePicker
                label="Дата до"
                value={dateFor}
                onChange={(newValue) => setDateFor(newValue)}
              />
              <CustomSelect
                label='Операция'
                content={operationList}
                variant='outlined'
                value={operationSelected}
                setValue={setOperationSelected}
              />
              <Stack flexDirection={"row"} gap={"10px"}>
                <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
                <Button variant='outlined' fullWidth>OK</Button>
              </Stack>
            </Menu>
          </Stack>
          <TabPanel value="1" sx={{ padding: '10px 0' }}>
            {
              viewHeads.length ?
                <Stack gap={'30px'}>
                  <Grid container spacing={1}>
                    <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                      {defaultViewCard(viewHeads[0], '405 230,06', 'USDT')}
                    </Grid>
                    <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                      {defaultViewCard(viewHeads[1], '22 151,05', 'USDT')}
                    </Grid>
                    <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                      {defaultViewCard(viewHeads[2], '17930', 'шт.')}
                    </Grid>
                    <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                      {defaultViewCard(viewHeads[3], '11064', 'шт.')}
                    </Grid>
                    <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                      {defaultViewCard(viewHeads[4], '61.71', '%')}
                    </Grid>
                    <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                      {defaultViewCard(viewHeads[5], '36,63', 'USDT')}
                    </Grid>
                  </Grid>
                </Stack>
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="2" sx={{ padding: '10px 0' }}>
            {
              viewGrafHeads.length ?

                viewGrafHeads.map((head, index) => (
                  <Box>
                    <Typography textAlign={"center"} margin={"20px 0"} fontWeight={700} fontSize={'1.1rem'}>{head}</Typography>
                    <Rechart key={index} />
                  </Box>
                ))

                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="3" sx={{ padding: '10px 0' }}>
            {
              rowsRequisites.length ?
                <CustomTable rowsDataHead={rowsHeadOrdersCanceled} rowsDataBody={rowsRequisites} paginationCol={6} />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="4" sx={{ padding: '10px 0' }}>
            {
              exchangeArchiveOrders.length ?
                <CustomTable rowsDataHead={rowsHeadOrdersSuccess} rowsDataBody={exchangeArchiveOrders} paginationCol={4} />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
        </TabContext>
      </TabPanel>
      <TabPanel value="payout" sx={{ padding: '10px 0' }}>
        <TabContext value={valueTabTwo}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTabTwo}>
              <Tab label="Активные" value="1" />
              <Tab label="Успешные" value="2" />
              <Tab label="Отмененные" value="3" />
              <Tab label="Диспут" value="4" />
              <Tab label="Все" value="5" />
            </TabList>
          </Box>
          <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"flex-end"} sx={{ padding: '8px 16px 0 16px' }}>
            <IconButton onClick={handleClick}>
              <FilterListIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
                <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <DatePicker
                label="Дата от"
                value={dateOn}
                onChange={(newValue) => setDateOn(newValue)}
              />
              <DatePicker
                label="Дата до"
                value={dateFor}
                onChange={(newValue) => setDateFor(newValue)}
              />
              <CustomSelect
                label='Операция'
                content={operationList}
                variant='outlined'
                value={operationSelected}
                setValue={setOperationSelected}
              />
              <Stack flexDirection={"row"} gap={"10px"}>
                <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
                <Button variant='outlined' fullWidth>OK</Button>
              </Stack>
            </Menu>
          </Stack>
          <TabPanel value="1" sx={{ padding: '10px 0' }}>
            {
              exchangeArchiveOrders.length ?
                <CustomTable rowsDataHead={rowsHeadOrdersSuccess} rowsDataBody={exchangeArchiveOrders} paginationCol={9} />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="2" sx={{ padding: '10px 0' }}>
            {
              exchangeArchiveOrders.length ?
                <CustomTable rowsDataHead={rowsHeadOrdersSuccess} rowsDataBody={exchangeArchiveOrders} paginationCol={5} />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="3" sx={{ padding: '10px 0' }}>
            {
              exchangeArchiveOrders.length ?
                <CustomTable rowsDataHead={rowsHeadOrdersSuccess} rowsDataBody={exchangeArchiveOrders} paginationCol={4} />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="4" sx={{ padding: '10px 0' }}>
            {
              exchangeArchiveOrders.length ?
                <CustomTable rowsDataHead={rowsHeadOrdersSuccess} rowsDataBody={exchangeArchiveOrders} paginationCol={4} />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
        </TabContext>
      </TabPanel>
      <CustomSnackBar open={openS} message={'Скопировано!'} setOpen={setOpenS} />
      <Modal modalContent={modalGraf} open={showGrafModal} setOpen={setShowGrafModal} width={"90dvw"} />
    </TabContext>
  )
}

export default Statistic