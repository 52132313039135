import AutoFixOffIcon from "@mui/icons-material/AutoFixOff"
import AutoModeIcon from "@mui/icons-material/AutoMode"
import CloseIcon from "@mui/icons-material/Close"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import FilterListIcon from "@mui/icons-material/FilterList"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  IconButton,
  Menu,
  Paper,
  Stack,
  Switch,
  Tab,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { Dayjs } from "dayjs"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import CustomSelect from "../../../UI/customSelect/CustomSelect"
import CustomSnackBar from "../../../UI/customSnackBar/CustomSnackBar"
import CustomTable from "../../../UI/customTable/CustomTable"
import Modal from "../../../UI/modal/Modal"
import { useAppSelector } from "../../../redux/hooks/hooks"
import { GetPayTypeMethod } from "../../../services/Actives"
import {
  GetReqsGroupsMethod,
  GetReqsMethod,
  RemoveGroupByIdMethod,
  RemoveReqsMethod,
  SetReqsMethod,
} from "../../../services/Mains"
import {
  CustomSelectContentType,
  IGetGroup,
  IGetReqs,
  IPayType,
  IReqs,
} from "../../../types/types"
import { turncate } from "../../../utils/Turncate"
import { RequisitesSample } from "./RequisitesSample"

const Requisites = () => {
  const [payValue, setPayValue] = React.useState("payin")
  const [valueTabOne, setValueTabOne] = React.useState("1")
  const [valueTabTwo, setValueTabTwo] = React.useState("1")

  const handleChangeTabOne = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setValueTabOne(newValue)
  }
  const handleChangeTabTwo = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setValueTabTwo(newValue)
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [dateOn, setDateOn] = useState<Dayjs | null>(null)
  const [dateFor, setDateFor] = useState<Dayjs | null>(null)

  const [operationSelected, setOperationSelected] = useState("")

  const operationList: CustomSelectContentType[] = [
    { contentLabel: "Вывод средств", contentValue: "fundsWithdraws" },
    { contentLabel: "Пополнение средств", contentValue: "fundsDeposit" },
  ]

  const handleResetFilters = () => {
    setOperationSelected("")
    setDateFor(null)
    setDateOn(null)
    setAnchorEl(null)
  }

  const profile = useAppSelector((state) => state.app.profile)

  const getReqs = useCallback(async () => {
    if (!profile) return
    //const data = await GetReqsMethod(profile.id)
    //console.log(data)
  }, [profile])

  /*useEffect(() => {
    getReqs()
  }, [getReqs])*/

  // const [requisites, setRequisites] = useState<any[]>([])
  const [groups, setGroups] = useState<any[]>([])
  const [exchangeArchiveOrders, setExchangeArchiveOrders] = useState<any[]>([])

  const [openS, setOpenS] = React.useState(false)

  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const [openSnack, setOpenSnack] = useState(false)

  const copy = (str: string) => {
    navigator.clipboard.writeText(str).then(
      function () {
        setOpenS(true)
      },
      function (err) {
        console.error("Произошла ошибка при копировании текста: ", err)
      }
    )
  }

  const [openModalAuto, setOpenModalAuto] = useState(false)
  const [openModalAutoGroup, setOpenModalAutoGroup] = useState(false)

  const handleOpenModalAuto = () => {
    setOpenModalAuto(true)
  }
  const handleOpenModalAutoGroup = () => {
    setOpenModalAutoGroup(true)
  }

  const modalAuto = (
    <Box gap={"5px"}>
      <Typography variant="body1" fontWeight={700} gutterBottom>
        Автоматическая обработка ордеров
      </Typography>
      <Typography
        variant="subtitle2"
        fontWeight={700}
        sx={{ marginTop: "30px" }}>
        API ключ
      </Typography>
      <Typography variant="subtitle1" width={"max-content"} display={"block"}>
        <Tooltip
          onClick={() => copy("156dd7d8-1749-4331-8a28-7aa2c0b6ab95")}
          title="Скопировать"
          sx={{ cursor: "pointer" }}
          slots={{
            transition: Zoom,
          }}>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography noWrap fontSize={"0.7rem"}>
              156dd7d8-1749-4331-8a28-7aa2c0b6ab95
            </Typography>
            <ContentCopyIcon
              color="primary"
              sx={{ width: "15px", height: "15px" }}
            />
          </Stack>
        </Tooltip>
      </Typography>
      <Typography
        variant="subtitle2"
        fontWeight={700}
        sx={{ marginTop: "30px" }}>
        Способ автоматизации
      </Typography>
      <Typography variant="subtitle1" fontSize={"0.7rem"}>
        Автоматический (Группа)
      </Typography>
      <Stack
        flexDirection={"row"}
        justifyContent={"flex-end"}
        gap={"10px"}
        marginTop={"20px"}>
        <Button onClick={() => setOpenModalAuto(false)}>Отмена</Button>
        <Button variant="contained" color="primary" sx={{ fontWeight: 700 }}>
          Сохранить
        </Button>
      </Stack>
    </Box>
  )

  const modalAutoGroup = (
    <Box gap={"5px"}>
      <Typography variant="body1" fontWeight={700} gutterBottom>
        Автоматизация
      </Typography>
      <Typography
        variant="subtitle2"
        fontWeight={700}
        sx={{ marginTop: "30px" }}>
        API ключ
      </Typography>
      <Typography variant="subtitle1" width={"max-content"} display={"block"}>
        <Tooltip
          onClick={() => copy("156dd7d8-1749-4331-8a28-7aa2c0b6ab95")}
          title="Скопировать"
          sx={{ cursor: "pointer" }}
          slots={{
            transition: Zoom,
          }}>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography noWrap fontSize={"0.7rem"}>
              156dd7d8-1749-4331-8a28-7aa2c0b6ab95
            </Typography>
            <ContentCopyIcon
              color="primary"
              sx={{ width: "15px", height: "15px" }}
            />
          </Stack>
        </Tooltip>
      </Typography>
      <Typography
        variant="subtitle2"
        fontWeight={700}
        sx={{ marginTop: "30px" }}>
        Способ автоматизации
      </Typography>
      <ButtonGroup sx={{ marginTop: "5px" }}>
        <Button variant="outlined">Ручной</Button>
        <Button variant="contained">Автоматический</Button>
      </ButtonGroup>
      <Stack
        flexDirection={"row"}
        justifyContent={"flex-end"}
        gap={"10px"}
        marginTop={"20px"}>
        <Button onClick={() => setOpenModalAutoGroup(false)}>Отмена</Button>
        <Button variant="contained" color="primary" sx={{ fontWeight: 700 }}>
          Сохранить
        </Button>
      </Stack>
    </Box>
  )

  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalDeleteGroup, setOpenModalDeleteGroup] = useState(false)

  const handleOpenModalDelete = () => {
    setOpenModalDelete(true)
  }
  const handleOpenModalDeleteGroup = ({ group }: { group: IGetGroup }) => {
    setOpenModalDeleteGroup(true)
    setSelectReqGroup(group)
  }

  const handleOpenModalDeleteReq = ({ req }: { req: IGetReqs }) => {
    setOpenModalDelete(true)
    setSelectReq(req)
  }

  const [selectReq, setSelectReq] = useState<null | IGetReqs>(null)
  const [selectGroup, setSelectReqGroup] = useState<null | IGetGroup>(null)

  const deleteReq = async () => {
    const body = {
      id: selectReq?.id,
      user_id: profile?.id,
    }

    const dataDeleteReqs = await RemoveReqsMethod(body)

    if (dataDeleteReqs.status === 200 && dataDeleteReqs.data.Success) {
      setSuccessMessage("Реквизит успешно удален!")
      setOpenSnack(true)
      setOpenModalDelete(false)
      getRequisites()
      setTimeout(() => {
        setSuccessMessage("")
      }, 3000)
    } else {
      setErrorMessage("Что-то пошло не так..")
      setOpenSnack(true)
      setTimeout(() => {
        setErrorMessage("")
      }, 3200)
    }
  }

  const deleteGroup = async () => {
    const body = {
      id: selectGroup?.id,
      user_id: profile?.id,
    }

    const dataDeleteReqs = await RemoveGroupByIdMethod(body)

    if (dataDeleteReqs.status === 200 && dataDeleteReqs.data.Success) {
      setSuccessMessage("Группа успешно удалена!")
      setOpenSnack(true)
      setOpenModalDeleteGroup(false)
      getRequisites()
      setTimeout(() => {
        setSuccessMessage("")
      }, 3000)
    } else {
      setErrorMessage("Что-то пошло не так..")
      setOpenSnack(true)
      setTimeout(() => {
        setErrorMessage("")
      }, 3200)
    }
  }

  const modalDelete = (
    <Box gap={"5px"}>
      <Typography variant="body1" fontWeight={700} gutterBottom>
        Вы уверены, что хотите удалить реквизиты?
      </Typography>
      {selectReq?.pay_pay_id === 1 && (
        <Box>
          <Typography fontWeight={700} noWrap>
            {selectReq?.title}
          </Typography>
          <Typography variant="subtitle1" fontSize={"0.7rem"} noWrap>
            {selectReq?.currency_symbol}
          </Typography>
          <Typography variant="subtitle1" fontSize={"0.7rem"} noWrap>
            {selectReq?.reqs_types_title}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textDisabled"
            fontSize={"0.7rem"}
            noWrap>
            {selectReq?.bank_title}
          </Typography>
          {/*<Typography
          variant="caption"
          noWrap
          width={"max-content"}
          display={"block"}>
          <Tooltip
            onClick={() => copy("044525225")}
            title="БИК: 044525225"
            sx={{ cursor: "pointer" }}
            slots={{
              transition: Zoom,
            }}>
            <Stack flexDirection={"row"} gap={"5px"}>
              <Typography variant="subtitle1" fontSize={"0.7rem"} noWrap>
                БИК: 044525225
              </Typography>
              <ContentCopyIcon
                color="primary"
                sx={{ width: "15px", height: "15px" }}
              />
            </Stack>
          </Tooltip>
        </Typography>*/}
          <Typography
            variant="caption"
            noWrap
            width={"max-content"}
            display={"block"}>
            <Tooltip
              onClick={() => copy(`${selectReq?.value}`)}
              title={selectReq?.value}
              sx={{ cursor: "pointer" }}
              slots={{
                transition: Zoom,
              }}>
              <Stack flexDirection={"row"} gap={"5px"}>
                <Typography variant="subtitle1" fontSize={"0.7rem"} noWrap>
                  {selectReq?.value}
                </Typography>
                <ContentCopyIcon
                  color="primary"
                  sx={{ width: "15px", height: "15px" }}
                />
              </Stack>
            </Tooltip>
          </Typography>
          {/*<Typography variant="subtitle1" fontSize={"0.7rem"} noWrap>
          Name name
        </Typography>
        <Typography variant="subtitle1" fontSize={"0.7rem"} noWrap>
          3135
        </Typography>*/}
        </Box>
      )}
      <Stack
        flexDirection={"row"}
        justifyContent={"flex-end"}
        gap={"10px"}
        marginTop={"20px"}>
        <Button onClick={() => setOpenModalDelete(false)}>Отмена</Button>
        <Button
          variant="contained"
          color="error"
          sx={{ fontWeight: 700 }}
          onClick={deleteReq}>
          Ок
        </Button>
      </Stack>
    </Box>
  )

  const modalDeleteGroup = (
    <Box gap={"5px"}>
      <Typography variant="body1" fontWeight={700} gutterBottom>
        Подтверждение удаления
      </Typography>
      <Typography
        variant="subtitle1"
        fontSize={"0.7rem"}
        marginTop={"20px"}
        noWrap>
        Вы уверены, что хотите удалить элемент?
      </Typography>
      <Stack
        flexDirection={"row"}
        justifyContent={"flex-end"}
        gap={"10px"}
        marginTop={"20px"}>
        <Button onClick={() => setOpenModalDeleteGroup(false)}>Отмена</Button>
        <Button
          variant="contained"
          color="error"
          sx={{ fontWeight: 700 }}
          onClick={deleteGroup}>
          Ок
        </Button>
      </Stack>
    </Box>
  )

  const [status, setStatus] = useState(false)

  const handleChangeStatus = () => {
    setStatus(!status)
  }

  const navigate = useNavigate()

  const [rowRequisites, setRowRequisites] = useState<IReqs[]>([])
  const [requisites, setRequisites] = useState<any[]>([])
  const [requisitesPayout, setRequisitesPayout] = useState<any[]>([])

  // SET REQUISITES
  useEffect(() => {
    const toRender = RequisitesSample({
      rowRequisites,
      setOpenS,
      handleOpenModalAuto,
      handleOpenModalDelete,
    })
    setRequisites(toRender)
  }, [rowRequisites])

  const sendEditRequisites = async (req: IGetReqs, checked: boolean) => {
    if (!profile) return
    const body = {
      ...req,
      reqs_status_id: +checked,
    }

    const dataCreateReqs = await SetReqsMethod(body)

    if (dataCreateReqs.status === 200 && dataCreateReqs.data.Success) {
      setSuccessMessage("Реквизит успешно изменен!")
      setOpenSnack(true)
      setTimeout(() => {
        setSuccessMessage("")
      }, 3000)
    } else {
      setErrorMessage("Что-то пошло не так..")
      setOpenSnack(true)
      setTimeout(() => {
        setErrorMessage("")
      }, 3200)
    }
  }

  const getRequisites = useCallback(async () => {
    if (!profile) return
    const dataPayType = await GetPayTypeMethod(0)

    const payinId = dataPayType.data.data.find(
      (el: IPayType) => el.title === "Payin"
    ).id
    const payoutId = dataPayType.data.data.find(
      (el: IPayType) => el.title === "Payout"
    ).id

    const dataReqsPayin = await GetReqsMethod(profile.id, payinId)
    const dataReqsPayout = await GetReqsMethod(profile.id, payoutId)

    const dataReqsGroups = await GetReqsGroupsMethod(0)

    const rowsReqs = dataReqsPayin?.data?.data.map((req: IGetReqs) => [
      <Switch
        defaultChecked={req.pay_status === "Активен"}
        value={req.pay_status === "Активен"}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
          checked: boolean
        ) => sendEditRequisites(req, checked)}
      />,
      <Box>
        <Typography variant="caption" width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(`${req.pay_reqs_uuid}`)}
            title="Скопировать"
            sx={{ cursor: "pointer" }}
            slots={{
              transition: Zoom,
            }}>
            <Stack flexDirection={"row"} gap={"5px"}>
              <Typography noWrap fontSize={"0.7rem"}>
                {turncate(`${req.pay_reqs_uuid}`, 21)}
              </Typography>
              <ContentCopyIcon
                color="primary"
                sx={{ width: "15px", height: "15px" }}
              />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant="caption" fontWeight={300} fontSize={"0.7rem"}>
          {req.date}
        </Typography>
      </Box>,
      <Box>
        <Typography variant="caption" fontWeight={300} fontSize={"0.7rem"}>
          {req.title}
          <br />
        </Typography>
        <Typography variant="caption" fontWeight={300} fontSize={"0.7rem"}>
          {req.reqs_types_title}
          <br />
        </Typography>
        <Typography variant="caption" fontWeight={300} fontSize={"0.7rem"}>
          {req.bank_title}
          <br />
        </Typography>
        <Typography variant="caption" width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(`${req.value}`)}
            title="Скопировать"
            sx={{ cursor: "pointer" }}
            slots={{
              transition: Zoom,
            }}>
            <Stack flexDirection={"row"} gap={"5px"}>
              <Typography noWrap fontSize={"0.7rem"}>
                {turncate(`${req.value}`, 21)}
              </Typography>
              <ContentCopyIcon
                color="primary"
                sx={{ width: "15px", height: "15px" }}
              />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant="caption" fontWeight={300} fontSize={"0.7rem"}>
          {req.date}
        </Typography>
      </Box>,
      <Typography variant="subtitle1" fontSize={"0.7rem"}>
        <span>{req.qty_limit_hour}</span>
        <span> / </span>
        <span>{req.qty_limit_day}</span>
        <span> / </span>
        <span>{req.qty_limit_month}</span>
      </Typography>,
      <Typography variant="subtitle1" fontSize={"0.7rem"}>
        <span>{req.sum_limit_hour}</span>
        <span> / </span>
        <span>{req.sum_limit_day}</span>
        <span> / </span>
        <span>{req.sum_limit_month}</span>
      </Typography>,
      <Typography variant="subtitle1" fontSize={"0.7rem"}>
        {req.pay_reqs_groups_title}
      </Typography>,
      <Typography variant="subtitle1" fontSize={"0.7rem"}>
        {req.pay_automation_type_title}
      </Typography>,
      <Typography variant="subtitle1" fontSize={"0.7rem"}>
        {req.sequence}
      </Typography>,
      <Stack flexDirection={"row"} gap={"5px"}>
        <IconButton color="primary" onClick={handleOpenModalAutoGroup}>
          <AutoModeIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() =>
            navigate(`/trader/requisites/payin/${req.id}`, {
              state: req,
            })
          }>
          <EditIcon />
        </IconButton>
        <IconButton
          color="error"
          onClick={() => handleOpenModalDeleteReq({ req })}>
          <DeleteIcon />
        </IconButton>
      </Stack>,
    ])

    setRequisites(rowsReqs)

    const rowsReqsPayuot = dataReqsPayout?.data?.data.map((req: IGetReqs) => [
      <Switch
        defaultChecked={req.pay_status === "Активен"}
        value={req.pay_status === "Активен"}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
          checked: boolean
        ) => sendEditRequisites(req, checked)}
      />,
      <Box>
        <Typography variant="caption" width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(`${req.pay_reqs_uuid}`)}
            title="Скопировать"
            sx={{ cursor: "pointer" }}
            slots={{
              transition: Zoom,
            }}>
            <Stack flexDirection={"row"} gap={"5px"}>
              <Typography noWrap fontSize={"0.7rem"}>
                {turncate(`${req.pay_reqs_uuid}`, 21)}
              </Typography>
              <ContentCopyIcon
                color="primary"
                sx={{ width: "15px", height: "15px" }}
              />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant="caption" fontWeight={300} fontSize={"0.7rem"}>
          {req.date}
        </Typography>
      </Box>,
      <Box>
        <Typography variant="caption" fontWeight={300} fontSize={"0.7rem"}>
          {req.currency_symbol}
          <br />
        </Typography>
        <Typography variant="caption" fontWeight={300} fontSize={"0.7rem"}>
          {req.reqs_types_title}
          <br />
        </Typography>
        <Typography variant="caption" fontWeight={300} fontSize={"0.7rem"}>
          {req.bank_title}
          <br />
        </Typography>
      </Box>,
      <Typography variant="subtitle1" fontSize={"0.7rem"}>
        <span>от {req.min_sum_per_transaction}</span>
        <span> до {req.max_sum_per_transaction}</span>
      </Typography>,
      <Typography variant="subtitle1" fontSize={"0.7rem"}>
        {req.max_limit_transaction_sum}
      </Typography>,
      <Typography variant="subtitle1" fontSize={"0.7rem"}>
        {req.max_limit_transaction}
      </Typography>,
      <Typography variant="subtitle1" fontSize={"0.7rem"}>
        {req.other_banks === 0 ? "Нет" : "Да"}
      </Typography>,
      <Stack flexDirection={"row"} gap={"5px"}>
        <IconButton
          color="primary"
          onClick={() =>
            navigate(`/trader/requisites/payout/${req.id}`, {
              state: req,
            })
          }>
          <EditIcon />
        </IconButton>
        <IconButton
          color="error"
          onClick={() => handleOpenModalDeleteReq({ req })}>
          <DeleteIcon />
        </IconButton>
      </Stack>,
    ])

    setRequisitesPayout(rowsReqsPayuot)

    const rowsGroups = dataReqsGroups?.data?.data.map((group: IGetGroup) => [
      <Box>
        <Typography variant="caption" width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(`${group.id}`)}
            title="Скопировать"
            sx={{ cursor: "pointer" }}
            slots={{
              transition: Zoom,
            }}>
            <Stack flexDirection={"row"} gap={"5px"}>
              <Typography noWrap fontSize={"0.7rem"}>
                {turncate(`${group.id}`, 21)}
              </Typography>
              <ContentCopyIcon
                color="primary"
                sx={{ width: "15px", height: "15px" }}
              />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant="caption" fontWeight={300} fontSize={"0.7rem"}>
          {group.date}
        </Typography>
      </Box>,
      <Typography variant="subtitle1" fontSize={"0.7rem"}>
        {group.title}
      </Typography>,
      <Typography variant="caption" width={"max-content"} display={"block"}>
        <Tooltip
          onClick={() => copy(group.group_uuid)}
          title="Скопировать"
          sx={{ cursor: "pointer" }}
          slots={{
            transition: Zoom,
          }}>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography noWrap fontSize={"0.7rem"}>
              {turncate(group.group_uuid, 21)}
            </Typography>
            <ContentCopyIcon
              color="primary"
              sx={{ width: "15px", height: "15px" }}
            />
          </Stack>
        </Tooltip>
      </Typography>,
      <Stack flexDirection={"row"} alignItems={"center"} gap={"5px"}>
        <Typography variant="subtitle1" fontSize={"0.7rem"}>
          {group.types_automation_title}
        </Typography>
        <Tooltip
          title="Автоматическое выключение реквизитов без доступа к автоматике"
          sx={{ cursor: "pointer" }}
          slots={{
            transition: Zoom,
          }}>
          <AutoFixOffIcon />
        </Tooltip>
      </Stack>,
      <Stack flexDirection={"row"} gap={"5px"}>
        <IconButton color="primary" onClick={handleOpenModalAutoGroup}>
          <AutoModeIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() =>
            navigate(`/trader/requisites/payin/group/edit/${group.id}`, {
              state: group,
            })
          }>
          <EditIcon />
        </IconButton>
        <IconButton
          color="error"
          onClick={() => handleOpenModalDeleteGroup({ group })}>
          <DeleteIcon />
        </IconButton>
      </Stack>,
    ])

    setGroups(rowsGroups)
  }, [navigate, profile])

  useEffect(() => {
    getRequisites()
  }, [getRequisites])

  const rowsHeadRequisites = [
    "Статус",
    "ID",
    "Реквизиты",
    "Количество (час/день/месяц)",
    "Сумма (час/день/месяц)",
    "Группа",
    "Способ автоматизации",
    "Частота успешных ордеров",
    "Действия",
  ]

  const rowsHeadRequisitesPayout = [
    "Статус",
    "ID",
    "Реквизиты",
    "Сумма транзакции",
    "Сумма",
    "Количество",
    "Другие банки",
    "Действия",
  ]

  const rowsHeadGroups = [
    "ID",
    "Название",
    "API ключ автоматизации",
    "Способ автоматизации",
    "Действия",
  ]

  const redirect = () => { }
  return (
    <TabContext value={payValue}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}>
        <Stack flexDirection={"row"} gap={"10px"}>
          <Chip
            label={"PayIn"}
            variant="outlined"
            color={payValue === "payin" ? "primary" : "default"}
            onClick={() => setPayValue("payin")}
          />
          <Chip
            label={"PayOut"}
            variant="outlined"
            color={payValue === "payout" ? "primary" : "default"}
            onClick={() => setPayValue("payout")}
          />
        </Stack>
        <Button
          variant="contained"
          sx={{ fontWeight: 700 }}
          type="submit"
          onClick={() =>
            navigate(
              valueTabOne === "1"
                ? `/trader/requisites/${payValue}/new`
                : `/trader/requisites/${payValue}/group/new`
            )
          }>
          Создать
        </Button>
      </Box>

      <TabPanel value="payin" sx={{ padding: "10px 0" }}>
        <TabContext value={valueTabOne}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChangeTabOne}>
              <Tab label="Реквизиты" value="1" />
              <Tab label="Группы" value="2" />
              <Tab label="Телеграмм автоматизация" value="3" />
            </TabList>
          </Box>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ padding: "8px 16px 0 16px" }}>
            <IconButton onClick={handleClick}>
              <FilterListIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
                sx: {
                  padding: "8px 16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                },
              }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "60px",
                }}>
                <Typography variant="h6" fontWeight={600}>
                  Фильтрация
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <DatePicker
                label="Дата от"
                value={dateOn}
                onChange={(newValue) => setDateOn(newValue)}
              />
              <DatePicker
                label="Дата до"
                value={dateFor}
                onChange={(newValue) => setDateFor(newValue)}
              />
              <CustomSelect
                label="Операция"
                content={operationList}
                variant="outlined"
                value={operationSelected}
                setValue={setOperationSelected}
              />
              <Stack flexDirection={"row"} gap={"10px"}>
                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  onClick={handleResetFilters}>
                  Сбросить
                </Button>
                <Button variant="outlined" fullWidth>
                  OK
                </Button>
              </Stack>
            </Menu>
          </Stack>
          <TabPanel value="1" sx={{ padding: "10px 0" }}>
            {requisites && requisites?.length ? (
              <CustomTable
                rowsDataHead={rowsHeadRequisites}
                rowsDataBody={requisites}
                paginationCol={9}
              />
            ) : (
              <Paper
                sx={{
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}>
                <ErrorOutlineIcon />
                Данные не найдены
              </Paper>
            )}
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "10px 0" }}>
            {groups?.length ? (
              <CustomTable
                rowsDataHead={rowsHeadGroups}
                rowsDataBody={groups}
                paginationCol={5}
              />
            ) : (
              <Paper
                sx={{
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}>
                <ErrorOutlineIcon />
                Данные не найдены
              </Paper>
            )}
          </TabPanel>
          <TabPanel value="3" sx={{ padding: "10px 0" }}>
            {exchangeArchiveOrders?.length ? (
              <CustomTable
                rowsDataHead={rowsHeadRequisites}
                rowsDataBody={exchangeArchiveOrders}
                paginationCol={4}
              />
            ) : (
              <Paper
                sx={{
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}>
                <ErrorOutlineIcon />
                Данные не найдены
              </Paper>
            )}
          </TabPanel>
        </TabContext>
      </TabPanel>
      <TabPanel value="payout" sx={{ padding: "10px 0" }}>
        <TabContext value={valueTabTwo}>
          {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab label="Реквизиты" value="1" />
              <Tab label="Группы" value="2" />
              <Tab label="Телеграмм автоматизация" value="3" />
            </TabList>
          </Box> */}
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ padding: "8px 16px 0 16px" }}>
            <IconButton onClick={handleClick}>
              <FilterListIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
                sx: {
                  padding: "8px 16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                },
              }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "60px",
                }}>
                <Typography variant="h6" fontWeight={600}>
                  Фильтрация
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <DatePicker
                label="Дата от"
                value={dateOn}
                onChange={(newValue) => setDateOn(newValue)}
              />
              <DatePicker
                label="Дата до"
                value={dateFor}
                onChange={(newValue) => setDateFor(newValue)}
              />
              <CustomSelect
                label="Операция"
                content={operationList}
                variant="outlined"
                value={operationSelected}
                setValue={setOperationSelected}
              />
              <Stack flexDirection={"row"} gap={"10px"}>
                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  onClick={handleResetFilters}>
                  Сбросить
                </Button>
                <Button variant="outlined" fullWidth>
                  OK
                </Button>
              </Stack>
            </Menu>
          </Stack>
          <TabPanel value="1" sx={{ padding: "10px 0" }}>
            {requisitesPayout?.length ? (
              <CustomTable
                rowsDataHead={rowsHeadRequisitesPayout}
                rowsDataBody={requisitesPayout}
                paginationCol={9}
              />
            ) : (
              <Paper
                sx={{
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}>
                <ErrorOutlineIcon />
                Данные не найдены
              </Paper>
            )}
          </TabPanel>
          {/*} <TabPanel value="2" sx={{ padding: '10px 0' }}>
            {
              groups.length ?
                <CustomTable rowsDataHead={rowsHeadGroups} rowsDataBody={groups} paginationCol={5} />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="3" sx={{ padding: '10px 0' }}>
            {
              exchangeArchiveOrders.length ?
                <CustomTable rowsDataHead={rowsHeadRequisites} rowsDataBody={exchangeArchiveOrders} paginationCol={4} />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="1" sx={{ padding: "10px 0" }}>
            <Paper
              sx={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}>
              <ErrorOutlineIcon />
              Данные не найдены
            </Paper>
          </TabPanel>*/}
        </TabContext>
      </TabPanel>
      <CustomSnackBar
        open={openS}
        message={"Скопировано!"}
        setOpen={setOpenS}
      />
      <Modal
        modalContent={modalAuto}
        open={openModalAuto}
        setOpen={setOpenModalAuto}
      />
      <Modal
        modalContent={modalAutoGroup}
        open={openModalAutoGroup}
        setOpen={setOpenModalAutoGroup}
      />
      <Modal
        modalContent={modalDelete}
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        width={450}
      />
      <Modal
        modalContent={modalDeleteGroup}
        open={openModalDeleteGroup}
        setOpen={setOpenModalDeleteGroup}
        width={450}
      />
      <CustomSnackBar
        open={openSnack}
        message={errorMessage || successMessage}
        setOpen={setOpenSnack}
      />
    </TabContext>
  )
}

export default Requisites
