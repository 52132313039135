import { Box, Chip, Stack, Tooltip, Typography, Zoom } from "@mui/material"
import { copy } from "../../../utils/Copy"
import { turncate } from "../../../utils/Turncate"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonIcon from '@mui/icons-material/Person';

interface WithdrawsSamplePropsType {
  rowWithdraws: any[]
  // rowWithdraws: IWithdraw[]
  setOpenS: (param: boolean) => void
  // handleOpenModalAuto: () => void
  // handleOpenModalDelete: () => void
}

export const WithdrawsSample = ({
  rowWithdraws,
  setOpenS,
  // handleOpenModalAuto, 
  // handleOpenModalDelete 
}: WithdrawsSamplePropsType) => {
  return rowWithdraws.map((withdraw, index) => (
    [
      <Box>
        <Typography variant="caption" width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(withdraw.uuid, setOpenS)}
            title="Скопировать"
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography noWrap fontSize={'0.7rem'}>{turncate(withdraw.uuid, 21)}</Typography>
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant="caption" fontWeight={300} fontSize={'0.7rem'}>{withdraw.date}</Typography>
      </Box>,
      <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>{withdraw.course} ₽</Typography>,
      <Box>
        <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(`${+withdraw.value * +withdraw.course}`, setOpenS)}
            title={'Скопировать сумму'}
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                {+withdraw.value * +withdraw.course} ₽
              </Typography>
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(`${withdraw.value}`, setOpenS)}
            title={'Скопировать сумму'}
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} alignItems={"center"} gap={'5px'}>
              <Chip color='primary' variant='outlined' icon={<AccountBalanceWalletIcon />} label={`${withdraw.value} ${withdraw.withdraw_symbol}`} size='small' />
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
      </Box>,
      <Box>
        <Typography fontWeight={700} noWrap>{withdraw.id}</Typography>
        <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>test{withdraw.id}</Typography>
        <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>test{withdraw.id}</Typography>
        <Typography variant='subtitle2' color='textDisabled' fontSize={'0.7rem'} noWrap>test{withdraw.id}</Typography>
        <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(`test${withdraw.id}`, setOpenS)}
            title={`БИК: test${withdraw.id}`}
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                БИК: test{withdraw.id}
              </Typography>
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy(`test${withdraw.id}`, setOpenS)}
            title={`test${withdraw.id}`}
            sx={{ cursor: 'pointer' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
                test{withdraw.id}
              </Typography>
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
        <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>test{withdraw.id}</Typography>
      </Box>,
      <Typography variant='subtitle1' fontSize={'0.7rem'}>Перевод по номеру счета</Typography>,
      <Box>
        <Tooltip
          title={'Закрыто вручную'}
          sx={{ cursor: 'pointer' }}
          slots={{
            transition: Zoom,
          }}
        >
          {/* <SmartToyIcon color='primary' sx={{ width: '15px', height: '15px' }} /> */}
          <PersonIcon sx={{ width: '15px', height: '15px' }} />
        </Tooltip>
      </Box>,
    ]
  ))
}

