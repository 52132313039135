import { IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { IPayMethod } from "../../../types/types";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

interface PayMethodsSamplePropsType {
  rowPayMethods: IPayMethod[]
  setOpenS: (param: boolean) => void
  handleOpenModalEdit: (id: number) => void
  handleOpenModalDelete: (id: number) => void
}

export const PayMethodsSample = ({
  rowPayMethods,
  setOpenS,
  handleOpenModalEdit,
  handleOpenModalDelete
}: PayMethodsSamplePropsType) => {
  return rowPayMethods.map((payMethod, index) => (
    [
      <Typography variant='subtitle1' noWrap>{payMethod.id}</Typography>,
      <Typography variant='subtitle1' noWrap>{payMethod.title}</Typography>,
      <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
        <Tooltip title="Редактировать">
          <IconButton color="info" onClick={() => handleOpenModalEdit(payMethod.id)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Удалить">
          <IconButton color="error" onClick={() => handleOpenModalDelete(payMethod.id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    ]
  ))
}

