import { Typography } from "@mui/material"
import { IChart } from "../../../types/types"

interface ChartsSamplePropsType {
  rowCharts: IChart[]
  setOpenS: (param: boolean) => void
}

export const ChartsSample = ({
  rowCharts,
  setOpenS,
}: ChartsSamplePropsType) => {
  return rowCharts.map((chart, index) => (
    [
      <Typography variant='subtitle2' noWrap>{chart.id}</Typography>,
      <Typography variant='subtitle2' noWrap>{chart.symbol}</Typography>,
      <Typography variant='subtitle2' noWrap>Действие</Typography>,
    ]
  ))
}

