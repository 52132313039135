import { Autocomplete, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React from 'react'
import { CustomSelectContentType } from '../../types/types';

interface CustomSelectPropsType {
  label: string
  content: CustomSelectContentType[]
  variant?: 'standard' | 'outlined' | 'filled'
  value: string
  setValue: (param: string) => void
  size?: 'small' | 'medium'
  disabled?: boolean
  required?: boolean
  defaultValue?: string
  autosuggest?: boolean
}

const CustomSelect = ({
  label,
  content,
  variant,
  value,
  setValue,
  size,
  disabled,
  required,
  defaultValue,
  autosuggest,
}: CustomSelectPropsType) => {

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const defaultProps = {
    options: content,
    getOptionLabel: (option: CustomSelectContentType) => option.contentLabel,
  };

  return (
    <>
      {
        autosuggest ?
          <FormControl required={required} variant={variant} disabled={disabled} sx={{ marginBottom: '8px' }} fullWidth>
            <Autocomplete
              {...defaultProps}
              size={size}
              renderInput={(params) => <TextField {...params} label={label} />}
              onChange={handleChange}
            />
          </FormControl>
          :
          <FormControl required={required} variant={variant} size={size} disabled={disabled} sx={{ marginBottom: '8px' }} fullWidth>
            <InputLabel id="simple-select-standard-label">{label}</InputLabel>
            <Select
              labelId="simple-select-standard-label"
              id="simple-select-standard"
              value={value}
              onChange={handleChange}
              label={label}
              defaultValue={defaultValue}
            >
              {
                content.map((element, index) => {
                  return <MenuItem key={index} value={element.contentValue}>{element.contentLabel}</MenuItem>
                })
              }
            </Select>
          </FormControl>
      }
    </>
  )
}

export default CustomSelect