import React, { useCallback, useEffect, useState } from 'react'
import { IUser } from '../../../types/types'
import { useLocation } from 'react-router-dom'
import { Box, Button, Card, CardContent, CircularProgress, Divider, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { BanUserMethod, GetUsersMethod, SetUserRoleMethod } from '../../../services/admin/Users'

const UsersEdit = () => {
  const location = useLocation()
  const userLocation: IUser = location.state

  if (!userLocation) document.location.href = '/'

  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<IUser | null>(null)

  const getUser = useCallback(async () => {
    const data = await GetUsersMethod(userLocation.id)
    if (data.status === 200 && data.data.Success) {
      setUser(data.data.data[0])
      setLoading(false)
    }
  }
    , [userLocation.id])

  useEffect(() => {
    getUser()
  }, [getUser])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
  }

  const updateRole = async (role: number) => {
    if (!user) return
    const data = await SetUserRoleMethod(user.id, role);
    if (data.status === 200 && data.data.Success) {
      getUser()
      setAnchorEl(null);
    }
  };

  const SetBanUser = async (banned: 0 | 1) => {
    if (!user) return
    const data = await BanUserMethod(user.id, user.login, banned);
    if (data.status === 200 && data.data.Success) {
      getUser()
    }
  }

  return (
    <>
      {
        !loading ?
          user &&
          <Box>
            <Stack flexDirection={"row"} gap={"10px"} marginBottom={"20px"}>
              <Typography variant='h5'>Информация пользователя:</Typography>
              <Typography variant='h5' color='primary'>{user.email}</Typography>
            </Stack>
            <Card>
              <CardContent>
                <Stack flexDirection={"row"} gap={"10px"} margin={"20px 0"}>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"}>ID:</Typography>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"} color='primary'>{user.id}</Typography>
                </Stack>
                <Divider />
                <Stack flexDirection={"row"} gap={"10px"} margin={"20px 0"}>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"}>Email:</Typography>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"} color='primary'>{user.email}</Typography>
                </Stack>
                <Divider />
                <Stack flexDirection={"row"} gap={"10px"} margin={"20px 0"}>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"}>Login:</Typography>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"} color='primary'>{user.login}</Typography>
                </Stack>
                <Divider />
                <Stack flexDirection={"row"} gap={"10px"} margin={"20px 0"}>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"}>Telegram:</Typography>
                  <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"} color='primary'>{user.telegram ?? 'Не подключен'}</Typography>
                </Stack>
                <Divider />
                <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"} margin={"20px 0"} justifyContent={"space-between"}>
                  <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
                    <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"}>Роль:</Typography>
                    <Typography variant='subtitle2' fontWeight={700} fontSize={"1.1rem"} color='primary'>
                      {
                        user.role_id === 1
                          ? 'Админ'
                          : user.role_id === 2
                            ? 'Мерчант'
                            : user.role_id === 3
                              ? 'Магазин'
                              : 'Трейдер'
                      }
                    </Typography>
                  </Stack>
                  <Button
                    variant='outlined'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    Изменить
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => updateRole(1)}>Админ</MenuItem>
                    <MenuItem onClick={() => updateRole(2)}>Мерчант</MenuItem>
                    <MenuItem onClick={() => updateRole(4)}>Трейдер</MenuItem>
                  </Menu>
                </Stack>
                <Stack flexDirection={"row"} gap={"10px"} marginBottom={"20px"}>
                  {
                    user.banned_status === 0
                      ? <Button color='error' variant='outlined' onClick={() => SetBanUser(1)}>Заблокировать</Button>
                      : <Button color='primary' variant='outlined' onClick={() => SetBanUser(0)}>Разблокировать</Button>
                  }
                </Stack>
              </CardContent>
            </Card>
          </Box>
          :
          <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"200px"}>
            <CircularProgress size="40px" />
          </Stack>
      }
    </>
  )
}

export default UsersEdit