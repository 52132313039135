import { createTheme, Theme } from "@mui/material";

// export const providerTheme = createTheme({
//   colorSchemes: { light: true, dark: true },
//   palette: {
//     mode: 'dark',
//     primary: {
//       main: '#1caf86'
//     },
//     secondary: {
//       main: '#ffffff'
//     },
//     background: {
//       default: '#191b1e',
//       // paper: '#bdbdbd'
//     }
//   },
// });

export const createAppTheme = (mode: 'light' | 'dark'): Theme => {
  return createTheme({
    palette: {
      mode,
      primary: {
        main: '#1caf86',
      },
      secondary: {
        main: '#ffffff',
      },
      background: {
        default: mode === 'dark' ? '#191b1e' : '#ffffff',
        paper: mode === 'dark' ? '#121212' : '#f5f5f5',
      },
    },
  });
};