import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import React, { memo, useEffect, useRef, useState } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

interface CustomInputPropsType {
  label: string,
  errorMessage?: string,
  disabled?: boolean,
  required?: boolean,
  autoComplete?: string,
  value?: string,
  shrink?: boolean,
  notched?: boolean,
  type?: string,
  numeric?: boolean,
  setValue?: (param: string) => void,
  name?: string,
  adornment?: string
  size?: 'small' | 'medium'
}

const CustomInput = memo(({
  label,
  errorMessage,
  disabled,
  required,
  autoComplete,
  value,
  shrink,
  notched,
  type,
  numeric,
  setValue,
  name,
  adornment,
  size
}: CustomInputPropsType) => {
  const [localValue, setLocalValue] = useState(value?.trim() ?? '');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const numericRef = useRef<HTMLInputElement | null>(null);
  const [isInitialRender, setIsInitialRender] = useState(true);  // Флаг для первого рендера



  // Синхронизация значения состояния
  useEffect(() => {
    setLocalValue(value?.trim() ?? '');
  }, [value]);

  // Обработчик изменения значения
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim();
    setLocalValue(newValue);
    setValue?.(newValue); // Обновляем глобальное состояние
  };

  // Восстановление фокуса
  useEffect(() => {
    if (inputRef.current && numeric) {
      inputRef.current.focus();
    }
  }, [localValue, numeric]); // Используем localValue, чтобы фокусировать инпут после его изменения


  // Новый компонент с использованием NumericFormat
  const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;

    // useEffect для установки фокуса на первый рендер
    useEffect(() => {
      if (isInitialRender && numericRef.current) {
        numericRef.current.focus();
        setIsInitialRender(false);  // Убираем флаг, чтобы не пытаться установить фокус снова
      }
    }, [isInitialRender]);

    return (
      <NumericFormat
        {...other}
        getInputRef={(input: any) => {
          numericRef.current = input;
          if (ref) {
            // Прокидываем ref, если это нужно для других целей
            if (typeof ref === 'function') {
              ref(input);
            } else {
              (ref as React.MutableRefObject<HTMLInputElement | null>).current = input;
            }
          }
        }}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
          setLocalValue(values.value.trim()); // Обновляем локальное состояние
          setValue?.(values.value.trim()); // Обновляем глобальное состояние
        }}
        thousandSeparator
        valueIsNumericString
        // suffix=" USDT"
        allowNegative={false}
      />
    );
  });

  return (
    <FormControl size={size ? size : 'medium'} fullWidth required={required} error={errorMessage ? true : false} disabled={disabled} sx={{ marginBottom: '8px' }}>
      <InputLabel
        htmlFor={`${name}-input`}
        shrink={shrink}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        notched={notched}
        label={label}
        autoComplete={autoComplete}
        id={`${name}-input`}
        fullWidth
        required={required}
        value={localValue}
        type={type}
        disabled={disabled}
        inputComponent={numeric ? NumericFormatCustom as any : null}
        onChange={handleValueChange}
        inputRef={inputRef}
        endAdornment={adornment ? <InputAdornment position="end">USDT</InputAdornment> : null}
      />
      <FormHelperText error={errorMessage ? true : false} children={errorMessage} />
    </FormControl>
  )
})

export default CustomInput