import { ApiClient } from "../Client"


export const GetPayMethodsMethod = async () => {
  return await ApiClient({
    url: `/mains/get-pay-reqs-types/0`
  })
}

export const SetPayMethodsMethod = async (title: string, id?: number) => {
  return await ApiClient({
    method: "POST",
    url: "/mains/set-pay-reqs-types",
    data: {
      title: title,
      ...(id && { id: id })
    }
  })
}

export const DelPayMethodsMethod = async (id: number) => {
  return await ApiClient({
    method: "POST",
    url: "/mains/remove-pay-reqs-types",
    data: {
      id: id
    }
  })
}