import { Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, SwipeableDrawer, Toolbar, Typography } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Data } from '../customTable/CustomTable';
import ClearIcon from '@mui/icons-material/Clear';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface CustomDrawerPropsType {
  side: Anchor;
  details: Data | undefined;
  setDetails: (param: Data | undefined) => void;
  rowHeads: string[]
}

const CustomDrawer = ({
  side,
  details,
  setDetails,
  rowHeads,
}: CustomDrawerPropsType) => {

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = useCallback(
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      }
    , [state])

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 'auto' }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Toolbar />
      <Stack flexDirection={"row"} alignItems={"center"} gap={"120px"} padding={"8px 16px"} justifyContent={"space-between"}>
        <Typography>Детали</Typography>
        <IconButton size='small' onClick={() => { setDetails(undefined); toggleDrawer(side, false) }}><ClearIcon /></IconButton>
      </Stack>
      <Divider />
      {
        details && rowHeads &&
        rowHeads.map((head, index) => (
          <Box padding={"8px 16px"} key={index}>
            <Typography fontWeight={700}>{head}</Typography>
            {details[`key${index}`]}
          </Box>
        ))
      }
    </Box>
  );

  return (
    <SwipeableDrawer
      hideBackdrop
      disableSwipeToOpen
      anchor={side}
      open={Boolean(details?.['key0'])}
      onClose={toggleDrawer(side, false)}
      onOpen={toggleDrawer(side, true)}
      sx={{ width: "0px" }}
    >
      {list(side)}
    </SwipeableDrawer>
  )
}

export default CustomDrawer