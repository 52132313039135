import { Logout, Settings } from '@mui/icons-material';
import Grid from '@mui/material/Grid2'
import { Avatar, Box, Divider, FormControlLabel, FormGroup, IconButton, ListItemAvatar, Menu, MenuItem, Switch, Tooltip, Typography, useColorScheme } from '@mui/material'
import React, { useState } from 'react'
import styles from './Account.module.scss'
import { LogOutMethod } from '../../services/Auth';
import { useNavigate } from 'react-router-dom';
import { useThemeContext } from '../../ThemeContext';
import { IProfile } from '../../types/types';
import DarkModeIcon from '@mui/icons-material/DarkMode';

interface AccountPropsType {
  profile: IProfile
  role: "trader" | "admin" | "merchant"
}

const Account = ({
  profile,
  role,
}: AccountPropsType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await LogOutMethod();
    localStorage.clear();
    document.location.href = '/'
  }

  const [active, setActive] = useState(Boolean(!profile.banned_status))

  const navigate = useNavigate();

  const { mode, toggleTheme } = useThemeContext();

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch onChange={() => setActive(!active)} />}
            label={''}
            labelPlacement="start"
            sx={{
              '@media screen and (min-width: 341px) and (max-width: 415px)': {
                display: 'flex'
              },
              '@media screen and (min-width: 416px)': {
                display: 'none'
              },
              '@media screen and (max-width: 340px)': {
                display: 'none'
              },
            }}
          />
          <FormControlLabel
            control={<Switch defaultChecked={Boolean(!profile.banned_status)} value={active} onChange={() => setActive(!active)} />}
            label={active ? 'Активен' : 'Неактивен'}
            labelPlacement="start"
            sx={{
              '@media screen and (max-width: 416px)': {
                display: 'none'
              }
            }}
          />
        </FormGroup>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Аккаунт">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{profile.login[0]}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 40,
                height: 40,
                ml: -0.5,
                mr: 1,
              },
              '& .MuiMenuItem-root': {
                padding: '8px 20px 8px 16px'
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{ pointerEvents: 'none' }}>
          <ListItemAvatar>
            <Avatar />
          </ListItemAvatar>
          <Box>
            <div className={styles.name}>{profile.login}</div>
            <div>{profile.email}</div>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Grid container spacing={2} width={"100%"} gap={"20px"} flexWrap={'nowrap'}>
            <Grid size={2} display={"flex"} alignItems={"center"} justifyContent={"center"}><DarkModeIcon fontSize='small' /></Grid>
            <Grid size={8}><Typography>Темная тема</Typography></Grid>
            <Grid size={2} display={"flex"} alignItems={"center"} justifyContent={"flex-start"} marginLeft={"-20px"}>
              <Switch size='small' checked={mode === 'dark'} onChange={toggleTheme} />
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={() => navigate(`/${role}/settings`)} sx={{ width: '100%' }}>
          <Grid container spacing={2} width={"100%"} gap={"20px"} flexWrap={'nowrap'}>
            <Grid size={2} display={"flex"} alignItems={"center"} justifyContent={"center"}><Settings fontSize="small" /></Grid>
            <Grid size={10}><Typography>Настройки</Typography></Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Grid container spacing={2} width={"100%"} gap={"20px"} flexWrap={'nowrap'}>
            <Grid size={2} display={"flex"} alignItems={"center"} justifyContent={"center"}><Logout fontSize="small" /></Grid>
            <Grid size={10}><Typography>Выйти</Typography></Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default Account