import { configureStore } from '@reduxjs/toolkit'
import appReducer from './slices/app.slice'

const store = configureStore({
  reducer: {
    app: appReducer
  },
})

// Выведение типов `RootState` и `AppDispatch` из хранилища
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
