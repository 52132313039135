import { Box, Divider, Paper, Stack, Tooltip, Typography, Zoom } from '@mui/material'
import React from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomSnackBar from '../../../UI/customSnackBar/CustomSnackBar';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Ref = () => {
  const [openS, setOpenS] = React.useState(false);

  const copy = (str: string) => {
    navigator.clipboard.writeText(str).then(function () {
      setOpenS(true)
    }, function (err) {
      console.error('Произошла ошибка при копировании текста: ', err);
    });
  }

  return (
    <Box>
      <Typography fontSize={"0.9rem"}>Реферальная система предлагает получение кешбэка за каждого приглашенного пользователя</Typography>
      <Paper sx={{ padding: '20px', margin: '20px 0', maxWidth: '550px' }}>
        <Typography variant='subtitle2' fontWeight={700} fontSize={"0.8rem"}>Реферальный токен:</Typography>
        <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
          <Tooltip
            onClick={() => copy('0a782338-8fe9-49f9-a279-157d9b7f9f9d')}
            title={'Скопировать'}
            sx={{ cursor: 'pointer', marginTop: '10px' }}
            slots={{
              transition: Zoom,
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Typography variant='subtitle1' fontSize={'0.8rem'} noWrap>
                {'0a782338-8fe9-49f9-a279-157d9b7f9f9d'}
              </Typography>
              <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
            </Stack>
          </Tooltip>
        </Typography>
      </Paper>
      <Typography variant='h4'>Мои рефералы</Typography>
      <Divider sx={{ margin: '30px 0' }} />
      <Typography variant='h6' margin={"10px 0"}>Трейдеры</Typography>
      <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
        <ErrorOutlineIcon />
        Данные не найдены
      </Paper>
      <Typography variant='h6' margin={"10px 0"}>Магазины</Typography>
      <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
        <ErrorOutlineIcon />
        Данные не найдены
      </Paper>
      <CustomSnackBar open={openS} message={'Скопировано!'} setOpen={setOpenS} />
    </Box>
  )
}

export default Ref