import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProfile } from '../../types/types'

interface InitialStateType {
  isAuth: boolean,
  mode: 'dark' | 'light',
  profile: IProfile | null,
  role: 'trader' | 'admin' | 'merchant'
}

const initialState: InitialStateType = {
  isAuth: false,
  mode: 'dark',
  profile: null,
  role: 'trader',
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    successLogin: (state) => {
      state.isAuth = true
    },
    unsuccessfulLogin: (state) => {
      state.isAuth = false
    },
    addProfileData: (state, { payload: profile }: PayloadAction<IProfile>) => {
      state.profile = profile
    },
    addRole: (state, { payload: role }: PayloadAction<'trader' | 'admin' | 'merchant'>) => {
      state.role = role
    }
  },
})

export const { successLogin, unsuccessfulLogin, addProfileData, addRole } = appSlice.actions

export default appSlice.reducer