import { ApiClient } from "./Client"


export const GetBalanceMethod = async (userId: number) => {
  return await ApiClient({
    method: 'POST',
    url: '/actives/get-balance',
    data: {
      user_id: userId
    }
  })
}

export const GetBalancePercentMethod = async (userId: number, pay_id: 1 | 2) => {
  return await ApiClient({
    method: 'POST',
    url: `/actives/get-balance-percent`,
    data: {
      user_id: userId,
      pay_pay_id: pay_id
    }
  })
}

export const GetDepositMethod = async (userId: number) => {
  return await ApiClient({
    method: 'POST',
    url: '/actives/get-deposit',
    data: {
      user_id: userId
    }
  })
}

// CREATE BALANCE

export const CreateBalanceMethod = async (
  user_id: number,
  value: number,
  chart_id: number,
  baldep_status_id: number,
  baldep_types_id: number,
  frozen: number,
) => {
  return await ApiClient({
    method: "POST",
    url: "/actives/create-balance",
    data: {
      user_id: user_id,
      value: value,
      chart_id: chart_id,
      baldep_status_id: baldep_status_id,
      baldep_types_id: baldep_types_id,
      frozen: frozen
    }
  })
}

export const CreateDepositMethod = async (user_id: number, value: number, baldep_status_id: number, baldep_type_id: number, desc: string) => {
  return await ApiClient({
    method: "POST",
    url: "/actives/create-deposit",
    data: {
      value: value,
      user_id: user_id,
      baldep_status_id: baldep_status_id,
      baldep_types_id: baldep_type_id,
      description: desc,
      frozen: 0
    }
  })
}

export const CreatePercentMethod = async (user_id: number, pay_id: number, value: number, pay_status_id: number) => {
  return await ApiClient({
    method: "POST",
    url: "/actives/create-balance-percent",
    data: {
      user_id: user_id,
      pay_id: pay_id,
      value: value,
      pay_status_id: pay_status_id
    }
  })
}

export const ChangeBalDepStatus = async (user_id: number) => {
  return await ApiClient({
    method: 'POST',
    url: `/actives/change-baldep-status`,
    data: {
      user_id: user_id
    }
  })
}

export const ChangeBalDepType = async (user_id: number) => {
  return await ApiClient({
    method: 'POST',
    url: `/actives/change-baldep-type`,
    data: {
      user_id: user_id
    }
  })
}

export const GetBalDepStatusMethod = async (user_id: number) => {
  return await ApiClient({
    url: `/actives/get-baldep-status/${user_id}`
  })
}

export const GetBalDepTypeMethod = async (user_id: number) => {
  return await ApiClient({
    url: `/actives/get-baldep-type/${user_id}`
  })
}

// CREATE BALANCE

// SET BALANCE

export const SetBalanceMethod = async (
  user_id: number,
  value: number,
  frozen: number
) => {
  return await ApiClient({
    method: "POST",
    url: "/actives/set-balance",
    data: {
      user_id: user_id,
      value: value,
      frozen: frozen
    }
  })
}
export const SetDepositMethod = async (
  user_id: number,
  value: number,
) => {
  return await ApiClient({
    method: "POST",
    url: "/actives/set-deposit",
    data: {
      user_id: user_id,
      value: value,
    }
  })
}

export const GhangeDepositFrozenMethod = async (
  user_id: number,
  frozen: number,
) => {
  return await ApiClient({
    method: "POST",
    url: "/actives/change-deposit-frozen",
    data: {
      user_id: user_id,
      frozen: frozen
    }
  })
}

export const SetBalancePercentMethod = async (
  user_id: number,
  value: number,
  pay_id: 1 | 2
) => {
  return await ApiClient({
    method: "POST",
    url: "/actives/set-balance-percent",
    data: {
      user_id: user_id,
      value: value,
      pay_id: pay_id
    }
  })
}

// SET BALANCE

//get-pay-type

export const GetPayTypeMethod = async (userId: number) => {
  return await ApiClient({
    method: "GET",
    url: `/actives/get-pay-type/${userId}`,
  })
}