import { ApiClient } from "../Client"


export const GetMerchantsMethod = async () => {
  return await ApiClient({
    method: "POST",
    url: "/admin/get-all-users",
    data: {
      app_id: 3,
      role_id: 2
    }
  })
}

export const GetMerchantsApiKey = async (user_id: number) => {
  return await ApiClient({
    url: `/user/get-user-apikey/${user_id}`
  })
}