import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Link, Stack, Switch, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import React, { useState } from 'react'
import CustomInputPassword from '../../UI/customInput/CustomInputPassword'
import styles from './Settings.module.scss'
import Modal from '../../UI/modal/Modal'
import { useAppSelector } from '../../redux/hooks/hooks'

const Settings = () => {

  const [name, setName] = useState('Name')
  const [email, setEmail] = useState('email@email.com')
  const [telegram, setTelegram] = useState('@telegram')
  const [showPasswordChange, setShowPasswordChange] = useState(false)

  const saveSettings = async () => {

  }

  const [active2fa, setActive2fa] = useState(false)

  const handleChange2fa = () => {
    setActive2fa(!active2fa)
    if (!active2fa) {

    } else {

    }
  }

  const [openModalConfirm, setOpenModalConfirm] = useState(false)

  const modalConfirm = <Box gap={"5px"}>
    <Typography variant='h6' gutterBottom>Подтверждение</Typography>
    <Typography variant='caption' sx={{ marginTop: '30px' }}>Вы уверены, что хотите выполнить действие?</Typography>
    <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
      <Button onClick={() => setOpenModalConfirm(false)}>Отмена</Button>
      <Button variant='contained' color='error' sx={{ fontWeight: 700 }}>ОК</Button>
    </Stack>
  </Box>

  const handleResetCode = () => {
    setOpenModalConfirm(true)
  }

  const profile = useAppSelector((state) => state.app.profile)

  return (
    <React.Fragment>
      {
        profile ?
          <Box>
            < Grid container spacing={4} >
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant='h5' gutterBottom>Личная информация</Typography>
                <Box maxWidth={"20rem"}>
                  <form action="#" onSubmit={(e) => { e.preventDefault(); saveSettings() }} className={styles.form}>
                    <TextField label="Имя" variant="standard" fullWidth margin='dense' required defaultValue={profile.login} />
                    <TextField label="Email" autoComplete='email' variant="standard" fullWidth margin='dense' required defaultValue={profile.email} />
                    <TextField label="Телеграм" variant="standard" fullWidth margin='dense' defaultValue={profile.telegram} />
                    <FormControlLabel
                      control={<Checkbox onChange={() => setShowPasswordChange(!showPasswordChange)} />}
                      label="Изменить пароль"
                      sx={{ marginTop: '20px' }}
                    />
                    {
                      showPasswordChange ?
                        <Box margin={'10px 0'} display={'flex'} flexDirection={'column'} gap={'10px'}>
                          <CustomInputPassword variant='standard' label='Текущий пароль' />
                          <CustomInputPassword variant='standard' label='Новый пароль' />
                          <CustomInputPassword variant='standard' label='Подтверждение нового пароля' />
                        </Box>
                        :
                        <></>
                    }
                    <Button variant='outlined' type='submit'>Сохранить</Button>
                  </form>
                </Box>
                <Divider sx={{ margin: '40px 0' }} />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box>
                  <Typography variant='h5' gutterBottom>Двойная аутентификация</Typography>
                  <Box>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={<Switch defaultChecked={Boolean(profile.twofa_status)} value={active2fa} onChange={handleChange2fa} inputProps={{ 'aria-label': 'controlled' }} />}
                        label={profile.twofa_status ? 'Подключено' : 'Выключено'}
                        labelPlacement="end"
                      />
                    </FormGroup>
                    <Typography
                      variant='subtitle1'
                      fontWeight={700}
                      gutterBottom
                      margin={"30px 0 10px 0"}
                    >
                      Скачать приложение Google Authenticator
                    </Typography>
                    <Stack flexDirection={"row"} gap={"20px"}>
                      <Link href="https://apps.apple.com/us/app/google-authenticator/id388497605" target='_blank'>App Store</Link>
                      <Link href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target='_blank'>Play Market</Link>
                    </Stack>
                    <Typography
                      variant='subtitle1'
                      fontWeight={700}
                      gutterBottom
                      margin={"30px 0 10px 0"}
                    >
                      Секретный код
                    </Typography>
                    <Typography variant='caption'>CODE</Typography>
                    <Typography
                      variant='subtitle1'
                      fontWeight={700}
                      gutterBottom
                      margin={"30px 0 10px 0"}
                    >
                      QR Код
                    </Typography>
                    <Box margin={"10px 0"}>
                      <img src="" alt="qr" />
                    </Box>
                    <Button variant='outlined' color='error' onClick={handleResetCode}>Сбросить секретный код</Button>
                  </Box>
                </Box>
              </Grid>
            </Grid >
            <Modal modalContent={modalConfirm} open={openModalConfirm} setOpen={setOpenModalConfirm} />
          </Box>
          :
          <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
            <CircularProgress size="20px" />
          </Stack>
      }
    </React.Fragment>
  )
}

export default Settings