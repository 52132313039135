import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

interface CustomModalPropsType {
  modalContent: JSX.Element;
  open: boolean;
  setOpen: (param: boolean) => void;
  padding?: string,
  width?: string | number,
}

const CustomModal = ({
  modalContent,
  open,
  setOpen,
  padding,
  width
}: CustomModalPropsType) => {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width ? width : 400,
    bgcolor: 'background.paper',
    border: '2px solid #1caf86',
    boxShadow: 24,
    padding: padding ? padding : 4,
    borderRadius: '8px',
    '@media screen and (max-width: 520px)': {
      width: '95%',
      margin: '120px 0',
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        backdropFilter: 'blur(5px)',
        overflow: "auto"
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {modalContent}
        </Box>
      </Fade>
    </Modal>
  );
}

export default CustomModal;
