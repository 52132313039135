import { ApiClient } from "../Client"


export const GetReqsAMethod = async () => {
  return await ApiClient({
    method: "POST",
    url: "/mains/get-reqs",
    data: {
      id: 0
    }
  })
}