import { IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { IUser } from "../../../types/types";
import { NavLink } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';

interface MerchantsSamplePropsType {
  rowMerchants: IUser[]
  setOpenS: (param: boolean) => void
  // handleOpenModalAuto: () => void
  // handleOpenModalDelete: () => void
}

export const MerchantsSample = ({
  rowMerchants,
  setOpenS,
  // handleOpenModalAuto, 
  // handleOpenModalDelete 
}: MerchantsSamplePropsType) => {
  return rowMerchants.map((merchant, index) => (
    [
      <Typography variant="subtitle1" fontWeight={300}>{merchant.id}</Typography>,
      <Typography variant='subtitle1' fontWeight={300}>{merchant.login}</Typography>,
      <Typography variant='subtitle1' fontWeight={300}>{merchant.reg_date}</Typography>,
      <Typography variant='subtitle1' fontWeight={300}>{merchant.banned}</Typography>,
      <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
        <NavLink to={`/admin/merchants/${merchant.id}/edit`} state={merchant}>
          <Tooltip title="Редактировать">
            <IconButton color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>
        </NavLink>
      </Stack>
    ]
  ))
}

