import { BottomNavigation, Box, Button, Divider, Paper } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
    <>
      {/* <Paper sx={{ position: 'fixed', bottom: 0, left: 0 }}> */}
      <Divider />
      <Box padding={'16px'} display={'flex'} justifyContent={"space-between"}>
        <Button size="large">Telegram</Button>
        <Button size="large">Processing</Button>
      </Box>
      {/* </Paper> */}
      {/* <BottomNavigation
        showLabels
      // value={value}
      // onChange={(event, newValue) => {
      //   setValue(newValue);
      // }}
      >
        <Button size="large">Telegram</Button>
        <Button size="large">Processing</Button>
      </BottomNavigation> */}
    </>
  )
}

export default Footer