import React, { useState } from 'react'
import styles from './Login.module.scss'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import OtpInput from './code/Code';
import { LogMethod, RegMethod } from '../../services/Auth';
import { LoadingElement } from '../../UI/loading/Loading';
import CustomInput from '../../UI/customInput/CustomInput';
import CustomInputPassword from '../../UI/customInput/CustomInputPassword';
import CustomSnackBar from '../../UI/customSnackBar/CustomSnackBar';
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import Modal from '../../UI/modal/Modal';
import regImg from '../../assets/img/regImg.png'

interface LoginPropsType {
  isAuth: boolean;
  role: "trader" | "admin" | "merchant"
}

const Login = ({ isAuth, role }: LoginPropsType) => {

  if (isAuth) document.location.href = role === 'admin' ? '/admin' : role === 'merchant' ? '/merchant' : '/trader';

  const [openSnack, setOpenSnack] = useState(false);
  const [showCodeVerify, setShowCodeVerify] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const [otp, setOtp] = useState('')
  const onChange = (value: string) => setOtp(value)

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    const email = ((e.target as HTMLFormElement)[0] as HTMLInputElement).value;
    const password = ((e.target as HTMLFormElement)[2] as HTMLInputElement).value;
    setEmail(email)
    setPassword(password)

    const data = await LogMethod({ email: email, password: password })
    if (data.status === 200) {

      // DEL DEL
      localStorage.setItem('access_token', data.data.access_token)
      localStorage.setItem('user_id', data.data.data.id)
      // document.location.href = '/';
      // DEL DEL

      setShowCodeVerify(true)
      setLoading(false)
    } else {
      setErrorMessage('Что-то пошло не так..')
      setOpenSnack(true)
      setTimeout(() => {
        setErrorMessage('')
        setOpenSnack(false)
      }, 3000)
      setLoading(false)
    }
  }

  const reLogin = async () => {
    const data = await LogMethod({ email: email, password: password })
    if (data.status === 200) {
      setShowCodeVerify(true)
      setLoading(false)
    } else {
      setErrorMessage('Что-то пошло не так..')
      setOpenSnack(true)
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
      setLoading(false)
    }
  }

  const [showReg, setShowReg] = useState(false);

  // const [name, setName] = useState('')
  // const [telegram, setTelegram] = useState('')

  const sendRegForm = async (e: React.FormEvent<HTMLFormElement>) => {
    const name = ((e.target as HTMLFormElement)[0] as HTMLInputElement).value;
    const telegram = ((e.target as HTMLFormElement)[2] as HTMLInputElement).value;
    const email = ((e.target as HTMLFormElement)[4] as HTMLInputElement).value;
    const password = ((e.target as HTMLFormElement)[6] as HTMLInputElement).value;
    const ref = ((e.target as HTMLFormElement)[9] as HTMLInputElement).value;

    // setName(name)
    // setTelegram(telegram)
    // setEmail(email)
    // setPassword(password)

    const data = await RegMethod({ email: email, password: password, login: name, telegram: telegram, affiliate_invitation_id: ref })
    if (data.status === 200) {
      setLoading(false)
      setSuccessMessage('Заявка успешно отправлена! В ближайшее время с вами свяжутся наши специалисты')
      setOpenSnack(true)
      setTimeout(() => {
        setSuccessMessage('')
      }, 3000)
      setLoading(false)
      setShowReg(false)
    } else {
      setErrorMessage('Что-то пошло не так..')
      setOpenSnack(true)
      setTimeout(() => {
        setErrorMessage('')
      }, 3200)
      setLoading(false)
    }
  }

  const modalRegContent = <Card>
    <CardMedia
      component="img"
      alt="reg image"
      // height="180"
      image={regImg}
    />
    <CardContent>
      <Typography gutterBottom variant="h5" textAlign={"center"}>
        Оставьте заявку и получите подробную консультацию по вашим вопросам
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }} textAlign={"center"} marginBottom={"10px"}>
        Свяжемся с вами в течение часа
      </Typography>
      <form action="#" onSubmit={(e) => { e.preventDefault(); sendRegForm(e) }}>
        <CustomInput label='Имя' required />
        <CustomInput label='Телеграм' required />
        <CustomInput label='Email' required type='email' autoComplete='email' />
        <CustomInputPassword label='Пароль' autoComplete='password' />
        <CustomInput label='Реферальный код' />
        <Button size="small" fullWidth variant='contained' type='submit' sx={{ fontWeight: 700, color: '#fff' }}>Оставить заявку</Button>
      </form>
    </CardContent>
  </Card>

  return (
    <Box className={styles.login}>
      {!isAuth ?
        <Container maxWidth="xs" disableGutters >
          {
            showCodeVerify ?
              <Box className={styles.box}>
                <LockIcon className={styles.lockIcon} sx={{ fontSize: 50 }} />
                <p className={styles.title}>Код из письма</p>
                <div className={styles.wrap}>
                  <OtpInput value={otp} valueLength={6} onChange={onChange} email={email} password={password} />
                  {/* {errorMessage ? error : <></>} */}
                </div>
                <div
                  className={styles.resent}
                  onClick={() => {
                    // setShowCodeVerify(false)
                    reLogin()
                  }}
                >
                  Письмо не пришло. Повторить отправку
                </div>
              </Box>
              :
              <Box className={styles.box}>
                <LockIcon className={styles.lockIcon} sx={{ fontSize: 40 }} />
                <p className={styles.title}>Вход</p>
                <form action="#" className={styles.form} onSubmit={(e) => { e.preventDefault(); login(e) }}>
                  <Box className={styles.box}>
                    <CustomInput
                      label='Email'
                      required
                      autoComplete='email'
                      type='email'
                      errorMessage={errorMessage === 'Что-то пошло не так..' ? 'Проверьте введенные данные' : errorMessage}
                      disabled={loading}
                    />
                    <CustomInputPassword
                      label='Пароль'
                      autoComplete='password'
                      errorMessage={errorMessage === 'Что-то пошло не так..' ? 'Проверьте введенные данные' : errorMessage}
                      loading={loading}
                    />
                    {
                      loading ?
                        <LoadingButton color='primary' loading variant="outlined" fullWidth className={styles.loading}>
                          &nbsp;
                        </LoadingButton>
                        :
                        <Button
                          variant="contained"
                          fullWidth
                          type='submit'
                          sx={{ fontWeight: 600, color: 'white' }}
                        >
                          Войти
                        </Button>
                    }
                    {/* {errorMessage ? error : <></>} */}
                    <Typography
                      variant='caption'
                      color='primary'
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={() => setShowReg(true)}
                    >
                      Подать заявку на регистрацию
                    </Typography>
                  </Box>
                </form>
              </Box>
          }
        </Container>
        : LoadingElement}
      <CustomSnackBar open={openSnack} message={errorMessage || successMessage} setOpen={setOpenSnack} />
      <Modal modalContent={modalRegContent} open={showReg} setOpen={setShowReg} padding='0' width={500} />
    </Box>
  )
}

export default Login