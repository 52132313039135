import { ApiClient } from "./Client"


// export const GetUserMethod = async (token: string) => {
//   return await ApiClient({
//     method: 'POST',
//     url: '',
//     data: {
//       token: token
//     }
//   })
// }

export const GetStatisticsMethod = async (user_id: number) => {
  return await ApiClient({
    method: "POST",
    url: "/stats/get-all-stats",
    data: {
      user_id: user_id
    }
  })
}