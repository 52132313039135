import CreditCardIcon from "@mui/icons-material/CreditCard"
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff"
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../../../redux/hooks/hooks"
import { GetPayTypeMethod } from "../../../services/Actives"
import {
  CreateReqsMethod,
  GetBanksMethod,
  GetCurrencyMethod,
  GetPayReqsStatusMethod,
  GetPayReqsTypesMethod,
} from "../../../services/Mains"
import {
  CustomSelectContentType,
  IBanks,
  ICurrency,
  IPayType,
  IStatus,
  ITypes,
} from "../../../types/types"
import CustomInput from "../../../UI/customInput/CustomInput"
import CustomSelect from "../../../UI/customSelect/CustomSelect"
import CustomSnackBar from "../../../UI/customSnackBar/CustomSnackBar"

const RequisitesPayoutNew = () => {
  const profile = useAppSelector((state) => state.app.profile)

  const navigate = useNavigate()

  const [openSnack, setOpenSnack] = useState(false)

  const [currency, setCurrency] = useState<CustomSelectContentType[]>([])
  const [bank, setBank] = useState<CustomSelectContentType[]>([])
  const [type, setType] = useState<CustomSelectContentType[]>([])

  const [currencySelected, setCurrencySelected] = useState("")
  const [typeSelected, setTypeSelected] = useState("")
  const [bankSelected, setBankSelected] = useState("")

  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [minSum, setMinSum] = useState(`0`)
  const [maxSum, setMaxSum] = useState(`0`)
  const [maxLimitSum, setMaxLimitSum] = useState(`0`)
  const [maxLimit, setMaxLimit] = useState(`0`)
  const [payType, setPayType] = useState(2)
  const [listStatus, setListStatus] = useState<IStatus[]>([])
  const [selectedStatus, setSelectedStatus] = useState<string>("Активен")
  const [selectedOrder, setSelectedOrder] = useState<string>("1")

  const sendCreateRequisites = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!profile) return
    const body = {
      user_id: profile.id,
      pay_pay_id: payType,
      currency_id: +currencySelected,
      reqs_types_id: +typeSelected,
      reqs_status_id: listStatus.find(
        (status) => status.title === selectedStatus
      )?.id,
      bank_id: +bankSelected,
      chart_id: profile.chart_id,
      phone: "-",
      min_sum_per_transaction: +minSum,
      max_sum_per_transaction: +maxSum,
      max_limit_transaction_sum: +maxLimitSum,
      max_limit_transaction: +maxLimit,
      other_banks: +selectedOrder,
    }

    const dataCreateReqs = await CreateReqsMethod(body)

    if (dataCreateReqs.status === 200 && dataCreateReqs.data.Success) {
      setSuccessMessage("Реквизит успешно создан!")
      setOpenSnack(true)
      setTimeout(() => {
        setSuccessMessage("")
        navigate("/trader/requisites")
      }, 3000)
    } else {
      setErrorMessage("Что-то пошло не так..")
      setOpenSnack(true)
      setTimeout(() => {
        setErrorMessage("")
      }, 3200)
    }
  }

  const getInfo = async () => {
    const dataCurrency = await GetCurrencyMethod(0)
    if (dataCurrency.status === 200 && dataCurrency.data.Success) {
      const newCurrency = dataCurrency.data.data.map((currency: ICurrency) => ({
        contentLabel: currency.symbol,
        contentValue: currency.id,
      }))

      setCurrency((prevCurrency) => {
        const uniqueCurrency = new Map(
          [...prevCurrency, ...newCurrency].map((item) => [
            item.contentValue,
            item,
          ])
        )
        return Array.from(uniqueCurrency.values())
      })
    }

    const dataPayType = await GetPayTypeMethod(0)
    if (dataPayType.status === 200 && dataPayType.data.Success) {
      const payTypeId = dataPayType.data.data.find(
        (el: IPayType) => el.title === "Payout"
      ).id

      setPayType(payTypeId)
    }

    const dataReqsStatus = await GetPayReqsStatusMethod(0)
    if (dataReqsStatus.status === 200 && dataReqsStatus.data.Success) {
      setListStatus(dataReqsStatus.data.data)
    }

    const dataBank = await GetBanksMethod(0)
    if (dataBank.status === 200 && dataBank.data.Success) {
      const newBank = dataBank.data.data.map((bank: IBanks) => ({
        contentLabel: bank.title,
        contentValue: bank.id,
      }))

      setBank((prevBank) => {
        const uniqueBank = new Map(
          [...prevBank, ...newBank].map((item) => [item.contentValue, item])
        )
        return Array.from(uniqueBank.values())
      })
    }

    const dataTypes = await GetPayReqsTypesMethod(0)
    if (dataTypes.status === 200 && dataTypes.data.Success) {
      const newTypes = dataTypes.data.data.map((type: ITypes) => ({
        contentLabel: type.title,
        contentValue: type.id,
      }))

      setType((prevType) => {
        const uniqueType = new Map(
          [...prevType, ...newTypes].map((item) => [item.contentValue, item])
        )
        return Array.from(uniqueType.values())
      })
    }

    setLoading(false)
  }

  useEffect(() => {
    getInfo()
  }, [])

  useEffect(() => {
    if (!profile) return
    setCurrencySelected(`${profile.currency_id}`)
  }, [profile])

  return (
    <Box>
      <Typography variant="h4">Добавить новые реквизиты</Typography>
      <Divider sx={{ margin: "20px 0" }} />
      {!loading ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          maxWidth={"30rem"}>
          <form
            action="#"
            onSubmit={(e) => {
              e.preventDefault()
              sendCreateRequisites(e)
            }}>
            <FormControl sx={{ marginBottom: "10px" }}>
              <FormLabel id="radio-buttons-group-label">Статус</FormLabel>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                row>
                <FormControlLabel
                  value="Активен"
                  control={<Radio />}
                  label={
                    <Chip
                      variant="outlined"
                      color="primary"
                      icon={<CreditCardIcon />}
                      label="Активны"
                    />
                  }
                />
                <FormControlLabel
                  value="Не активен"
                  control={<Radio />}
                  label={
                    <Chip
                      variant="outlined"
                      icon={<CreditCardOffIcon />}
                      label="Неактивны"
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
            <CustomSelect
              label="Валюта"
              required
              content={currency}
              value={currencySelected}
              setValue={setCurrencySelected}
              size="small"
              autosuggest
            />
            <CustomSelect
              label="Способ пополнения"
              required
              content={type}
              value={typeSelected}
              setValue={setTypeSelected}
              size="small"
            />
            <CustomSelect
              label="Банк"
              required
              content={bank}
              value={bankSelected}
              setValue={setBankSelected}
              size="small"
              autosuggest
            />
            <FormControl sx={{ marginBottom: "10px" }}>
              <FormLabel id="radio-buttons-group-label">
                Разрешать ордера других банков
              </FormLabel>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                value={selectedOrder}
                onChange={(e) => setSelectedOrder(e.target.value)}
                row>
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={"Да"}
                />
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label={"Нет"}
                />
              </RadioGroup>
            </FormControl>
            <CustomInput
              label="Минимальная сумма одной транзакции"
              value={`${minSum}`}
              shrink
              notched
              numeric
              required
              setValue={setMinSum}
            />
            <CustomInput
              label="Максимальная сумма одной транзакции"
              value={`${maxSum}`}
              shrink
              notched
              numeric
              required
              setValue={setMaxSum}
            />
            <CustomInput
              label="Общий лимит суммы переводов"
              value={`${maxLimitSum}`}
              shrink
              notched
              numeric
              required
              setValue={setMaxLimitSum}
            />
            <CustomInput
              label="Лимит на общее количество операций"
              value={`${maxLimit}`}
              shrink
              notched
              numeric
              required
              setValue={setMaxLimit}
            />
            <Stack flexDirection={"row"} gap={"10px"} marginTop={"20px"}>
              <Button
                variant="outlined"
                onClick={() => navigate("/trader/requisites")}>
                Отмена
              </Button>
              <Button variant="contained" type="submit">
                Сохранить
              </Button>
            </Stack>
          </form>
        </Box>
      ) : (
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          minHeight={"100px"}>
          <CircularProgress size="40px" />
        </Stack>
      )}
      <CustomSnackBar
        open={openSnack}
        message={errorMessage || successMessage}
        setOpen={setOpenSnack}
      />
    </Box>
  )
}

export default RequisitesPayoutNew
