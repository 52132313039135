import { IconButton, Stack, Typography } from "@mui/material"
import { IFavBank } from "../../../types/types"
import DeleteIcon from '@mui/icons-material/Delete';

interface FavBanksSamplePropsType {
  rowFavBanks: IFavBank[]
  setOpenSnack: (param: boolean) => void
  handleOpenModalDelete: (title: string) => void
}

export const FavBanksSample = ({ rowFavBanks, setOpenSnack, handleOpenModalDelete }: FavBanksSamplePropsType) => {
  return rowFavBanks.map((banks, index) => (
    [
      <Typography variant='subtitle2' fontSize={'0.7rem'} noWrap>{banks.title}</Typography>,
      <Typography variant='subtitle2' fontSize={'0.7rem'} noWrap>{banks.bik}</Typography>,
      <Stack flexDirection={"column"} alignItems={'flex-start'} gap={"5px"}>
        <IconButton color='error' onClick={() => handleOpenModalDelete(banks.title)}><DeleteIcon /></IconButton>
      </Stack>
    ]
  ))
}

