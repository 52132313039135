import { ApiClient } from "./Client"

export const GetReqsMethod = async (userId: number, payPayId: number) => {
  return await ApiClient({
    method: "POST",
    url: "/mains/get-reqs",
    data: {
      user_id: userId,
      pay_pay_id: payPayId,
    },
  })
}

export const GetBanksMethod = async (userId: number) => {
  return await ApiClient({
    method: "GET",
    url: `/mains/get-fav-banks/${userId}`,
  })
}

export const SetFavBankMethod = async (userId: number, title: string, bik: string) => {
  return await ApiClient({
    method: "POST",
    url: `/mains/set-favorite-banks`,
    data: {
      user_id: userId,
      title: title,
      bik: bik
    }
  })
}

export const DelFavBankMethod = async (userId: number, title: string) => {
  return await ApiClient({
    method: "POST",
    url: "/mains/remove-favorite-banks",
    data: {
      user_id: userId,
      title: title
    }
  })
}

export const GetChartMethod = async (userId: number) => {
  return await ApiClient({
    method: "GET",
    url: `/mains/get-chart/${userId}`,
  })
}

export const GetPayReqsTypesMethod = async (userId: number) => {
  return await ApiClient({
    method: "GET",
    url: `/mains/get-pay-reqs-types/${userId}`,
  })
}

export const GetPayReqsStatusMethod = async (userId: number) => {
  return await ApiClient({
    method: "GET",
    url: `/mains/get-pay-reqs-status/${userId}`,
  })
}

export const GetPayReqsGroupsMethod = async (userId: number) => {
  return await ApiClient({
    method: "GET",
    url: `/mains/get-reqs-groups/${userId}`,
  })
}

export const GetCurrencyMethod = async (userId: number) => {
  return await ApiClient({
    method: "GET",
    url: `/mains/get-currency/${userId}`,
  })
}

export const SetReqsMethod = async (body: any) => {
  return await ApiClient({
    method: "POST",
    url: "/mains/set-reqs",
    data: body,
  })
}

export const CreateReqsMethod = async (body: any) => {
  return await ApiClient({
    method: "POST",
    url: "/mains/create-reqs",
    data: body,
  })
}

export const RemoveReqsMethod = async (body: any) => {
  return await ApiClient({
    method: "POST",
    url: "/mains/remove-reqs",
    data: body,
  })
}

export const GetReqsGroupsMethod = async (userId: number) => {
  return await ApiClient({
    method: "GET",
    url: `/mains/get-reqs-groups/${userId}`,
  })
}

export const CreateReqsGroupsMethod = async (body: any) => {
  return await ApiClient({
    method: "POST",
    url: "/mains/create-reqs-groups",
    data: body,
  })
}

export const AddReqsToGroupMethod = async (body: any) => {
  return await ApiClient({
    method: "POST",
    url: "/mains/add-reqs-to-group",
    data: body,
  })
}

export const SetReqsGroupsMethod = async (body: any) => {
  return await ApiClient({
    method: "POST",
    url: "/mains/set-reqs-groups",
    data: body,
  })
}

export const RemoveReqsFromGroupMethod = async (body: any) => {
  return await ApiClient({
    method: "POST",
    url: "/mains/remove-reqs-from-group",
    data: body,
  })
}

export const RemoveGroupByIdMethod = async (body: any) => {
  return await ApiClient({
    method: "POST",
    url: "/mains/remove-group-by-id",
    data: body,
  })
}