import { UserCode, UserLog, UserReg } from "../types/types"
import { ApiClient } from "./Client"

export const LogMethod = async (data: UserLog) => {
  return await ApiClient({
    method: "POST",
    url: "/user/login",
    data: data
  })
}

export const CodeMethod = async (data: UserCode) => {
  return await ApiClient({
    method: "POST",
    url: "/user/code",
    data: data
  })
}

export const CheckAuthMethod = async (token: string) => {
  return await ApiClient({
    // method: 'POST',
    url: `/user/jwt-token/${token}`,
    // params: {
    //   token: token
    // }
  })
}

export const LogOutMethod = async () => {
  const token = localStorage.getItem("access_token") ?? '';
  return await ApiClient({
    method: 'POST',
    url: "/user/logout",
    data: {
      token: token
    }
  })
}

export const RegMethod = async (data: UserReg) => {
  return await ApiClient({
    method: "POST",
    url: "/user/register-request",
    data: {
      login: data.login,
      email: data.email,
      password: data.password,
      telegram: data.telegram,
      ...(data.affiliate_invitation_id && { affiliate_invitation_id: data.affiliate_invitation_id, })
    }
  })
}

export const GetProfileMethod = async (userId: string) => {
  return await ApiClient({
    url: `/user/profile/${userId}`,
  })
}