import { Snackbar, SnackbarCloseReason } from '@mui/material'
import React from 'react'

interface CustomSnackBarPropsType {
  open: boolean,
  message: string,
  setOpen: (param: boolean) => void
}

const CustomSnackBar = ({
  open,
  message,
  setOpen
}: CustomSnackBarPropsType) => {

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      message={message}
    />
  )
}

export default CustomSnackBar