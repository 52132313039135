import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { IUser } from "../../../types/types"
import { AllowReqUserMethod } from "../../../services/admin/Users"
import WalletIcon from '@mui/icons-material/Wallet';
import EditIcon from '@mui/icons-material/Edit';
import { NavLink } from "react-router-dom";

interface UsersSamplePropsType {
  rowUsers: IUser[]
  setOpenS: (param: boolean) => void
  getUsers: () => void
}

export const UsersAllSample = ({ rowUsers, setOpenS, getUsers }: UsersSamplePropsType) => {


  const allowUser = async (userId: number) => {
    await AllowReqUserMethod(userId)
    getUsers()
  }

  return rowUsers.map((user, index) => (
    user.banned_status === 1 ?
      [
        <Typography variant="subtitle2">{user.id}</Typography>,
        <Typography variant="subtitle2">{user.login}</Typography>,
        <Typography variant="subtitle2">{user.email}</Typography>,
        <Typography variant="subtitle2">{user.telegram}</Typography>,
        <Typography variant="subtitle2">{user.reg_date}</Typography>,
        <Button onClick={() => allowUser(user.id)}>Зарегистрировать</Button>
      ]
      :
      [
        <Typography variant="subtitle2">{user.id}</Typography>,
        <Typography variant="subtitle2">{user.login}</Typography>,
        <Typography variant="subtitle2">{user.email}</Typography>,
        <Typography variant="subtitle2">{user.telegram}</Typography>,
        <Typography variant="subtitle2">{user.reg_date}</Typography>,
        <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
          <NavLink to={`/admin/users/${user.id}/wallet`} state={user}>
            <Tooltip title="Баланс">
              <IconButton color="warning">
                <WalletIcon />
              </IconButton>
            </Tooltip>
          </NavLink>
          <NavLink to={`/admin/users/${user.id}/edit`} state={user}>
            <Tooltip title="Редактировать">
              <IconButton color="primary">
                <EditIcon />
              </IconButton>
            </Tooltip>
          </NavLink>
        </Stack>
      ]
  ))
}

