import React, { createContext, useContext, useState, ReactNode } from 'react';

// Типы для состояния темы
type ThemeContextType = {
  mode: 'light' | 'dark';
  toggleTheme: () => void;
};

// Создаем контекст с начальным значением
const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  // Состояние для хранения текущей темы
  const [mode, setMode] = useState<'light' | 'dark'>('dark'); // По умолчанию темная тема

  // Функция для переключения темы
  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Хук для использования контекста в компонентах
export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};
