import React, { useCallback, useEffect, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, IconButton, Menu, Paper, Stack, Tab, Tooltip, Typography, Zoom } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CustomSelectContentType } from '../../../types/types';
import { turncate } from '../../../utils/Turncate';
import CustomSelect from '../../../UI/customSelect/CustomSelect';
import CustomTable from '../../../UI/customTable/CustomTable';
import CustomSnackBar from '../../../UI/customSnackBar/CustomSnackBar';

const Automatic = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [dateOn, setDateOn] = useState<Dayjs | null>(null)
  const [dateFor, setDateFor] = useState<Dayjs | null>(null)

  const [operationSelected, setOperationSelected] = useState('')

  const operationList: CustomSelectContentType[] = [
    { contentLabel: 'Вывод средств', contentValue: 'fundsWithdraws' },
    { contentLabel: 'Пополнение средств', contentValue: 'fundsDeposit' }
  ]

  const handleResetFilters = () => {
    setOperationSelected('')
    setDateFor(null)
    setDateOn(null)
    setAnchorEl(null);
  }

  const [activeAuto, setActiveAuto] = useState<any[]>([])
  const [successAuto, setSuccessAuto] = useState<any[]>([])
  const [errorAuto, setErrorAuto] = useState<any[]>([])
  const [errorModelAuto, setErrorModelAuto] = useState<any[]>([])
  const [techAuto, setTechAuto] = useState<any[]>([])

  const [openS, setOpenS] = React.useState(false);

  const copy = (str: string) => {
    navigator.clipboard.writeText(str).then(function () {
      setOpenS(true)
    }, function (err) {
      console.error('Произошла ошибка при копировании текста: ', err);
    });
  }

  const getSuccessAuto = useCallback(async () => {
    const rowsSuccessAuto = [
      [
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('adress to copy')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography noWrap>{turncate('adress to copy', 21)}</Typography>
                <ContentCopyIcon color='primary' fontSize='small' />
              </Stack>
            </Tooltip>
          </Typography>
          <Typography variant="caption" fontWeight={300}>2024-11-15 08:04</Typography>
        </Box>,
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('Сбер.счёт *3135 Зачисление 2012р')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography noWrap>{'Сбер.счёт *3135 Зачисление 2012р'}</Typography>
                <ContentCopyIcon color='primary' fontSize='small' />
              </Stack>
            </Tooltip>
          </Typography>
        </Box>,
        <Typography variant="subtitle1">Автоматический (группа)</Typography>
      ]
    ]

    const rowsErrorAuto = [
      [
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('adress to copy')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography noWrap>{turncate('adress to copy', 21)}</Typography>
                <ContentCopyIcon color='primary' fontSize='small' />
              </Stack>
            </Tooltip>
          </Typography>
          <Typography variant="caption" fontWeight={300}>2024-11-15 08:04</Typography>
        </Box>,
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('СЧЁТ6098 19:30 Перевод из Т‑Банк +10р от ОЗОДБЕК УГЛИ Баланс: 59р')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <Stack flexDirection={'row'} gap={'5px'}>
                <Typography noWrap>{'СЧЁТ6098 19:30 Перевод из Т‑Банк +10р от ОЗОДБЕК УГЛИ Баланс: 59р'}</Typography>
                <ContentCopyIcon color='primary' fontSize='small' />
              </Stack>
            </Tooltip>
          </Typography>
        </Box>,
        <Typography variant="subtitle1">Автоматический (группа)</Typography>,
        <Typography variant="subtitle1" color='error'>Ордер не найден</Typography>,
      ]
    ]

    setSuccessAuto(rowsSuccessAuto)
    setErrorAuto(rowsErrorAuto)

  }
    , [])

  useEffect(() => {
    getSuccessAuto()
  }, [getSuccessAuto])

  const rowsHeadSuccess = ['ID', 'Текст сообщения', 'Способ автоматизации']
  const rowsHeadError = ['ID', 'Текст сообщения', 'Способ автоматизации', 'Статус']

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs">
          <Tab label="Активные" value="1" />
          <Tab label="Успешные" value="2" />
          <Tab label="Ошибка" value="3" />
          <Tab label="Ошибка шаблона" value="4" />
          <Tab label="Технические" value="5" />
        </TabList>
      </Box>
      <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"flex-end"} sx={{ padding: '8px 16px 0 16px' }}>
        <IconButton onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
            <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DatePicker
            label="Дата от"
            value={dateOn}
            onChange={(newValue) => setDateOn(newValue)}
          />
          <DatePicker
            label="Дата до"
            value={dateFor}
            onChange={(newValue) => setDateFor(newValue)}
          />
          <CustomSelect
            label='Операция'
            content={operationList}
            variant='outlined'
            value={operationSelected}
            setValue={setOperationSelected}
          />
          <Stack flexDirection={"row"} gap={"10px"}>
            <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
            <Button variant='outlined' fullWidth>OK</Button>
          </Stack>
        </Menu>
      </Stack>
      <TabPanel value="1" sx={{ padding: '10px 0' }}>
        {
          activeAuto.length ?
            <CustomTable rowsDataHead={rowsHeadSuccess} rowsDataBody={activeAuto} paginationCol={4} />
            :
            <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <ErrorOutlineIcon />
              Данные не найдены
            </Paper>
        }
      </TabPanel>
      <TabPanel value="2" sx={{ padding: '10px 0' }}>
        {
          successAuto.length ?
            <CustomTable rowsDataHead={rowsHeadSuccess} rowsDataBody={successAuto} paginationCol={3} />
            :
            <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <ErrorOutlineIcon />
              Данные не найдены
            </Paper>
        }
      </TabPanel>
      <TabPanel value="3" sx={{ padding: '10px 0' }}>
        {
          errorAuto.length ?
            <CustomTable rowsDataHead={rowsHeadError} rowsDataBody={errorAuto} paginationCol={4} />
            :
            <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <ErrorOutlineIcon />
              Данные не найдены
            </Paper>
        }
      </TabPanel>
      <TabPanel value="4" sx={{ padding: '10px 0' }}>
        {
          errorModelAuto.length ?
            <CustomTable rowsDataHead={rowsHeadError} rowsDataBody={errorModelAuto} paginationCol={4} />
            :
            <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <ErrorOutlineIcon />
              Данные не найдены
            </Paper>
        }
      </TabPanel>
      <TabPanel value="5" sx={{ padding: '10px 0' }}>
        {
          techAuto.length ?
            <CustomTable rowsDataHead={rowsHeadError} rowsDataBody={techAuto} paginationCol={4} />
            :
            <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <ErrorOutlineIcon />
              Данные не найдены
            </Paper>
        }
      </TabPanel>
      <CustomSnackBar open={openS} message={'Скопировано!'} setOpen={setOpenS} />
    </TabContext>
  )
}

export default Automatic