import { TabContext, TabPanel } from '@mui/lab'
import { Box, Button, Chip, IconButton, Menu, Paper, Stack, Typography } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { useCallback, useEffect, useState } from 'react'
import CustomSelect from '../../../UI/customSelect/CustomSelect';
import CustomTable from '../../../UI/customTable/CustomTable';
import CustomSnackBar from '../../../UI/customSnackBar/CustomSnackBar';
import { CustomSelectContentType, IUser } from '../../../types/types';
import { MerchantsSample } from './MerchantsSample';
import { GetMerchantsMethod } from '../../../services/admin/Merchants';

const Merchants = () => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [operationSelected, setOperationSelected] = useState('all')

  const operationList: CustomSelectContentType[] = [
    { contentLabel: 'Все мерчанты', contentValue: 'all' },
  ]

  const handleResetFilters = () => {
    setOperationSelected('all')
    setAnchorEl(null);
    getMerchants()
  }

  const [openS, setOpenS] = useState(false);

  const [rowMerchants, setRowMerchants] = useState<IUser[]>([])
  const [merchants, setMerchants] = useState<any[]>([])

  const getMerchants = useCallback(async () => {
    const merchantsData = await GetMerchantsMethod();
    if (merchantsData.status === 200 && merchantsData.data.Success) {
      setRowMerchants(merchantsData.data.data)
    }
  }
    , [])

  // SET DISPUTS
  useEffect(() => {
    const toRender = MerchantsSample({ rowMerchants, setOpenS })
    setMerchants(toRender)
  }, [getMerchants, operationSelected, rowMerchants])

  useEffect(() => {
    getMerchants()
  }, [getMerchants])

  const rowsHead = ['ID', 'Название', 'Дата регистрации', 'Доступ', 'Действия']

  return (
    <TabContext value={'1'}>
      <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"space-between"} sx={{ padding: '8px 16px 0 16px' }}>
        <Chip label={operationSelected === 'all' ? 'Мерчанты' : '..'} />
        <IconButton onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
            <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <CustomSelect
            label='Тип'
            content={operationList}
            variant='outlined'
            value={operationSelected}
            setValue={setOperationSelected}
          />
          <Stack flexDirection={"row"} gap={"10px"}>
            <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
          </Stack>
        </Menu>
      </Stack>
      <TabPanel value="1" sx={{ padding: '10px 0' }}>
        {
          merchants.length ?
            <CustomTable rowsDataHead={rowsHead} rowsDataBody={merchants} paginationCol={rowsHead.length} />
            :
            <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <ErrorOutlineIcon />
              Данные не найдены
            </Paper>
        }
      </TabPanel>
      <CustomSnackBar open={openS} message={'Скопировано!'} setOpen={setOpenS} />
    </TabContext>
  )
}

export default Merchants