import React, { useCallback, useEffect } from 'react'
import styles from './Main.module.scss'
import { Navigation, Router } from '@toolpad/core/AppProvider';
import { AppProvider } from '@toolpad/core/react-router-dom';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { PageContainer } from '@toolpad/core/PageContainer';
import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { createAppTheme } from '../../theme';
import logo from '../../assets/img/logo.svg'
import { LoadingElement } from '../../UI/loading/Loading';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentsIcon from '@mui/icons-material/Payments';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import TimelineIcon from '@mui/icons-material/Timeline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PaymentIcon from '@mui/icons-material/Payment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import GroupIcon from '@mui/icons-material/Group';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Account from '../../components/account/Account';
import CustomHtmlChip from '../../components/customHtmlChip/CustomHtmlChip';
import Marquee from 'react-fast-marquee';
import Footer from '../../components/footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useThemeContext } from '../../ThemeContext';
import { GetProfileMethod } from '../../services/Auth';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { addProfileData } from '../../redux/slices/app.slice'
import PersonIcon from '@mui/icons-material/Person';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import StoreIcon from '@mui/icons-material/Store';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

interface MainPropsType {
  children: JSX.Element;
  isAuth: boolean;
  role: "trader" | "admin" | "merchant"
}

const Main = ({ children, isAuth, role }: MainPropsType) => {

  if ((role === 'admin') && (!document.location.pathname.startsWith('/admin'))) document.location.href = '/admin';
  if ((role === 'merchant') && (!document.location.pathname.startsWith('/merchant'))) document.location.href = '/merchant';
  if ((role === 'trader') && (!document.location.pathname.startsWith('/trader'))) document.location.href = '/trader';

  const traderNavigation: Navigation = [
    {
      kind: 'header',
      title: role === 'merchant' ? `Мерчант` : role === 'admin' ? "Админ" : 'Трейдер',
    },
    {
      kind: 'divider',
    },
    {
      kind: 'header',
      title: 'Активы',
    },
    {
      segment: `${role}`,
      title: 'Балансы',
      icon: <AttachMoneyIcon />,
    },
    {
      segment: `${role}/funds`,
      title: 'Запросы средств',
      icon: <PaymentsIcon />,
    },
    {
      segment: `${role}/transactions`,
      title: 'Внутренние переводы',
      icon: <CompareArrowsIcon />,
    },
    {
      segment: `${role}/balance-history`,
      title: 'Изменения баланса',
      icon: <TimelineIcon />,
    },
    {
      segment: `${role}/exchange`,
      title: 'Обмен валют',
      icon: <CurrencyExchangeIcon />,
    },
    {
      kind: 'divider',
    },
    {
      kind: 'header',
      title: 'Основное',
    },
    {
      segment: `${role}/requisites`,
      title: 'Реквизиты',
      icon: <PaymentIcon />,
    },
    {
      segment: `${role}/orders`,
      title: 'Ордера',
      icon: <AssignmentIcon />,
    },
    {
      segment: `${role}/automatic`,
      title: 'Автоматизация',
      icon: <AutoModeIcon />,
    },
    {
      segment: `${role}/referral-system`,
      title: 'Реферальная система',
      icon: <GroupIcon />,
    },
    {
      segment: `${role}/statistics`,
      title: 'Статистика',
      icon: <QueryStatsIcon />,
    },
  ];

  const adminNavigation: Navigation = [
    {
      kind: 'header',
      title: role === 'merchant' ? `Мерчант` : role === 'admin' ? "Админ" : 'Трейдер',
    },
    {
      kind: 'divider',
    },
    {
      kind: 'header',
      title: 'Группы',
    },
    {
      segment: `${role}`,
      title: 'Пользователи',
      icon: <PersonIcon />,
    },
    {
      segment: `${role}/merchants`,
      title: 'Мерчанты',
      icon: <StoreIcon />,
    },
    {
      kind: 'divider',
    },
    {
      kind: 'header',
      title: 'Основное',
    },
    {
      segment: `${role}/charts`,
      title: 'Валюты',
      icon: <PaymentsIcon />,
    },
    {
      segment: `${role}/pay-methods`,
      title: 'Платежные методы',
      icon: <PointOfSaleIcon />,
    },
    {
      segment: `${role}/requisites`,
      title: 'Реквизиты',
      icon: <PaymentIcon />,
    },
    {
      segment: `${role}/orders`,
      title: 'Ордера',
      icon: <AssignmentIcon />,
    },
    {
      segment: `${role}/disputs`,
      title: 'Споры',
      icon: <ErrorOutlineIcon />,
    },
    {
      segment: `${role}/withdraws-funds`,
      title: 'Вывод средств',
      icon: <UpgradeIcon />,
    },
    {
      segment: `${role}/parsers`,
      title: 'Парсеры',
      icon: <FilterAltIcon />,
    },
    {
      segment: `${role}/messages`,
      title: 'Сообщения',
      icon: <SpeakerNotesIcon />,
    },
  ];

  const merchantNavigation: Navigation = [
    {
      kind: 'header',
      title: role === 'merchant' ? `Мерчант` : role === 'admin' ? "Админ" : 'Трейдер',
    },
    {
      kind: 'divider',
    },
    {
      kind: 'header',
      title: 'Магазины',
    },
    {
      segment: `${role}`,
      title: 'Магазины',
      icon: <AttachMoneyIcon />,
    },
  ];

  const useCustomRouter = (): Router => {
    const navigate = useNavigate();
    const location = useLocation();

    const router = React.useMemo(() => {
      return {
        pathname: location.pathname,
        searchParams: new URLSearchParams(location.search),
        navigate: (path: string | URL) => {
          const newPath = path.toString();
          navigate(newPath);
        },
      };
    }, [location, navigate]);

    return router;
  }

  const router = useCustomRouter();

  const testContentForChip = <React.Fragment>
    <Typography variant='caption'>2024-11-20 23:49</Typography>
    <Typography variant='subtitle2'>Bybit ([RUB] SBERBANK)</Typography>
  </React.Fragment>

  const dispatch = useAppDispatch()

  const getProfile = useCallback(async () => {
    const userId = localStorage.getItem("user_id") ?? '';
    const data = await GetProfileMethod(userId)
    if (data.status === 200 && data.data.Success) {
      const row = data.data.data
      dispatch(addProfileData(row))
    }
  }
    , [dispatch])

  useEffect(() => {
    getProfile()
  }, [getProfile])

  const profile = useAppSelector((state) => state.app.profile)

  const ToolbarActionsBox = () => {
    return (
      <Stack direction={"row"} alignItems={'center'} width={'100%'} >
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            overflowX: 'auto', // горизонтальная прокрутка
            margin: '0 50px 0 150px',
            width: '100%',
            '@media screen and (max-width: 700px)': {
              display: 'none',
            },
            '@media screen and (min-width: 701px) and (max-width: 1200px)': {
              margin: '0 50px',
            }
          }}
        >
          <Marquee
            pauseOnHover={true}
            pauseOnClick={true}
            gradient={true}
            gradientWidth={50}
            speed={20}
            gradientColor={mode === 'dark' ? '#121212' : '#f5f5f5'}
            autoFill={true}
            style={{ width: 'auto' }}
          >
            <CustomHtmlChip content={testContentForChip} label='USDT/RUB 100.43' />
          </Marquee>
        </Box>
        {
          profile ?
            <Account profile={profile} role={role} />
            :
            <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" sx={{ height: '30px', width: '60px' }}>
              <CircularProgress size="20px" />
            </Stack>
        }
      </Stack>
    )
  }

  // Получаем текущий режим темы из контекста
  const { mode } = useThemeContext();

  // Обновляем тему в зависимости от состояния mode
  const theme = createAppTheme(mode);

  return (
    <div className={styles.main}>
      {isAuth ?
        <AppProvider
          navigation={role === 'admin' ? adminNavigation : role === 'merchant' ? merchantNavigation : traderNavigation}
          router={router}
          theme={theme}
          branding={{ title: `Processing`, logo: <img src={logo} alt='logo' /> }}
        // session={session}
        // authentication={authentication}
        >
          <DashboardLayout
            slots={{
              toolbarActions: ToolbarActionsBox,
            }}
            sx={{
              '& .MuiBox-root': {
                '@media screen and (max-width: 768px)': {
                  width: '100%'
                }
              },
              '& .MuiStack-root:nth-child(1)': {
                flexWrap: 'nowrap'
              },
              '& .MuiStack-root:nth-child(2)': {
                overflow: 'auto',
              }
            }}
          >
            <PageContainer breadcrumbs={[]} sx={{ margin: '20px 0px 100px 0px', flex: 1, maxWidth: '100% !important' }}>
              {
                window.location.pathname === '/trader/settings' ?
                  <Typography variant='h4'>Настройки</Typography>
                  :
                  <></>
              }
              <Divider
                sx={
                  window.location.pathname === '/trader/settings'
                    ? { margin: '30px 0 30px 0' }
                    : window.location.pathname === '/trader/funds'
                      || window.location.pathname === '/trader/requisites'
                      ? { display: 'none' }
                      : { margin: '10px 0 30px 0' }
                }
              />
              {children}
            </PageContainer>
            <Footer />
          </DashboardLayout>
        </AppProvider>
        : LoadingElement}
    </div>
  )
}

export default Main