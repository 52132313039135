import { ApiClient } from "./Client"

type GetOrdersParams = {
  id?: number,
  user_id?: number,
  course?: number,
  chart_id?: number,
  sum_fiat?: number,
  pay_id: 1 | 2,
  value?: number,
  cashback?: number,
  date?: string,
  date_expiry?: string,
  req_id?: number,
  pay_notify_order_types_id?: number[] | number,
  docs_id?: number
}

export const GetOrdersMethod = async (data: GetOrdersParams) => {
  return await ApiClient({
    method: 'POST',
    url: '/orders/get-orders',
    data: data
  })
}