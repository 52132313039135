import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/Auth/Login';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createAppTheme } from './theme';
import Main from './pages/main/Main';
import { useAppInit } from './redux/hooks/useAppInit';
import styles from './App.module.scss';
import Settings from './pages/settings/Settings';
import { useThemeContext } from './ThemeContext';
import { CssBaseline } from '@mui/material';
import Transactions from './pages/trader/transactions/Transactions';
import BalanceHistory from './pages/trader/balanceHistory/BalanceHistory';
import Orders from './pages/trader/orders/Orders';
import Statistic from './pages/trader/statistic/Statistic';
import Balances from './pages/trader/balances/Balances';
import Funds from './pages/trader/funds/Funds';
import Exchange from './pages/trader/exchange/Exchange';
import Requisites from './pages/trader/requisites/Requisites';
import GroupEdit from './pages/trader/requisites/GroupEdit';
import Automatic from './pages/trader/automatic/Automatic';
import Ref from './pages/trader/refs/Ref';
import Users from './pages/admin/users/Users';
import UsersWallet from './pages/admin/usersWallet/UsersWallet';
import { useAppDispatch, useAppSelector } from './redux/hooks/hooks';
import { addRole } from './redux/slices/app.slice';
import RequisitesA from './pages/admin/requisites/RequisitesA';
import OrdersA from './pages/admin/orders/OrdersA';
import RequisitesPayinNew from './pages/trader/requisites/RequisitesPayinNew';
import RequisitesPayoutNew from './pages/trader/requisites/RequisitesPayoutNew';
import Charts from './pages/admin/charts/Charts';
import PayMethods from './pages/admin/payMethods/PayMethods';
import Disputs from './pages/admin/disput/Disput';
import Merchants from './pages/admin/merchants/Merchants';
import Withdraws from './pages/admin/withdraws/Withdraws';
import Parsers from './pages/admin/parsers/Parsers';
import Messages from './pages/admin/messages/Messages';
import UsersEdit from './pages/admin/usersEdit/UsersEdit';
import MerchantsEdit from './pages/admin/merchantsEdit/MerchantsEdit';
import RequisitesPayinEdit from './pages/trader/requisites/RequisitesPayinEdit';
import RequisitesPayoutEdit from './pages/trader/requisites/RequisitesPayoutEdit';
import Pay from './pages/pay/Pay';
import RequisitesPayinGroupNew from './pages/trader/requisites/RequisitesPayinGroupNew';

type RoleType = "trader" | "admin" | "merchant"

const App = () => {
  useAppInit()

  const dispatch = useAppDispatch()

  const isAuth = Boolean(localStorage.getItem("access_token"));
  // const isAuth = true
  const token = localStorage.getItem("access_token") ?? '';

  try {
    let tempEncoded = JSON.parse(atob(token.split('.')[1]))?.role;
    dispatch(addRole(tempEncoded === 1 ? 'admin' : tempEncoded === 2 ? 'merchant' : 'trader'))
  } catch (error) {
    // console.log(error);
  }

  if (!isAuth && document.location.pathname !== '/login') document.location.href = '/login';

  const role: RoleType = useAppSelector((state) => state.app.role)

  // Получаем текущий режим темы из контекста
  const { mode } = useThemeContext();

  // Обновляем тему в зависимости от состояния mode
  const theme = createAppTheme(mode);

  return (
    <div className={styles.app}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route index element={<Navigate to={isAuth ? `/${role}` : '/login'} />} />
          <Route path='/login' element={<Login role={role} isAuth={isAuth} />} />
          <Route path='/payment' element={<Pay />} />
          {/* TRADER */}
          <Route path="/trader">
            <Route index element={<Main role={role} isAuth={isAuth} children={<Balances />} />} />
            <Route path="settings" element={<Main role={role} isAuth={isAuth} children={<Settings />} />} />
            <Route path="funds" element={<Main role={role} isAuth={isAuth} children={<Funds />} />} />
            <Route path="transactions" element={<Main role={role} isAuth={isAuth} children={<Transactions />} />} />
            <Route path="balance-history" element={<Main role={role} isAuth={isAuth} children={<BalanceHistory />} />} />
            <Route path="exchange" element={<Main role={role} isAuth={isAuth} children={<Exchange />} />} />
            <Route path="requisites" element={<Main role={role} isAuth={isAuth} children={<Requisites />} />} />
            <Route path="requisites/payin/new" element={<Main role={role} isAuth={isAuth} children={<RequisitesPayinNew />} />} />
            <Route path="requisites/payin/group/new" element={<Main role={role} isAuth={isAuth} children={<RequisitesPayinGroupNew />} />} />
            <Route path="requisites/payout/new" element={<Main role={role} isAuth={isAuth} children={<RequisitesPayoutNew />} />} />
            <Route path="requisites/payin/*" element={<Main role={role} isAuth={isAuth} children={<RequisitesPayinEdit />} />} />
            <Route path="requisites/payout/*" element={<Main role={role} isAuth={isAuth} children={<RequisitesPayoutEdit />} />} />
            <Route path="requisites/payin/group/edit/*" element={<Main role={role} isAuth={isAuth} children={<GroupEdit />} />} />
            <Route path="orders" element={<Main role={role} isAuth={isAuth} children={<Orders />} />} />
            <Route path="automatic" element={<Main role={role} isAuth={isAuth} children={<Automatic />} />} />
            <Route path="referral-system" element={<Main role={role} isAuth={isAuth} children={<Ref />} />} />
            <Route path="statistics" element={<Main role={role} isAuth={isAuth} children={<Statistic />} />} />
            <Route path=":/*" element={<Main role={role} isAuth={isAuth} children={<Balances />} />} />
          </Route>
          {/* TRADER */}
          {/* ADMIN */}
          <Route path="/admin">
            <Route index element={<Main role={role} isAuth={isAuth} children={<Users />} />} />
            <Route path='users/:id/wallet' element={<Main role={role} isAuth={isAuth} children={<UsersWallet />} />} />
            <Route path='users/:id/edit' element={<Main role={role} isAuth={isAuth} children={<UsersEdit />} />} />
            <Route path='requisites' element={<Main role={role} isAuth={isAuth} children={<RequisitesA />} />} />
            <Route path='orders' element={<Main role={role} isAuth={isAuth} children={<OrdersA />} />} />
            <Route path='charts' element={<Main role={role} isAuth={isAuth} children={<Charts />} />} />
            <Route path='pay-methods' element={<Main role={role} isAuth={isAuth} children={<PayMethods />} />} />
            <Route path='disputs' element={<Main role={role} isAuth={isAuth} children={<Disputs />} />} />
            <Route path='merchants' element={<Main role={role} isAuth={isAuth} children={<Merchants />} />} />
            <Route path='merchants/:id/edit' element={<Main role={role} isAuth={isAuth} children={<MerchantsEdit />} />} />
            <Route path='withdraws-funds' element={<Main role={role} isAuth={isAuth} children={<Withdraws />} />} />
            <Route path='parsers' element={<Main role={role} isAuth={isAuth} children={<Parsers />} />} />
            <Route path='messages' element={<Main role={role} isAuth={isAuth} children={<Messages />} />} />
            <Route path="settings" element={<Main role={role} isAuth={isAuth} children={<Settings />} />} />
            <Route path=":/*" element={<Main role={role} isAuth={isAuth} children={<Users />} />} />
          </Route>
          {/* ADMIN */}
          {/* MERCHANT */}
          <Route path="/merchant">
            <Route index element={<Main role={role} isAuth={isAuth} children={<Balances />} />} />
            <Route path=":*/" element={<Main role={role} isAuth={isAuth} children={<Balances />} />} />
          </Route>
          {/* MERCHANT */}
          <Route path="*" element={<Navigate to={isAuth ? `/${role}` : '/login'} />} />
        </Routes>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
