import React, { useCallback, useEffect, useState } from 'react'
import styles from './BalanceHistory.module.scss'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Button, IconButton, Menu, Paper, Stack, Tooltip, Typography, Zoom } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import CustomSelect from '../../../UI/customSelect/CustomSelect';
import { CustomSelectContentType } from '../../../types/types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomTable from '../../../UI/customTable/CustomTable';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomSnackBar from '../../../UI/customSnackBar/CustomSnackBar';

const BalanceHistory = () => {
  // const [value, setValue] = React.useState('1');

  // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  //   setValue(newValue);
  // };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [dateOn, setDateOn] = useState<Dayjs | null>(null)
  const [dateFor, setDateFor] = useState<Dayjs | null>(null)

  const [operationSelected, setOperationSelected] = useState('')

  const operationList: CustomSelectContentType[] = [
    { contentLabel: 'Вывод средств', contentValue: 'fundsWithdraws' },
    { contentLabel: 'Пополнение средств', contentValue: 'fundsDeposit' }
  ]

  const handleResetFilters = () => {
    setOperationSelected('')
    setDateFor(null)
    setDateOn(null)
    setAnchorEl(null);
  }

  const [balanceHistory, setBalanceHistory] = useState<any[]>([])
  // const [fundsArchiveOrders, setFundsArchiveOrders] = useState<any[]>([])

  const [openS, setOpenS] = React.useState(false);

  const copy = (str: string) => {
    navigator.clipboard.writeText(str).then(function () {
      setOpenS(true)
    }, function (err) {
      console.error('Произошла ошибка при копировании текста: ', err);
    });
  }

  const getBalanceHistory = useCallback(async () => {
    const rowsBalanceHistory: any[] = [
      [
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('adress to copy')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <ContentCopyIcon color='primary' fontSize='small' />
            </Tooltip>
          </Typography>
        </Box>,
        <Box>
          <Typography>USDT</Typography>
        </Box>,
        <Box>
          <Typography>2024-11-25 16:39</Typography>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>0,78866</Typography>
            <Typography color='error'>(-9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>9,63482</Typography>
            <Typography color='primary'>(+9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Typography>Возврат из отмененных</Typography>
        </Box>,
      ],
      [
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('adress to copy')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <ContentCopyIcon color='primary' fontSize='small' />
            </Tooltip>
          </Typography>
        </Box>,
        <Box>
          <Typography>USDT</Typography>
        </Box>,
        <Box>
          <Typography>2024-11-25 16:39</Typography>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>0,78866</Typography>
            <Typography color='error'>(-9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>9,63482</Typography>
            <Typography color='primary'>(+9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Typography>Возврат из отмененных</Typography>
        </Box>,
      ],
      [
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('adress to copy')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <ContentCopyIcon color='primary' fontSize='small' />
            </Tooltip>
          </Typography>
        </Box>,
        <Box>
          <Typography>USDT</Typography>
        </Box>,
        <Box>
          <Typography>2024-11-25 16:39</Typography>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>0,78866</Typography>
            <Typography color='error'>(-9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>9,63482</Typography>
            <Typography color='primary'>(+9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Typography>Возврат из отмененных</Typography>
        </Box>,
      ],
      [
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('adress to copy')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <ContentCopyIcon color='primary' fontSize='small' />
            </Tooltip>
          </Typography>
        </Box>,
        <Box>
          <Typography>USDT</Typography>
        </Box>,
        <Box>
          <Typography>2024-11-25 16:39</Typography>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>0,78866</Typography>
            <Typography color='error'>(-9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>9,63482</Typography>
            <Typography color='primary'>(+9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Typography>Возврат из отмененных</Typography>
        </Box>,
      ],
      [
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('adress to copy')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <ContentCopyIcon color='primary' fontSize='small' />
            </Tooltip>
          </Typography>
        </Box>,
        <Box>
          <Typography>USDT</Typography>
        </Box>,
        <Box>
          <Typography>2024-11-25 16:39</Typography>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>0,78866</Typography>
            <Typography color='error'>(-9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>9,63482</Typography>
            <Typography color='primary'>(+9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Typography>Возврат из отмененных</Typography>
        </Box>,
      ],
      [
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('adress to copy')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <ContentCopyIcon color='primary' fontSize='small' />
            </Tooltip>
          </Typography>
        </Box>,
        <Box>
          <Typography>USDT</Typography>
        </Box>,
        <Box>
          <Typography>2024-11-25 16:39</Typography>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>0,78866</Typography>
            <Typography color='error'>(-9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>9,63482</Typography>
            <Typography color='primary'>(+9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Typography>Возврат из отмененных</Typography>
        </Box>,
      ],
      [
        <Box>
          <Typography variant="caption" width={"max-content"} display={"block"}>
            <Tooltip
              onClick={() => copy('adress to copy')}
              title="Скопировать"
              sx={{ cursor: 'pointer' }}
              slots={{
                transition: Zoom,
              }}
            >
              <ContentCopyIcon color='primary' fontSize='small' />
            </Tooltip>
          </Typography>
        </Box>,
        <Box>
          <Typography>USDT</Typography>
        </Box>,
        <Box>
          <Typography>2024-11-25 16:39</Typography>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>0,78866</Typography>
            <Typography color='error'>(-9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Stack flexDirection={"row"} gap={"5px"}>
            <Typography>9,63482</Typography>
            <Typography color='primary'>(+9,63482)</Typography>
          </Stack>
        </Box>,
        <Box>
          <Typography>Возврат из отмененных</Typography>
        </Box>,
      ],
    ]

    setBalanceHistory(rowsBalanceHistory)

  }
    , [])

  useEffect(() => {
    getBalanceHistory()
  }, [getBalanceHistory])

  const rowsHead = ['Актив', 'Валюта', 'Дата', 'Доступно', 'Заморожено', 'Основание']

  return (
    <TabContext value={'1'}>
      <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"flex-end"} sx={{ padding: '8px 16px 0 16px' }}>
        <IconButton onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
            <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DatePicker
            label="Дата от"
            value={dateOn}
            onChange={(newValue) => setDateOn(newValue)}
          />
          <DatePicker
            label="Дата до"
            value={dateFor}
            onChange={(newValue) => setDateFor(newValue)}
          />
          <CustomSelect
            label='Операция'
            content={operationList}
            variant='outlined'
            value={operationSelected}
            setValue={setOperationSelected}
          />
          <Stack flexDirection={"row"} gap={"10px"}>
            <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
            <Button variant='outlined' fullWidth>OK</Button>
          </Stack>
        </Menu>
      </Stack>
      <TabPanel value="1" sx={{ padding: '10px 0' }}>
        {
          balanceHistory.length ?
            <CustomTable rowsDataHead={rowsHead} rowsDataBody={balanceHistory} paginationCol={6} />
            :
            <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <ErrorOutlineIcon />
              Данные не найдены
            </Paper>
        }
      </TabPanel>
      <CustomSnackBar open={openS} message={'Скопировано!'} setOpen={setOpenS} />
    </TabContext>
  )
}

export default BalanceHistory