import React, { ReactNode } from 'react'
import { HtmlTooltip } from '../../UI/htmlToolTip/HtmlToolTip'
import { Button, Typography, Zoom } from '@mui/material'

interface CustomHtmlChipPropsType {
  content: ReactNode;
  label: string
}

const CustomHtmlChip = ({
  content,
  label
}: CustomHtmlChipPropsType) => {
  return (
    <HtmlTooltip
      title={content}
      slots={{
        transition: Zoom,
      }}
    >
      <Button
        sx={{
          border: '1px solid #555',
          borderRadius: '16px',
          padding: '4px 8px',
          fontWeight: 500,
          // color: '#fff',
          textTransform: 'unset',
          margin: '0 5px'
        }}
      >
        {label}
      </Button>
    </HtmlTooltip>
  )
}

export default CustomHtmlChip