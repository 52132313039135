import React, { useCallback, useEffect, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, ButtonGroup, Chip, IconButton, Menu, Paper, Stack, Tab, Tooltip, Typography, Zoom } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import CustomSelect from '../../../UI/customSelect/CustomSelect';
import { CustomSelectContentType, IOrder } from '../../../types/types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomTable from '../../../UI/customTable/CustomTable';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomSnackBar from '../../../UI/customSnackBar/CustomSnackBar';
import Modal from '../../../UI/modal/Modal';
import { GetOrdersMethod } from '../../../services/Orders';
import { OrdersSample } from './OrdersSample';
import { useAppSelector } from '../../../redux/hooks/hooks';

const Orders = () => {
  const [payValue, setPayValue] = React.useState('payin');
  const [valueTabOne, setValueTabOne] = React.useState('1');
  const [valueTabTwo, setValueTabTwo] = React.useState('1');

  const profile = useAppSelector((state) => state.app.profile);

  const handleChangeTabOne = (event: React.SyntheticEvent, newValue: string) => {
    setValueTabOne(newValue);
  };
  const handleChangeTabTwo = (event: React.SyntheticEvent, newValue: string) => {
    setValueTabTwo(newValue);
  };

  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const [dateOn, setDateOn] = useState<Dayjs | null>(null)
  // const [dateFor, setDateFor] = useState<Dayjs | null>(null)

  // const [operationSelected, setOperationSelected] = useState('')

  // const operationList: CustomSelectContentType[] = [
  //   { contentLabel: 'Вывод средств', contentValue: 'fundsWithdraws' },
  //   { contentLabel: 'Пополнение средств', contentValue: 'fundsDeposit' }
  // ]

  // const handleResetFilters = () => {
  //   setOperationSelected('')
  //   setDateFor(null)
  //   setDateOn(null)
  //   setAnchorEl(null);
  // }

  const [openS, setOpenS] = React.useState(false);

  // const copy = (str: string) => {
  //   navigator.clipboard.writeText(str).then(function () {
  //     setOpenS(true)
  //   }, function (err) {
  //     console.error('Произошла ошибка при копировании текста: ', err);
  //   });
  // }

  // const [openModalAuto, setOpenModalAuto] = useState(false)
  // const [openModalAutoGroup, setOpenModalAutoGroup] = useState(false)

  // const handleOpenModalAuto = () => {
  //   setOpenModalAuto(true)
  // }
  // const handleOpenModalAutoGroup = () => {
  //   setOpenModalAutoGroup(true)
  // }

  // const modalAuto = <Box gap={"5px"}>
  //   <Typography variant='body1' fontWeight={700} gutterBottom>Автоматическая обработка ордеров</Typography>
  //   <Typography variant='subtitle2' fontWeight={700} sx={{ marginTop: '30px' }}>API ключ</Typography>
  //   <Typography variant='subtitle1' width={"max-content"} display={"block"}>
  //     <Tooltip
  //       onClick={() => copy('156dd7d8-1749-4331-8a28-7aa2c0b6ab95')}
  //       title="Скопировать"
  //       sx={{ cursor: 'pointer' }}
  //       slots={{
  //         transition: Zoom,
  //       }}
  //     >
  //       <Stack flexDirection={'row'} gap={'5px'}>
  //         <Typography noWrap fontSize={'0.7rem'}>156dd7d8-1749-4331-8a28-7aa2c0b6ab95</Typography>
  //         <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
  //       </Stack>
  //     </Tooltip>
  //   </Typography>
  //   <Typography variant='subtitle2' fontWeight={700} sx={{ marginTop: '30px' }}>Способ автоматизации</Typography>
  //   <Typography variant='subtitle1' fontSize={'0.7rem'}>Автоматический (Группа)</Typography>
  //   <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
  //     <Button onClick={() => setOpenModalAuto(false)}>Отмена</Button>
  //     <Button variant='contained' color='primary' sx={{ fontWeight: 700 }}>Сохранить</Button>
  //   </Stack>
  // </Box>

  // const modalAutoGroup = <Box gap={"5px"}>
  //   <Typography variant='body1' fontWeight={700} gutterBottom>Автоматизация</Typography>
  //   <Typography variant='subtitle2' fontWeight={700} sx={{ marginTop: '30px' }}>API ключ</Typography>
  //   <Typography variant='subtitle1' width={"max-content"} display={"block"}>
  //     <Tooltip
  //       onClick={() => copy('156dd7d8-1749-4331-8a28-7aa2c0b6ab95')}
  //       title="Скопировать"
  //       sx={{ cursor: 'pointer' }}
  //       slots={{
  //         transition: Zoom,
  //       }}
  //     >
  //       <Stack flexDirection={'row'} gap={'5px'}>
  //         <Typography noWrap fontSize={'0.7rem'}>156dd7d8-1749-4331-8a28-7aa2c0b6ab95</Typography>
  //         <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
  //       </Stack>
  //     </Tooltip>
  //   </Typography>
  //   <Typography variant='subtitle2' fontWeight={700} sx={{ marginTop: '30px' }}>Способ автоматизации</Typography>
  //   <ButtonGroup sx={{ marginTop: '5px' }}>
  //     <Button variant="outlined">Ручной</Button>
  //     <Button variant="contained">Автоматический</Button>
  //   </ButtonGroup>
  //   <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
  //     <Button onClick={() => setOpenModalAutoGroup(false)}>Отмена</Button>
  //     <Button variant='contained' color='primary' sx={{ fontWeight: 700 }}>Сохранить</Button>
  //   </Stack>
  // </Box>

  // const [openModalDelete, setOpenModalDelete] = useState(false)
  // const [openModalDeleteGroup, setOpenModalDeleteGroup] = useState(false)

  // const handleOpenModalDelete = () => {
  //   setOpenModalDelete(true)
  // }
  // const handleOpenModalDeleteGroup = () => {
  //   setOpenModalDeleteGroup(true)
  // }

  // const modalDelete = <Box gap={"5px"}>
  //   <Typography variant='body1' fontWeight={700} gutterBottom>Вы уверены, что хотите удалить реквизиты?</Typography>
  //   <Box>
  //     <Typography fontWeight={700} noWrap>Name name</Typography>
  //     <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>RUB</Typography>
  //     <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>Перевод по номеру счета</Typography>
  //     <Typography variant='subtitle2' color='textDisabled' fontSize={'0.7rem'} noWrap>СберБанк</Typography>
  //     <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
  //       <Tooltip
  //         onClick={() => copy('044525225')}
  //         title="БИК: 044525225"
  //         sx={{ cursor: 'pointer' }}
  //         slots={{
  //           transition: Zoom,
  //         }}
  //       >
  //         <Stack flexDirection={'row'} gap={'5px'}>
  //           <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
  //             БИК: 044525225
  //           </Typography>
  //           <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
  //         </Stack>
  //       </Tooltip>
  //     </Typography>
  //     <Typography variant="caption" noWrap width={"max-content"} display={"block"}>
  //       <Tooltip
  //         onClick={() => copy('40820810738260623135')}
  //         title="40820810738260623135"
  //         sx={{ cursor: 'pointer' }}
  //         slots={{
  //           transition: Zoom,
  //         }}
  //       >
  //         <Stack flexDirection={'row'} gap={'5px'}>
  //           <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>
  //             40820810738260623135
  //           </Typography>
  //           <ContentCopyIcon color='primary' sx={{ width: '15px', height: '15px' }} />
  //         </Stack>
  //       </Tooltip>
  //     </Typography>
  //     <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>Name name</Typography>
  //     <Typography variant='subtitle1' fontSize={'0.7rem'} noWrap>3135</Typography>
  //   </Box>
  //   <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
  //     <Button onClick={() => setOpenModalDelete(false)}>Отмена</Button>
  //     <Button variant='contained' color='error' sx={{ fontWeight: 700 }}>Ок</Button>
  //   </Stack>
  // </Box>

  // const modalDeleteGroup = <Box gap={"5px"}>
  //   <Typography variant='body1' fontWeight={700} gutterBottom>Подтверждение удаления</Typography>
  //   <Typography variant='subtitle1' fontSize={'0.7rem'} marginTop={"20px"} noWrap>Вы уверены, что хотите удалить элемент?</Typography>
  //   <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
  //     <Button onClick={() => setOpenModalDeleteGroup(false)}>Отмена</Button>
  //     <Button variant='contained' color='error' sx={{ fontWeight: 700 }}>Ок</Button>
  //   </Stack>
  // </Box>

  // const [status, setStatus] = useState(false)

  // const handleChangeStatus = () => {
  //   setStatus(!status)
  // }

  // const navigate = useNavigate()

  const [rowOrders, setRowOrders] = useState<IOrder[]>([])
  const [orders, setOrders] = useState<any[]>([])

  // SET ORDERS
  useEffect(() => {
    const toRender = OrdersSample({ rowOrders, setOpenS })
    setOrders(toRender)
  }, [rowOrders])

  const getOrders = useCallback(async () => {
    if (!profile) return
    if (payValue === 'payin') {
      if (valueTabOne === '1') {
        const dataOrders = await GetOrdersMethod({ pay_id: 1, user_id: +profile.id, pay_notify_order_types_id: 1 })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabOne === '2') {
        const dataOrders = await GetOrdersMethod({ pay_id: 1, user_id: +profile.id, pay_notify_order_types_id: 2 })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabOne === '3') {
        const dataOrders = await GetOrdersMethod({ pay_id: 1, user_id: +profile.id, pay_notify_order_types_id: [3, 9] })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabOne === '4') {
        const dataOrders = await GetOrdersMethod({ pay_id: 1, user_id: +profile.id, pay_notify_order_types_id: [5, 8] })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabOne === '5') {
        const dataOrders = await GetOrdersMethod({ pay_id: 1, user_id: +profile.id })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      }
    } else if (payValue === 'payout') {
      if (valueTabTwo === '1') {
        const dataOrders = await GetOrdersMethod({ pay_id: 2, user_id: +profile.id, pay_notify_order_types_id: 1 })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabTwo === '2') {
        const dataOrders = await GetOrdersMethod({ pay_id: 2, user_id: +profile.id, pay_notify_order_types_id: 2 })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabTwo === '3') {
        const dataOrders = await GetOrdersMethod({ pay_id: 2, user_id: +profile.id, pay_notify_order_types_id: [3, 9] })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabTwo === '4') {
        const dataOrders = await GetOrdersMethod({ pay_id: 2, user_id: +profile.id, pay_notify_order_types_id: [5, 8] })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabTwo === '5') {
        const dataOrders = await GetOrdersMethod({ pay_id: 2, user_id: +profile.id })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      }
    }
  }
    , [payValue, profile, valueTabOne, valueTabTwo])

  useEffect(() => {
    getOrders()
  }, [getOrders])

  const rowsHeadOrders = [
    'ID',
    'Курс',
    'Сумма',
    'Реквизиты',
    'Оплата',
    'Статус',
  ]

  return (
    <TabContext value={payValue}>
      <Stack flexDirection={'row'} gap={"10px"}>
        <Chip label={'PayIn'} variant='outlined' color={payValue === 'payin' ? 'primary' : 'default'} onClick={() => setPayValue('payin')} />
        <Chip label={'PayOut'} variant='outlined' color={payValue === 'payout' ? 'primary' : 'default'} onClick={() => setPayValue('payout')} />
      </Stack>
      <TabPanel value="payin" sx={{ padding: '10px 0' }}>
        <TabContext value={valueTabOne}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTabOne}>
              <Tab label="Активные" value="1" />
              <Tab label="Успешные" value="2" />
              <Tab label="Отмененные" value="3" />
              <Tab label="Диспут" value="4" />
              <Tab label="Все" value="5" />
            </TabList>
          </Box>
          {/* <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"flex-end"} sx={{ padding: '8px 16px 0 16px' }}>
            <IconButton onClick={handleClick}>
              <FilterListIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
                <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <DatePicker
                label="Дата от"
                value={dateOn}
                onChange={(newValue) => setDateOn(newValue)}
              />
              <DatePicker
                label="Дата до"
                value={dateFor}
                onChange={(newValue) => setDateFor(newValue)}
              />
              <Stack flexDirection={"row"} gap={"10px"}>
                <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
                <Button variant='outlined' fullWidth>OK</Button>
              </Stack>
            </Menu>
          </Stack> */}
          <TabPanel value="1" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="2" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="3" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="4" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="5" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
        </TabContext>
      </TabPanel>
      <TabPanel value="payout" sx={{ padding: '10px 0' }}>
        <TabContext value={valueTabTwo}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTabTwo}>
              <Tab label="Активные" value="1" />
              <Tab label="Успешные" value="2" />
              <Tab label="Отмененные" value="3" />
              <Tab label="Диспут" value="4" />
              <Tab label="Все" value="5" />
            </TabList>
          </Box>
          {/* <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"flex-end"} sx={{ padding: '8px 16px 0 16px' }}>
            <IconButton onClick={handleClick}>
              <FilterListIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
                <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <DatePicker
                label="Дата от"
                value={dateOn}
                onChange={(newValue) => setDateOn(newValue)}
              />
              <DatePicker
                label="Дата до"
                value={dateFor}
                onChange={(newValue) => setDateFor(newValue)}
              />
              <Stack flexDirection={"row"} gap={"10px"}>
                <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
                <Button variant='outlined' fullWidth>OK</Button>
              </Stack>
            </Menu>
          </Stack> */}
          <TabPanel value="1" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="2" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="3" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="4" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="5" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
        </TabContext>
      </TabPanel>
      <CustomSnackBar open={openS} message={'Скопировано!'} setOpen={setOpenS} />
      {/* <Modal modalContent={modalAuto} open={openModalAuto} setOpen={setOpenModalAuto} />
      <Modal modalContent={modalAutoGroup} open={openModalAutoGroup} setOpen={setOpenModalAutoGroup} />
      <Modal modalContent={modalDelete} open={openModalDelete} setOpen={setOpenModalDelete} />
      <Modal modalContent={modalDeleteGroup} open={openModalDeleteGroup} setOpen={setOpenModalDeleteGroup} /> */}
    </TabContext>
  )
}

export default Orders