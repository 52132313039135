import React, { useCallback, useEffect, useState } from 'react'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Button, Chip, IconButton, Menu, Paper, Stack, Typography } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { Dayjs } from 'dayjs';
import CustomSelect from '../../../UI/customSelect/CustomSelect';
import { CustomSelectContentType, IUser } from '../../../types/types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomTable from '../../../UI/customTable/CustomTable';
import CustomSnackBar from '../../../UI/customSnackBar/CustomSnackBar';
import { GetUsersMethod } from '../../../services/admin/Users';
import { UsersAllSample } from './UsersAllSample';

const Users = () => {
  // const [value, setValue] = React.useState('1');

  // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  //   setValue(newValue);
  // };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const [dateOn, setDateOn] = useState<Dayjs | null>(null)
  // const [dateFor, setDateFor] = useState<Dayjs | null>(null)

  const [operationSelected, setOperationSelected] = useState('all')

  const operationList: CustomSelectContentType[] = [
    { contentLabel: 'Все пользователи', contentValue: 'all' },
    { contentLabel: 'Заявки на регистрацию', contentValue: 'applications' }
  ]

  const handleResetFilters = () => {
    setOperationSelected('')
    // setDateFor(null)
    // setDateOn(null)
    setAnchorEl(null);
    getUsers()
  }

  // const [users, setUsers] = useState<any[]>([])
  // const [fundsArchiveOrders, setFundsArchiveOrders] = useState<any[]>([])

  const [openS, setOpenS] = React.useState(false);

  const [rowUsers, setRowUsers] = useState<IUser[]>([])
  const [users, setUsers] = useState<any[]>([])

  const getUsers = useCallback(async () => {
    const usersData = await GetUsersMethod(0, operationSelected === 'applications' ? 1 : undefined);
    if (usersData.status === 200 && usersData.data.Success) {
      setRowUsers(usersData.data.data)
    }
  }
    , [operationSelected])

  // SET USERS
  useEffect(() => {
    const toRender = UsersAllSample({ rowUsers, setOpenS, getUsers })
    setUsers(toRender)
  }, [getUsers, operationSelected, rowUsers])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const rowsHead = ['ID', 'Имя', 'Почта', 'Телеграм', 'Создан', 'Действия']

  return (
    <TabContext value={'1'}>
      <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"space-between"} sx={{ padding: '8px 16px 0 16px' }}>
        <Chip label={operationSelected === 'all' ? 'Все пользователи' : 'Заявки на регистрацию'} />
        <IconButton onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
            <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {/* <DatePicker
            label="Дата от"
            value={dateOn}
            onChange={(newValue) => setDateOn(newValue)}
          />
          <DatePicker
            label="Дата до"
            value={dateFor}
            onChange={(newValue) => setDateFor(newValue)}
          /> */}
          <CustomSelect
            label='Тип'
            content={operationList}
            variant='outlined'
            value={operationSelected}
            setValue={setOperationSelected}
          />
          <Stack flexDirection={"row"} gap={"10px"}>
            <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
            {/* <Button variant='outlined' fullWidth>OK</Button> */}
          </Stack>
        </Menu>
      </Stack>
      <TabPanel value="1" sx={{ padding: '10px 0' }}>
        {
          users.length ?
            <CustomTable rowsDataHead={rowsHead} rowsDataBody={users} paginationCol={rowsHead.length} />
            :
            <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <ErrorOutlineIcon />
              Данные не найдены
            </Paper>
        }
      </TabPanel>
      <CustomSnackBar open={openS} message={'Скопировано!'} setOpen={setOpenS} />
    </TabContext>
  )
}

export default Users