import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Chip, IconButton, Menu, Paper, Stack, Tab, Typography } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { useCallback, useEffect, useState } from 'react'
import CustomSelect from '../../../UI/customSelect/CustomSelect';
import CustomTable from '../../../UI/customTable/CustomTable';
import CustomSnackBar from '../../../UI/customSnackBar/CustomSnackBar';
import { CustomSelectContentType, IOrder } from '../../../types/types';
import { OrdersASample } from './OrdersASample';
import { GetOrdersAMethod } from '../../../services/admin/Orders';
import { GetOrdersMethod } from '../../../services/Orders';

const OrdersA = () => {

  const [payValue, setPayValue] = React.useState('payin');
  const [valueTabOne, setValueTabOne] = React.useState('1');
  const [valueTabTwo, setValueTabTwo] = React.useState('1');

  const handleChangeTabOne = (event: React.SyntheticEvent, newValue: string) => {
    setValueTabOne(newValue);
  };
  const handleChangeTabTwo = (event: React.SyntheticEvent, newValue: string) => {
    setValueTabTwo(newValue);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [operationSelected, setOperationSelected] = useState('all')

  const operationList: CustomSelectContentType[] = [
    { contentLabel: 'Все сделки', contentValue: 'all' },
    // { contentLabel: 'Заявки на регистрацию', contentValue: 'applications' }
  ]

  const handleResetFilters = () => {
    setOperationSelected('')
    setAnchorEl(null);
    getOrders()
  }

  const [openS, setOpenS] = useState(false);

  const [rowOrders, setRowOrders] = useState<IOrder[]>([])
  const [orders, setOrders] = useState<any[]>([])

  const getOrders = useCallback(async () => {
    if (payValue === 'payin') {
      if (valueTabOne === '1') {
        const dataOrders = await GetOrdersMethod({ pay_id: 1, id: 0, pay_notify_order_types_id: 1 })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabOne === '2') {
        const dataOrders = await GetOrdersMethod({ pay_id: 1, id: 0, pay_notify_order_types_id: 2 })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabOne === '3') {
        const dataOrders = await GetOrdersMethod({ pay_id: 1, id: 0, pay_notify_order_types_id: [3, 9] })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabOne === '4') {
        const dataOrders = await GetOrdersMethod({ pay_id: 1, id: 0, pay_notify_order_types_id: [5, 8] })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabOne === '5') {
        const dataOrders = await GetOrdersMethod({ pay_id: 1, id: 0 })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      }
    } else if (payValue === 'payout') {
      if (valueTabTwo === '1') {
        const dataOrders = await GetOrdersMethod({ pay_id: 2, id: 0, pay_notify_order_types_id: 1 })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabTwo === '2') {
        const dataOrders = await GetOrdersMethod({ pay_id: 2, id: 0, pay_notify_order_types_id: 2 })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabTwo === '3') {
        const dataOrders = await GetOrdersMethod({ pay_id: 2, id: 0, pay_notify_order_types_id: [3, 9] })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabTwo === '4') {
        const dataOrders = await GetOrdersMethod({ pay_id: 2, id: 0, pay_notify_order_types_id: [5, 8] })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      } else if (valueTabTwo === '5') {
        const dataOrders = await GetOrdersMethod({ pay_id: 2, id: 0 })
        if (dataOrders.status === 200 && dataOrders.data.Success) {
          setRowOrders(dataOrders.data.data)
        } else {
          setRowOrders([])
        }
      }
    }
  }
    , [payValue, valueTabOne, valueTabTwo])

  // SET ORDERS
  useEffect(() => {
    const toRender = OrdersASample({ rowOrders, setOpenS })
    setOrders(toRender)
  }, [getOrders, operationSelected, rowOrders])

  useEffect(() => {
    getOrders()
  }, [getOrders])

  const rowsHeadOrders = ['ID', 'Курс', 'Суммы', 'Реквизиты', 'Оплата', 'Статус']

  return (
    // <TabContext value={'1'}>
    //   <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"space-between"} sx={{ padding: '8px 16px 0 16px' }}>
    //     <Chip label={operationSelected === 'all' ? 'Сделки' : '..'} />
    //     <IconButton onClick={handleClick}>
    //       <FilterListIcon />
    //     </IconButton>
    //     <Menu
    //       id="basic-menu"
    //       anchorEl={anchorEl}
    //       open={open}
    //       onClose={handleClose}
    //       MenuListProps={{
    //         'aria-labelledby': 'basic-button',
    //         sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
    //       }}
    //     >
    //       <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
    //         <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
    //         <IconButton onClick={handleClose}>
    //           <CloseIcon />
    //         </IconButton>
    //       </Box>
    //       <CustomSelect
    //         label='Тип'
    //         content={operationList}
    //         variant='outlined'
    //         value={operationSelected}
    //         setValue={setOperationSelected}
    //       />
    //       <Stack flexDirection={"row"} gap={"10px"}>
    //         <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
    //       </Stack>
    //     </Menu>
    //   </Stack>
    //   <TabPanel value="1" sx={{ padding: '10px 0' }}>
    //     {
    //       orders.length ?
    //         <CustomTable rowsDataHead={rowsHead} rowsDataBody={orders} paginationCol={rowsHead.length} />
    //         :
    //         <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
    //           <ErrorOutlineIcon />
    //           Данные не найдены
    //         </Paper>
    //     }
    //   </TabPanel>
    //   <CustomSnackBar open={openS} message={'Скопировано!'} setOpen={setOpenS} />
    // </TabContext>
    <TabContext value={payValue}>
      <Stack flexDirection={'row'} gap={"10px"}>
        <Chip label={'PayIn'} variant='outlined' color={payValue === 'payin' ? 'primary' : 'default'} onClick={() => setPayValue('payin')} />
        <Chip label={'PayOut'} variant='outlined' color={payValue === 'payout' ? 'primary' : 'default'} onClick={() => setPayValue('payout')} />
      </Stack>
      <TabPanel value="payin" sx={{ padding: '10px 0' }}>
        <TabContext value={valueTabOne}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTabOne}>
              <Tab label="Активные" value="1" />
              <Tab label="Успешные" value="2" />
              <Tab label="Отмененные" value="3" />
              <Tab label="Диспут" value="4" />
              <Tab label="Все" value="5" />
            </TabList>
          </Box>
          {/* <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"flex-end"} sx={{ padding: '8px 16px 0 16px' }}>
            <IconButton onClick={handleClick}>
              <FilterListIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
                <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <DatePicker
                label="Дата от"
                value={dateOn}
                onChange={(newValue) => setDateOn(newValue)}
              />
              <DatePicker
                label="Дата до"
                value={dateFor}
                onChange={(newValue) => setDateFor(newValue)}
              />
              <Stack flexDirection={"row"} gap={"10px"}>
                <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
                <Button variant='outlined' fullWidth>OK</Button>
              </Stack>
            </Menu>
          </Stack> */}
          <TabPanel value="1" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="2" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="3" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="4" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="5" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
        </TabContext>
      </TabPanel>
      <TabPanel value="payout" sx={{ padding: '10px 0' }}>
        <TabContext value={valueTabTwo}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTabTwo}>
              <Tab label="Активные" value="1" />
              <Tab label="Успешные" value="2" />
              <Tab label="Отмененные" value="3" />
              <Tab label="Диспут" value="4" />
              <Tab label="Все" value="5" />
            </TabList>
          </Box>
          {/* <Stack flexDirection={'row'} alignItems={'center'} justifyContent={"flex-end"} sx={{ padding: '8px 16px 0 16px' }}>
            <IconButton onClick={handleClick}>
              <FilterListIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                sx: { padding: '8px 16px', display: 'flex', flexDirection: 'column', gap: '10px' }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '60px' }}>
                <Typography variant='h6' fontWeight={600}>Фильтрация</Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <DatePicker
                label="Дата от"
                value={dateOn}
                onChange={(newValue) => setDateOn(newValue)}
              />
              <DatePicker
                label="Дата до"
                value={dateFor}
                onChange={(newValue) => setDateFor(newValue)}
              />
              <Stack flexDirection={"row"} gap={"10px"}>
                <Button variant='outlined' color='error' fullWidth onClick={handleResetFilters}>Сбросить</Button>
                <Button variant='outlined' fullWidth>OK</Button>
              </Stack>
            </Menu>
          </Stack> */}
          <TabPanel value="1" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="2" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="3" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="4" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
          <TabPanel value="5" sx={{ padding: '10px 0' }}>
            {
              orders.length ?
                <CustomTable rowsDataHead={rowsHeadOrders} rowsDataBody={orders} paginationCol={rowsHeadOrders.length} showDetails />
                :
                <Paper sx={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <ErrorOutlineIcon />
                  Данные не найдены
                </Paper>
            }
          </TabPanel>
        </TabContext>
      </TabPanel>
      <CustomSnackBar open={openS} message={'Скопировано!'} setOpen={setOpenS} />
      {/* <Modal modalContent={modalAuto} open={openModalAuto} setOpen={setOpenModalAuto} />
      <Modal modalContent={modalAutoGroup} open={openModalAutoGroup} setOpen={setOpenModalAutoGroup} />
      <Modal modalContent={modalDelete} open={openModalDelete} setOpen={setOpenModalDelete} />
      <Modal modalContent={modalDeleteGroup} open={openModalDeleteGroup} setOpen={setOpenModalDeleteGroup} /> */}
    </TabContext>
  )
}

export default OrdersA