import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import React from 'react'

interface CustomInputPasswordPropsType {
  errorMessage?: string,
  loading?: boolean,
  name?: string,
  variant?: 'outlined' | 'filled' | 'standard',
  label: string,
  autoComplete?: string,
}

const CustomInputPassword = ({
  errorMessage,
  loading,
  name,
  variant,
  label,
  autoComplete,
}: CustomInputPasswordPropsType) => {

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl fullWidth required error={!!errorMessage} disabled={loading} sx={{ marginBottom: '8px' }}>
      <InputLabel
        htmlFor={`${name}-adornment-password`}
      // sx={{ color: '#ffffff', '&.Mui-focused': { color: '#1caf86' }, '&.Mui-disabled': { color: '#eeeeee50' } }}
      >
        {label}
      </InputLabel>
      {
        variant === 'standard' ?
          <Input
            id={`${name}-adornment-password`}
            type={showPassword ? 'text' : 'password'}
            autoComplete={autoComplete}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={
                    showPassword ? 'hide the password' : 'display the password'
                  }
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                  edge="end"
                  disabled={loading}
                // sx={{ color: '#E0E3E7', '&.Mui-disabled': { color: '#eeeeee50' } }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          // label="Password"
          />
          :
          <OutlinedInput
            id={`${name}-adornment-password`}
            type={showPassword ? 'text' : 'password'}
            autoComplete={autoComplete}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={
                    showPassword ? 'hide the password' : 'display the password'
                  }
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                  edge="end"
                  disabled={loading}
                // sx={{ color: '#E0E3E7', '&.Mui-disabled': { color: '#eeeeee50' } }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={label}
          />
      }
      <FormHelperText error={!!errorMessage} children={errorMessage ? 'Проверьте введенные данные' : ''} />
    </FormControl>
  )
}

export default CustomInputPassword