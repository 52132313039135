import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CustomSelectContentType, IBalance, IPercent, IUser } from '../../../types/types'
import { Box, Button, Card, CardContent, CircularProgress, Stack, Typography } from '@mui/material'
import { CreateBalanceMethod, CreateDepositMethod, CreatePercentMethod, GetBalanceMethod, GetBalancePercentMethod, GetBalDepStatusMethod, GetBalDepTypeMethod, GetDepositMethod, GhangeDepositFrozenMethod, SetBalanceMethod, SetBalancePercentMethod, SetDepositMethod } from '../../../services/Actives'
import Grid from '@mui/material/Grid2'
import Modal from '../../../UI/modal/Modal'
import CustomInput from '../../../UI/customInput/CustomInput'
import CustomSelect from '../../../UI/customSelect/CustomSelect'
import CustomSnackBar from '../../../UI/customSnackBar/CustomSnackBar'

const UsersWallet = () => {
  const location = useLocation()
  const user: IUser = location.state

  if (!user) document.location.href = '/'

  const createUserBalance = async () => {
    const dataBalance = await CreateBalanceMethod(user.id, +sum, +currencySelected, +statusSelected, +typeSelected, +sumFroz)
    const dataDeposit = await CreateDepositMethod(user.id, +sum, +statusSelected, +typeSelected, 'deposit')
    const dataPercentPayIn = await CreatePercentMethod(user.id, 1, 1, +statusSelected)
    const dataPercentPayOut = await CreatePercentMethod(user.id, 2, 1, +statusSelected)
    // console.log(dataBalance, dataDeposit, dataPercent);
    // console.log(dataBalance, dataPercent);

    if (dataBalance.status === 200) {
      setSum('')
      setSumFroz('')
      setOpenModalAddBalance(false)
    } else {

    }
  }

  const [loading, setLoading] = useState(true)
  const [isExistBalance, setIsExistBalance] = useState(false)

  const [balance, setBalance] = useState<IBalance | null>(null)
  const [deposit, setDeposit] = useState<IBalance | null>(null)
  const [percentPayIn, setPercentPayIn] = useState<IPercent | null>(null)
  const [percentPayOut, setPercentPayOut] = useState<IPercent | null>(null)

  const getBalance = useCallback(async () => {
    const dataBalance = await GetBalanceMethod(user.id)
    const dataDeposit = await GetDepositMethod(user.id)
    const dataPercentPayIn = await GetBalancePercentMethod(user.id, 1)
    // const dataPercentPayOut = await GetBalancePercentMethod(user.id, 2)

    if (dataBalance.status === 200 && dataBalance.data.Success) {
      setBalance(dataBalance.data.data[0])
      setIsExistBalance(true)
      if (dataDeposit.status === 200 && dataDeposit.data.Success) {
        setDeposit(dataDeposit.data.data[0])
        // setIsExistBalance(true)
        if (dataPercentPayIn.status === 200 && dataPercentPayIn.data.Success) {
          setPercentPayIn(dataPercentPayIn.data.data[0])
          setPercentPayOut(dataPercentPayIn.data.data[1])
          // setIsExistBalance(true)
        }
      }
    }
    setLoading(false)
  }
    , [user.id])

  const [showModalChangeBalance, setShowModalChangeBalance] = useState(false)
  const [showModalChangeDeposit, setShowModalChangeDeposit] = useState(false)
  const [showModalChangePayIn, setShowModalChangePayIn] = useState(false)
  const [showModalChangePayOut, setShowModalChangePayOut] = useState(false)

  const changeBalance = async () => {
    const data = await SetBalanceMethod(user.id, +sum, +sumFroz)
    if (data.status === 200 && data.data.Success) {
      setShowModalChangeBalance(false)
      setSum('')
      setSumFroz('')
    } else {

    }
  }

  const [openSnack, setOpenSnack] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')

  const changeDeposit = async () => {
    if (+sum < +sumFroz) {
      setOpenSnack(true)
      setErrorMessage('Сумма доступных меньше суммы замороженных')
      setTimeout(() => {
        setErrorMessage('')
      }, 2000);
    } else {
      const data = await SetDepositMethod(user.id, +sum)
      const dataFr = await GhangeDepositFrozenMethod(user.id, +sumFroz)
      if (data.status === 200 && data.data.Success) {
        setShowModalChangeDeposit(false)
        setSum('')
        setSumFroz('')
      } else {

      }
    }
  }
  const changePayIn = async () => {
    const data = await SetBalancePercentMethod(user.id, +sum, 1)
    if (data.status === 200 && data.data.Success) {
      setShowModalChangePayIn(false)
      setSum('')
    } else {
      const dataPercentPayIn = await CreatePercentMethod(user.id, 1, 1, +statusSelected)
      const dataPercentPayOut = await CreatePercentMethod(user.id, 2, 1, +statusSelected)
    }
  }
  const changePayOut = async () => {
    const data = await SetBalancePercentMethod(user.id, +sum, 2)
    if (data.status === 200 && data.data.Success) {
      setShowModalChangePayOut(false)
      setSum('')
    } else {
      const dataPercentPayIn = await CreatePercentMethod(user.id, 1, 1, +statusSelected)
      const dataPercentPayOut = await CreatePercentMethod(user.id, 2, 1, +statusSelected)
    }
  }

  useEffect(() => {
    getBalance()
  }, [getBalance])

  const cardPayin = (
    <>
      <CardContent>
        <Stack flexDirection={"row"} gap={'20px'} alignItems={"center"} justifyContent={"space-between"} marginBottom={'10px'}>
          <Typography gutterBottom variant='h6'>Вознаграждение (PayIn)</Typography>
          <Button onClick={() => setShowModalChangePayIn(true)}>Изменить</Button>
        </Stack>
        <Typography variant="h5">
          {
            // percentPayIn ?
            <Stack flexDirection={"row"} gap={"10px"}>
              <Typography>{percentPayIn?.value ?? 0}</Typography>
              <Typography color='primary'>%</Typography>
            </Stack>
            // :
            // <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"100px"}>
            //   <CircularProgress size="30px" />
            // </Stack>
          }
        </Typography>
      </CardContent>
    </>
  )
  const cardPayOut = (
    <>
      <CardContent>
        <Stack flexDirection={"row"} gap={'20px'} alignItems={"center"} justifyContent={"space-between"} marginBottom={'10px'}>
          <Typography variant='h6'>Вознаграждение (PayOut)</Typography>
          <Button onClick={() => setShowModalChangePayOut(true)}>Изменить</Button>
        </Stack>
        <Typography variant="h5">
          {
            // percentPayOut ?
            <Stack flexDirection={"row"} gap={"10px"}>
              <Typography>{percentPayOut?.value ?? 0}</Typography>
              <Typography color='primary'>%</Typography>
            </Stack>
            // :
            // <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"100px"}>
            //   <CircularProgress size="30px" />
            // </Stack>
          }
        </Typography>
      </CardContent>
    </>
  )
  const cardBalance = (
    <>
      <CardContent>
        <Stack flexDirection={"row"} gap={'20px'} alignItems={"center"} justifyContent={"space-between"} marginBottom={'10px'}>
          <Typography variant='h6'>Баланс</Typography>
          <Button onClick={() => setShowModalChangeBalance(true)}>Изменить</Button>
        </Stack>
        <Stack flexDirection={"row"} alignItems={"center"} gap={"20px"}>
          <Box>
            <Typography variant="h5">
              {
                balance ?
                  <Stack flexDirection={"row"} gap={"10px"}>
                    <Typography>{balance.value}</Typography>
                    <Typography color='primary'>{balance.chart_symbol}</Typography>
                  </Stack>
                  :
                  <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"100px"}>
                    <CircularProgress size="20px" />
                  </Stack>
              }
            </Typography>
            <Typography variant="caption" gutterBottom>
              Доступно
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              {
                balance ?
                  <Stack flexDirection={"row"} gap={"10px"}>
                    <Typography>{balance.frozen ?? 0}</Typography>
                    <Typography color='primary'>{balance.chart_symbol}</Typography>
                  </Stack>
                  :
                  <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"100px"}>
                    <CircularProgress size="20px" />
                  </Stack>
              }
            </Typography>
            <Typography variant="caption">
              Заморожено
            </Typography>
          </Box>
        </Stack>
      </CardContent>
      {/* <CardActions>
        <Button size="medium" variant='contained' sx={{ fontWeight: 600 }} onClick={() => setOpenModalBalance(true)}>Вывести</Button>
      </CardActions> */}
    </>
  )

  const cardDeposit = (
    <>
      <CardContent>
        <Stack flexDirection={"row"} gap={'20px'} alignItems={"center"} justifyContent={"space-between"} marginBottom={'10px'}>
          <Typography variant='h6'>Депозит</Typography>
          <Button onClick={() => setShowModalChangeDeposit(true)}>Изменить</Button>
        </Stack>
        <Stack flexDirection={"row"} alignItems={"center"} gap={"20px"}>
          <Box>
            <Typography variant="h5">
              {
                deposit ?
                  <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
                    <Typography>{+deposit.value - +(deposit?.frozen ?? 0)}</Typography>
                    <Typography>из</Typography>
                    <Typography>{deposit.value}</Typography>
                    <Typography color='primary'>{balance?.chart_symbol}</Typography>
                  </Stack>
                  :
                  <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"30px"}>
                    <CircularProgress size="20px" />
                  </Stack>
              }
            </Typography>
            <Typography variant="caption" gutterBottom>
              Доступно
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              {
                deposit ?
                  <Stack flexDirection={"row"} gap={"10px"}>
                    <Typography>{deposit?.frozen ?? 0}</Typography>
                    <Typography color='primary'>{balance?.chart_symbol}</Typography>
                  </Stack>
                  :
                  <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"30px"}>
                    <CircularProgress size="20px" />
                  </Stack>
              }
            </Typography>
            <Typography variant="caption">
              Заморожено
            </Typography>
          </Box>
        </Stack>
      </CardContent>
      {/* <CardActions>
        <Button disabled size="medium" variant='contained' sx={{ fontWeight: 600 }}>Вывести</Button>
      </CardActions> */}
    </>
  )

  const [openModalAddBalance, setOpenModalAddBalance] = useState(false)

  const currency: CustomSelectContentType[] = [
    { contentLabel: 'USDT', contentValue: '259' },
    // { contentLabel: requisites.group, contentValue: requisites.group },
  ]
  const status: CustomSelectContentType[] = [
    { contentLabel: 'Доступен', contentValue: '0' },
    { contentLabel: 'Недоступен', contentValue: '1' },
  ]
  const type: CustomSelectContentType[] = [
    { contentLabel: 'Активный', contentValue: '0' },
    { contentLabel: 'Архивный', contentValue: '1' },
  ]

  const [currencySelected, setCurrencySelected] = useState(currency[0].contentValue)
  const [statusSelected, setStatusSelected] = useState(status[0].contentValue)
  const [typeSelected, setTypeSelected] = useState(type[0].contentValue)

  const [sum, setSum] = useState('')
  const [sumFroz, setSumFroz] = useState('')

  const modalAddBalance = <Box gap={"5px"}>
    <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
      <Typography variant='h6' gutterBottom>Создать баланс пользователю:</Typography>
      <Typography variant='h6' color='primary' gutterBottom>{user.email}</Typography>
    </Stack>
    <form action='#' onSubmit={(e) => { e.preventDefault(); createUserBalance().then(() => getBalance()) }}>
      <Box margin={"10px 0"} display={'flex'} flexDirection={'column'} gap={"10px"}>
        <CustomInput label='Сумма' numeric required value={sum} setValue={setSum} />
        <CustomInput label='Сумма замороженных' numeric required value={sumFroz} setValue={setSumFroz} />
        <CustomSelect label='Валюта' disabled content={currency} value={currencySelected} setValue={setCurrencySelected} />
        <CustomSelect label='Статус' content={status} value={statusSelected} setValue={setStatusSelected} />
        <CustomSelect label='Тип' content={type} value={typeSelected} setValue={setTypeSelected} />
      </Box>
      <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
        <Button onClick={() => setOpenModalAddBalance(false)}>Отмена</Button>
        <Button variant='contained' sx={{ fontWeight: 700 }} type='submit'>Создать баланс</Button>
      </Stack>
    </form>
  </Box>

  const modalChangeBalance = <Box gap={"5px"}>
    <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
      <Typography variant='h6' gutterBottom>Изменить баланс пользователю:</Typography>
      <Typography variant='h6' color='primary' gutterBottom>{user.email}</Typography>
    </Stack>
    <form action='#' onSubmit={(e) => { e.preventDefault(); changeBalance().then(() => getBalance()) }}>
      <Box margin={"10px 0"} display={'flex'} flexDirection={'column'} gap={"10px"}>
        <CustomInput label='Сумма доступных' numeric required value={sum} setValue={setSum} />
        <CustomInput label='Сумма замороженых' numeric required value={sumFroz} setValue={setSumFroz} />
      </Box>
      <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
        <Button onClick={() => setShowModalChangeBalance(false)}>Отмена</Button>
        <Button variant='contained' sx={{ fontWeight: 700 }} type='submit'>Изменить баланс</Button>
      </Stack>
    </form>
  </Box>
  const modalChangeDeposit = <Box gap={"5px"}>
    <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
      <Typography variant='h6' gutterBottom>Изменить депозит пользователю:</Typography>
      <Typography variant='h6' color='primary' gutterBottom>{user.email}</Typography>
    </Stack>
    <form action='#' onSubmit={(e) => { e.preventDefault(); changeDeposit().then(() => getBalance()) }}>
      <Box margin={"10px 0"} display={'flex'} flexDirection={'column'} gap={"10px"}>
        <CustomInput label='Сумма доступных' numeric required value={sum} setValue={setSum} />
        <CustomInput label='Сумма замороженных' numeric required value={sumFroz} setValue={setSumFroz} />
      </Box>
      <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
        <Button onClick={() => setShowModalChangeDeposit(false)}>Отмена</Button>
        <Button variant='contained' sx={{ fontWeight: 700 }} type='submit'>Изменить депозит</Button>
      </Stack>
    </form>
  </Box>
  const modalChangePercentIn = <Box gap={"5px"}>
    <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
      <Typography variant='h6' gutterBottom>Изменить процент (PayIn) пользователю:</Typography>
      <Typography variant='h6' color='primary' gutterBottom>{user.email}</Typography>
    </Stack>
    <form action='#' onSubmit={(e) => { e.preventDefault(); changePayIn().then(() => getBalance()) }}>
      <Box margin={"10px 0"} display={'flex'} flexDirection={'column'} gap={"10px"}>
        <CustomInput label='Процент' numeric required value={sum} setValue={setSum} />
      </Box>
      <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
        <Button onClick={() => setShowModalChangePayIn(false)}>Отмена</Button>
        <Button variant='contained' sx={{ fontWeight: 700 }} type='submit'>Изменить процент</Button>
      </Stack>
    </form>
  </Box>
  const modalChangePercentOut = <Box gap={"5px"}>
    <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
      <Typography variant='h6' gutterBottom>Изменить процент (PayOut) пользователю:</Typography>
      <Typography variant='h6' color='primary' gutterBottom>{user.email}</Typography>
    </Stack>
    <form action='#' onSubmit={(e) => { e.preventDefault(); changePayOut().then(() => getBalance()) }}>
      <Box margin={"10px 0"} display={'flex'} flexDirection={'column'} gap={"10px"}>
        <CustomInput label='Процент' numeric required value={sum} setValue={setSum} />
      </Box>
      <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={"10px"} marginTop={"20px"}>
        <Button onClick={() => setShowModalChangePayOut(false)}>Отмена</Button>
        <Button variant='contained' sx={{ fontWeight: 700 }} type='submit'>Изменить процент</Button>
      </Stack>
    </form>
  </Box>

  const [open, setOpen] = useState(false)

  return (
    <Box>
      <Stack flexDirection={"row"} gap={"10px"} marginBottom={"20px"}>
        <Typography variant='h5'>Финансы пользователя:</Typography>
        <Typography variant='h5' color='primary'>{user.email}</Typography>
      </Stack>
      {
        !loading ?
          isExistBalance ?
            <Stack gap={'30px'}>
              <Grid container spacing={1}>
                <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                  <Card variant="outlined" sx={{ padding: '24px 40px' }}>{cardPayin}</Card>
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                  <Card variant="outlined" sx={{ padding: '24px 40px' }}>{cardPayOut}</Card>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                  <Card variant="outlined" sx={{ padding: '24px 40px' }}>{cardBalance}</Card>
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                  <Card variant="outlined" sx={{ padding: '24px 40px' }}>{cardDeposit}</Card>
                </Grid>
              </Grid>
              <CustomSnackBar open={open} message={'Что-то пошло не так..'} setOpen={setOpen} />
            </Stack>
            :
            <Button variant='outlined' onClick={() => setOpenModalAddBalance(true)}>Создать баланс пользователя</Button>
          :
          <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" minHeight={"200px"}>
            <CircularProgress size="40px" />
          </Stack>
      }
      <Modal modalContent={modalAddBalance} open={openModalAddBalance} setOpen={setOpenModalAddBalance} width={"max-content"} />
      <Modal modalContent={modalChangeBalance} open={showModalChangeBalance} setOpen={setShowModalChangeBalance} width={"max-content"} />
      <Modal modalContent={modalChangeDeposit} open={showModalChangeDeposit} setOpen={setShowModalChangeDeposit} width={"max-content"} />
      <Modal modalContent={modalChangePercentIn} open={showModalChangePayIn} setOpen={setShowModalChangePayIn} width={"max-content"} />
      <Modal modalContent={modalChangePercentOut} open={showModalChangePayOut} setOpen={setShowModalChangePayOut} width={"max-content"} />
      <CustomSnackBar open={openSnack} message={errorMessage} setOpen={setOpenSnack} />
    </Box>
  )
}

export default UsersWallet