import { useCallback, useEffect } from "react"
import { useAppDispatch } from "./hooks"
import { successLogin, unsuccessfulLogin } from "../slices/app.slice"
import { CheckAuthMethod } from "../../services/Auth"


export const useAppInit = () => {
  const dispatch = useAppDispatch()

  const token = localStorage.getItem("access_token");

  const checkAuth = useCallback(async () => {
    if (token) {
      //   if (Date.now() >= tokenData.expires_on * 1000) {
      //   try {
      //     const newToken = await refreshToken(tokenData.refresh_token); // если истек, то обновляем токен с помощью refresh_token
      //     saveToken(newToken);
      // } catch () { // если тут что-то пошло не так, то перенаправляем пользователя на страницу авторизации
      //     window.location.href = '/login';
      // }
      // } else {
      //     const data = await CheckAuthMethod(token)
      //     if (data.status === 200 && data.data.Status) {
      //       dispatch(successLogin())
      //     } else {
      //       localStorage.clear()
      //       dispatch(unsuccessfulLogin())
      //       document.location.href = '/login'
      //     }
      // }

      //WAITING FOR NEW TOKEN API

      // const data = await CheckAuthMethod(token)
      // if (data.status === 200 && data.data.Success) {
      //   dispatch(successLogin())
      // } else {
      //   localStorage.clear()
      //   dispatch(unsuccessfulLogin())
      //   document.location.href = '/login'
      // }
    }
  }
    , [dispatch, token])

  useEffect(() => {
    checkAuth()
  }, [checkAuth])
}